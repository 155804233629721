import { useContext , useEffect} from "react";
import { useSelector } from "react-redux";
import { Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { WalletStateContext } from "../context/wallet";
import { getToken } from "../store/slices/tokens-slice";
import { getAccountBalance } from "../store/slices/account-balances-slice";
import { getAllowance } from "../store/slices/allowances-slice";
import { useTranslation } from "react-i18next";
import { Connectors } from "../connectors/connectors";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import ConversionUtils from "../utils/conversions";

const {
  formatUnits,
} = ConversionUtils;

const AccountInfo = (props) => {
  const {
    networkName,
    isPopUp,
    activeConnector,
    handleWalletConnection,
    handleFreeTicketsClick,
    handleBurnPotClick,
    disconnectRequested,
    addTokenToMM
  } = props;
  const { t } = useTranslation("translation", {keyPrefix: "account"});
  const theme = useTheme();
  const wallet = useContext(WalletStateContext);
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const gameToken = useSelector(state => getToken(state, "game"));
  const linkToken = useSelector(state => getToken(state, "link"));
  const chanceyTokenBalance = useSelector(state => getAccountBalance(state, "chancey"));
  const gameTokenBalance = useSelector(state => getAccountBalance(state, "game"));
  const linkTokenBalance = useSelector(state => getAccountBalance(state, "link"));
  const chanceyTokenAllowance = useSelector(state => getAllowance(state, "chancey"));
  const gameTokenAllowance = useSelector(state => getAllowance(state, "game"));
  const linkTokenAllowance = useSelector(state => getAllowance(state, "link"));
  const allTokens = [chanceyToken, gameToken, /*linkToken*/];
  const allBalances = [chanceyTokenBalance, gameTokenBalance, linkTokenBalance];
  const allAllowances = [chanceyTokenAllowance, gameTokenAllowance, linkTokenAllowance];
  const connectorActive = activeConnector?.chainId > 0
  const borderStyles = { m: 1, p: 1, border: isPopUp ? 1 : 0 };
  const accountName = wallet?.account ? `${wallet.account.slice(0, 12)} ... ${wallet.account.slice(-8)}` : "";

  return(
    <Box>
      <Box sx={{ ...borderStyles, borderColor: 'primary.main' }}>
        {!window.ethereum && <Box>
          <Typography>
            {t("wallet.install")}
          </Typography>
        </Box>}
        {(window.ethereum && !connectorActive) && <Box alignItems="center">
          <Stack>
            {Connectors.map((c, i) => {
              return <Button
                key={i}
                startIcon={<Avatar src={`/icons/wallets/${c.logo}`}
                sx={{...theme.typography.coinAvatar}}/>}
                onClick={() => handleWalletConnection(c)}
              >
                {c.name}
              </Button>
            })}
          </Stack>
        </Box>}
        {(window.ethereum && connectorActive) && <Box>
          <Box sx={{p: 1}}>
          <Typography  sx={{ ...theme.typography.accountName }}>
            {accountName}
          </Typography>
          <Typography  sx={{ ...theme.typography.networkInfo }}>
            {networkName}
          </Typography>
          </Box>
          <Box sx={{p: 1}}>
            <Divider/>
          </Box>
          <Box sx={{p: 1}}>
            <Typography>
              {t("balances")}
            </Typography>
            <Box>
              <Stack>
                {
                  allTokens.map((token,i) => {
                    return (
                      <Stack direction="row" alignItems="center" spacing={2} key={i}>
                        <Tooltip title={t("addTokenToWallet", {sym: token.symbol})} arrow>
                          <IconButton onClick={() => {addTokenToMM(token)}}>
                            <Avatar src={`/icons/tokens/${token?.icon}`} sx={{...theme.typography.coinAvatar}}/>
                          </IconButton>
                        </Tooltip>
                        {/* <Stack alignItems="right">
                          <Typography sx={{ ...theme.typography.accountBalance }}>
                            {t("balance", {b: formatUnits(allBalances[i], token.decimals)})}
                          </Typography>
                          <Typography sx={{ ...theme.typography.allowance }}>
                            {t("allowance", {a: formatUnits(allAllowances[i], token.decimals)})}
                          </Typography>
                        </Stack> */}
                        <Typography sx={{ ...theme.typography.accountBalance }}>
                          {formatUnits(allBalances[i], token.decimals)}
                        </Typography>
                      </Stack>
                    )
                  })
                  }
              </Stack>
            </Box>
          </Box>
          <Box sx={{p: 1}}>
            <Divider/>
          </Box>
          <Box sx={{p: 1}}>
            <Typography>
              {t("redeem")}
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={handleFreeTicketsClick}>
                  <ListItemIcon>
                    <ConfirmationNumberOutlinedIcon variant="outlined" color="primary" size="large"/>
                  </ListItemIcon>
                  <ListItemText primary="Free Tickets" primaryTypographyProps={{ ...theme.typography.accountBalance }}></ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleBurnPotClick}>
                  <ListItemIcon>
                    <LoyaltyOutlinedIcon variant="outlined" color="primary" size="large"/>
                  </ListItemIcon>
                  <ListItemText primary="Pot Claim" primaryTypographyProps={{ ...theme.typography.accountBalance }}></ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box sx={{p: 1}}>
            <Divider/>
          </Box>
          <Box sx={{p: 1}}>
            <Button onClick={disconnectRequested}>
              {t("disconnect")}
            </Button>
          </Box>
        </Box>}
      </Box>
    </Box>
  )
}
export default AccountInfo