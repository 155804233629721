import { ethers } from "ethers";
import { Contracts } from "./deployed-contracts.js";

// Note - contract loading currently works
// only on default network
// TODO make it dynamic
class Factory {

  constructor(provider, chainId) {
    this.provider = provider;
    this.chainId = chainId;
  }

  getChainId() {
    return this.chainId;
  }

  getProvider() {
    return this.provider;
  }

  getSigner() {
    return this.provider.getSigner();
  }

  getFunctionAbi (input) {
    return new ethers.utils.Interface(input);
  }

  six49Address() {
    return Contracts[this.chainId]["Chancey649"].address;
  }

  seven50Address() {
    return Contracts[this.chainId]["Chancey750"].address;
  }

  chanceyTokenAddress() {
    return Contracts[this.chainId]["Chancey"].address;
  }

  governorAddress() {
    return Contracts[this.chainId]["ChanceyGovernor"].address;
  }

  timelockControllerAddress() {
    return Contracts[this.chainId]["ChanceyTimelockController"].address;
  }

  getContract(str) {
    const contract = Contracts[this.chainId][str];
    if (contract) {
      const signer = this.provider.getSigner();
      const deployed = contract;
      return new ethers.Contract(deployed?.address, contract.abi, signer);
    } else {
      console.error(`${str} contract not registered`);
    }
  }

  getGovernor() {
    const signer = this.provider.getSigner();
    return new ethers.Contract(this.governorAddress(), Contracts[this.chainId]["ChanceyGovernor"].abi, signer);
  }

  getTimelockController() {
    const signer = this.provider.getSigner();
    return new ethers.Contract(this.timelockControllerAddress(), Contracts[this.chainId]["ChanceyTimelockController"].abi, signer);
  }

  getGovernedContracts() {
    return {
      [this.six49Address()] : "Chancey 649",
      [this.chanceyTokenAddress()] : "Chancey Token",
      [this.governorAddress()] : "Governor",
      [this.timelockControllerAddress()] : "Timelock",
    }
  }

  async getDetachedFacetFromAddress(address) {
    const contracts = Object.values(Contracts[this.chainId]);
    return contracts.find(c => c.address === address);
  }

  async getLoupe(target) {
    switch(target) {
      case this.six49Address():
        return this.getGameFacet("LoupeFacet");
      case this.chanceyTokenAddress():
        return this.getChanceyFacet("LoupeFacet");
      case this.governorAddress():
        // The governor doesn't use the Diamond pattern, hence no loupe with facets.
        // But we can pretend it's a Diamond and return self as a loupe with facets.
        return {
          facets :() => [{facetAddress: this.governorAddress()}]
        }
      case this.timelockControllerAddress():
        // The governor doesn't use the Diamond pattern, hence no loupe with facets.
        // But we can pretend it's a Diamond and return self as a loupe with facets.
        return {
          facets :() => [{facetAddress: this.timelockControllerAddress()}]
        }
    }
  }

  async getGameFacet(str, game="649") {
    const facet = Contracts[this.chainId][str];
    if (facet) {
      const signer = this.provider.getSigner();
      return new ethers.Contract(game === "649" ? this.six49Address() : this.seven50Address(), facet.abi, signer);
    } else {
      console.error(`${str} facet not registered`);
    }
  }

  async getChanceyFacet(str) {
    const facet = Contracts[this.chainId][str];
    if (facet) {
      const signer = this.provider.getSigner();
      return new ethers.Contract(this.chanceyTokenAddress(), facet.abi, signer);
    } else {
      console.error(`${str} facet not registered`);
    }
  }

  async getVRFMock () {
    const signer = this.provider.getSigner();
    const VRFCoordinatorV2Mock = Contracts[this.chainId]["VRFCoordinatorV2Mock"];
    return new ethers.Contract(VRFCoordinatorV2Mock.address, VRFCoordinatorV2Mock.abi, signer);
  }

  async getAirnodeRrp(address) {
    const signer = this.provider.getSigner();
    return new ethers.Contract(address, Contracts[this.chainId]["AirnodeRrpV0"].abi, signer);
  }

  IERC20FromAddress(address) {
    const signer = this.provider.getSigner();
    const ChanceyERC20Facet = Contracts[this.chainId]["ChanceyERC20Facet"];
    return new ethers.Contract(address, ChanceyERC20Facet.abi, signer);
  }
}

const ContractUtils = {
  contractFactory : (provider, chainId) => new Factory(provider, chainId)
};

export default ContractUtils;