import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "gameConfigurations";

const initialState = {
  data: {
    carryoverTickets : false,
    isERC20Configured : true,
    minTickets: 1,
    drawInterval: 1,
    freeTicketsConfigs : null,
    rewardsConfigurations : null,
    randomSource: 1,
    guaranteedPrizeConfigurations : []
  },
  status: "idle",
  error: null
};

const gameConfigurationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchMinTickets.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchMinTickets.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.minTickets = action.payload;
    })
    .addCase(fetchMinTickets.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchDrawInterval.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchDrawInterval.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.drawInterval = action.payload;
    })
    .addCase(fetchDrawInterval.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchCarryOverTickets.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchCarryOverTickets.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.carryoverTickets = action.payload;
    })
    .addCase(fetchCarryOverTickets.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchRandomSource.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchRandomSource.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.randomSource = action.payload;
    })
    .addCase(fetchRandomSource.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchRewardsConfigurations.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchRewardsConfigurations.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.rewardsConfigurations = action.payload;
    })
    .addCase(fetchRewardsConfigurations.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchGuaranteedPrizeConfigurations.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchGuaranteedPrizeConfigurations.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.guaranteedPrizeConfigurations = action.payload;
    })
    .addCase(fetchGuaranteedPrizeConfigurations.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
  }
});

export const fetchMinTickets = createAsyncThunk(`${sliceName}/fetchMinTickets`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  return (await game.minTickets()).toNumber();
});

export const fetchDrawInterval = createAsyncThunk(`${sliceName}/fetchDrawInterval`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  return (await game.drawInterval()).toNumber();
});

export const fetchCarryOverTickets = createAsyncThunk(`${sliceName}/fetchCarryOverTickets`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  return await game.carryoverTickets();
});

export const fetchRandomSource = createAsyncThunk(`${sliceName}/fetchRandomSource`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  return await game.randomSource();
});

export const fetchRewardsConfigurations = createAsyncThunk(`${sliceName}/fetchRewardsConfigurations`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  const configs = await game.rewardsConfigurations();
  return {
    potLiftTarget : configs.potLiftTarget.toString(),
    freeTicketExchangeRate: configs.freeTicketExchangeRate.toString(),
    freeTicketsToSoldRate: configs.freeTicketsToSoldRate.toString(),
    potBurnCoolRate: configs.potBurnCoolRate,
    boostFactor: configs.boostFactor,
    rewardBuckets: configs.rewardBuckets
  };
});

export const fetchGuaranteedPrizeConfigurations = createAsyncThunk(`${sliceName}/fetchGuaranteedPrizeConfigurations`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game configurations, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  const configs = await game.guaranteedPrizeConfigurations();
  return configs.map(c => {
    return {
      ticketsLot: c.ticketsLot.toNumber(),
      prizeRate: c.prizeRate.toNumber(),
    }
  })
});

export const getMinTickets = (state) => {
  return state[sliceName].data.minTickets;
}

export const getDrawInterval = (state) => {
  return state[sliceName].data.drawInterval;
}

export const getCarryoverTickets = (state) => {
  return state[sliceName].data.carryoverTickets;
}

export const getRandomSource = (state) => {
  return state[sliceName].data.randomSource;
}

export const getFreeTicketsConfigs = (state) => {
  return state[sliceName].data.freeTicketsConfigs;
}

export const getRewardsConfigurations = (state) => {
  return state[sliceName].data.rewardsConfigurations;
}

export const getGuaranteedPrizeConfigurations = (state) => {
  return state[sliceName].data.guaranteedPrizeConfigurations;
}

export default gameConfigurationsSlice.reducer;