import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "winnings";

const initialState = {
  data: "0",
  status: "idle",
  error: null
};

const potSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchWinnings.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchWinnings.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = action.payload;
    })
    .addCase(fetchWinnings.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchWinnings = createAsyncThunk(`${sliceName}/fetch`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch winnings: params is undefined")
  }
  const {
    contracts,
    account
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch winnings, contracts is undefined")
  }
  if (!account) {
    throw new Error("Can't fetch winnings, account is undefined")
  }

  const claims = await contracts.getGameFacet("ClaimsFacet");
  const w = await claims.winnerBalances(account);
  return w.toString();
});

export const getWinnings = (state) => {
  return state.winnings.data;
};

export default potSlice.reducer;