import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
  Button
} from "@mui/material";

import { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import LabelWithValue from "./LabelWithValue";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    // borderRight: "1px solid",
    // borderLeft: "1px solid",
    // borderColor: theme.palette.tertiary.dark,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // "td " : {
  //   borderRight: "1px solid",
  //   borderLeft: "1px solid",
  //   borderColor: theme.palette.tertiary.dark,
  // },
  // // // hide last border
  // '&:last-child td': {
  //   borderBottom: 0,
  // },
}));

const ChanceyTable = (props) => {
  const theme = useTheme();
  const { t } = useTranslation("translation", { keyPrefix: "chanceyTable" });
  const {
    data,
    compact = false,
    showPrevious,
    showNext,
    onPrevious = () => {},
    onNext = () => {},
  } = props;
  if (!data) {
    return <Box></Box>;
  }

  const { columns, rows, rowFormatter } = data;
  if (!columns || !rows) {
    return <Box></Box>;
  }

  return compact ? (<Box sx={{width: "100%"}}>
      <Stack direction="column" spacing={1}>
        {rows.map((r, i) => {
          const paddingBottom = 1;
          const paddingTop = 1;
          return <Paper key={i} sx={{p: 1}}>
            <Box sx={{p: 2, paddingBottom, paddingTop}}>
              {columns.map((col) => {
                const value = rowFormatter ? rowFormatter(col.key, r) : (col.format ? col.format(r[col.key]) : r[col.key]);
                return <Box key={col.key}>
                  <LabelWithValue label={col.name} value={value} separator={""} />
                </Box>
              })}
            </Box>
            {r.actions && r.actions.length > 0 && <Box sx={{p: 2, paddingBottom, paddingTop}}>
              {r.actions(r)}
            </Box>}
          </Paper>
        })}
      </Stack>
    </Box>)
    :
    (<Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((col) => {
                return (
                  <StyledTableCell key={col.key}>
                    <Typography component={'span'} sx={{ ...theme.typography.tableHeader }}>
                      {col.name}
                    </Typography>
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => {
              return(
                <StyledTableRow key={i}>
                  {columns.map((col) => {
                    return(
                      <StyledTableCell key={col.key}>
                        <Typography component={'span'} sx={{ ...theme.typography.tableCell}}>
                          {rowFormatter ? rowFormatter(col.key, r) : (col.format ? col.format(r[col.key]) : r[col.key])}
                        </Typography>
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={2} justifyContent="end" sx={{paddingTop: 2}}>
        {showPrevious && <Button onClick={onPrevious}>{t("previous")}</Button>}
        {showNext && <Button onClick={onNext}>{t("next")}</Button>}
      </Stack>
    </Box>
  )
}
export default ChanceyTable