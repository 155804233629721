import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ConversionUtils from "../../utils/conversions";
import { ethers } from "ethers";

const { toBigNumber } = ConversionUtils;
const sliceName = "governance";
const initialState = {
  data: {
    proposals: {
      created : [],
      gqlProposals : []
    },
    delegateAddress : "",
    delegateVotingPower : "",
    ownVotingPower : "",
    cancelRole : false,
    pauseRole : false,
    proposalVotes : {}
  },
  status: "idle",
  error: null
};

const slice = createSlice({
  name: sliceName,
  initialState,
  extraReducers(builder) {
    builder
    .addCase(fetchAllProposals.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAllProposals.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.proposals.created = action.payload;
    })
    .addCase(fetchAllProposals.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(storeGQLProposals.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(storeGQLProposals.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.proposals.gqlProposals = action.payload;
    })
    .addCase(storeGQLProposals.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchDelegateAddress.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchDelegateAddress.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.delegateAddress = action.payload;
    })
    .addCase(fetchDelegateAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchDelegateVotingPower.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchDelegateVotingPower.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.delegateVotingPower = action.payload;
    })
    .addCase(fetchDelegateVotingPower.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchOwnVotingPower.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchOwnVotingPower.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.ownVotingPower = action.payload;
    })
    .addCase(fetchOwnVotingPower.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchCancelRole.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchCancelRole.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.cancelRole = action.payload;
    })
    .addCase(fetchCancelRole.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchPauseRole.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchPauseRole.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.pauseRole = action.payload;
    })
    .addCase(fetchPauseRole.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })

    .addCase(fetchProposalVotes.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchProposalVotes.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.proposalVotes = action.payload;
    })
    .addCase(fetchProposalVotes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
  }
});

const convertProposal = async (proposal, governor) => {
  const keys = [
    "calldatas",
    "description",
    "endBlock",
    "proposalId",
    "proposer",
    "signatures",
    "startBlock",
    "targets"
  ];
  const res = {};
  const {args} = proposal;
  keys.forEach(k => {
    res[k] = args[k] && args[k]._isBigNumber ? args[k].toString() : args[k];
  });
  const block = await proposal.getBlock();
  res["timestamp"] = block.timestamp;
  res["state"] = await governor.state(res.proposalId);

  try {
    const quorum = await governor.quorum(res.startBlock);
    res["minQuorum"] = quorum.toString();
  } catch (e) {
    // reading quorum can throw in local dev
    // because the blocks are not minted yet.
    res["minQuorum"] = "0";
  }
  return res;
}

export const storeGQLProposals = createAsyncThunk(`${sliceName}/storeGQLProposals`, async(params) => {
  const { contracts, proposals } = params;
  const governor = contracts.getGovernor();
  const result = [];

  for(let i = 0; i < proposals.length; ++i) {
    const proposal = {...proposals[i]};
    proposal["state"] = await governor.state(proposal.proposalId);
    try {
      const quorum = await governor.quorum(proposal.voteStart);
      proposal["minQuorum"] = quorum.toString();
    } catch (e) {
      // reading quorum can throw in local dev
      // because the blocks are not minted yet.
      proposal["minQuorum"] = "0";
    }
    result.push(proposal);
  }
  return result;
});

export const fetchAllProposals = createAsyncThunk(`${sliceName}/fetchAllProposals`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch proposals: params is undefined")
  }
  const {
    contracts
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch proposals, contracts is undefined")
  }

  const governor = contracts.getGovernor();
  const filter = governor.filters.ProposalCreated();
  const res = await governor.queryFilter(filter);
  const proposalsEvents = res.filter(r => r.event === "ProposalCreated");
  const proposals = await Promise.all(proposalsEvents.map(async(e) => await convertProposal(e, governor)));
  return proposals.sort((a,b) => b.timestamp - a.timestamp);
});

export const fetchProposalVotes = createAsyncThunk(`${sliceName}/fetchProposalVotes`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch proposals: params is undefined")
  }
  const {
    contracts,
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch proposal votes, contracts is undefined")
  }

  const governor = contracts.getGovernor();
  const filter1 = governor.filters.VoteCast();
  const filter2 = governor.filters.VoteCastWithParams();
  const res1 = await governor.queryFilter(filter1);
  const res2 = await governor.queryFilter(filter2);
  const events1 = res1.filter(r => r.event === "VoteCast");
  const events2 = res2.filter(r => r.event === "VoteCastWithParams");
  const events = events1.concat(events2).map(e => e.args);
  const votes = {};
  const getVotes = (proposalId) => {
    if (!votes[proposalId]) {
      votes[proposalId] = {voteFor: "0", voteAgainst: "0", voteAbstain: "0", voteTotal: "0"};
    }
    return votes[proposalId];
  }
  const voteOptions = ["voteAgainst", "voteFor", "voteAbstain"];
  for(let i = 0; i < events.length; i++) {
    const {support, weight, proposalId} = events[i];
    const option = voteOptions[support];
    const proposalVote = getVotes(proposalId);
    const currentWeight = toBigNumber(proposalVote[option]);
    proposalVote[option] = currentWeight.add(weight).toString();
    let total = toBigNumber(proposalVote["voteTotal"]);
    total = total.add(weight);
    proposalVote.voteTotal = total.toString();
  }
  return votes;
});

export const fetchDelegateAddress = createAsyncThunk(`${sliceName}/fetchDelegateAddress`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch delegate address: params is undefined")
  }
  const {
    contracts,
    account
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch delegate address, contracts is undefined")
  }

  if (!account) {
    throw new Error("Can't fetch delegate address, account is undefined")
  }

  const erc20Votes = await contracts.getChanceyFacet("ChanceyERC20VotesFacet");
  const delegate = await erc20Votes.delegates(account);
  return delegate;
});

export const fetchDelegateVotingPower = createAsyncThunk(`${sliceName}/fetchDelegateVotingPower`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch delegate address: params is undefined")
  }
  const {
    contracts,
    delegate
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch voting power, contracts is undefined")
  }

  if (!delegate) {
    throw new Error("Can't fetch voting power, delegate is undefined")
  }

  const erc20Votes = await contracts.getChanceyFacet("ChanceyERC20VotesFacet");
  const votes = await erc20Votes.getVotes(delegate);
  return votes.toString();
});

export const fetchOwnVotingPower = createAsyncThunk(`${sliceName}/fetchOwnVotingPower`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch delegate address: params is undefined")
  }
  const {
    contracts,
    account
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch own voting power, contracts is undefined")
  }

  if (!account) {
    throw new Error("Can't fetch own voting power, account is undefined")
  }

  const erc20Votes = await contracts.getChanceyFacet("ChanceyERC20VotesFacet");
  const votes = await erc20Votes.getVotes(account);
  return votes.toString();
});

export const fetchCancelRole = createAsyncThunk(`${sliceName}/fetchCancelRole`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch cancel role: params is undefined")
  }
  const {
    contracts,
    account
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch cancel role, contracts is undefined")
  }

  if (!account) {
    throw new Error("Can't fetch cancel role, account is undefined")
  }

  const timelock = await contracts.getTimelockController();
  const role = await timelock.CANCELLER_ROLE();
  return await timelock.hasRole(role, account);
});

export const fetchPauseRole = createAsyncThunk(`${sliceName}/fetchPauseRole`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch pause role: params is undefined")
  }
  const {
    contracts,
    account
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch pause role, contracts is undefined")
  }

  if (!account) {
    throw new Error("Can't fetch pause role, account is undefined")
  }

  const { utils } = ethers;
  const role = utils.keccak256(utils.toUtf8Bytes("PAUSE_ROLE"));
  const ac = await contracts.getGameFacet("AccessControlFacet");
  return await ac.hasRole(role, account);
});

export const getAllProposals = (state) => {
  return state[sliceName].data.proposals.created;
};

export const getGQLProposals = (state) => {
  return state[sliceName].data.proposals.gqlProposals;
}

export const getDelegateAddress = (state) => {
  return state[sliceName].data.delegateAddress;
};

export const getDelegateVotingPower = (state) => {
  return state[sliceName].data.delegateVotingPower;
};

export const getOwnVotingPower = (state) => {
  return state[sliceName].data.ownVotingPower;
};

export const getCancelRole = (state) => {
  return state[sliceName].data.cancelRole;
};

export const getPauseRole = (state) => {
  return state[sliceName].data.pauseRole;
};

export const getProposalVotes = (state) => {
  return state[sliceName].data.proposalVotes;
}

export default slice.reducer;