import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();
const palette = {
  mode: 'dark',
  primary: {
    main: '#fca241',
  },
  secondary: {
    main: '#ea19a8',
    mainGradient: "linear-gradient(to right, #fca241, #e7ff5c)"
  },
  tertiary: defaultTheme.palette.augmentColor({
    name: "tertiary",
    color: {
      main: "#a5879b",
      light: "#d7b7cc",
      dark: "#765a6d",
      darker: "#5b4655",
    }
  }),
  background: {
    default: '#130211',
    paper: '#180c1d',
  },
  error: {
    main: '#f9246f',
  },
  success: {
    main: '#804caf',
    dark: '#664287'
  },
  divider: '#59224d',
  text: {
    primary: '#fcf0e3',
  },
  info: {
    main: '#e7ff5c',
  }
}

const typography = {
  button: {
    fontWeight: 400,
  },
  coinAvatar: {
    width: '36px',
    height: '36px',
    fontSize: '1rem',
    padding: '2px',
    border: '2px solid #59224d'
  },
  h3 : {
    fontWeight: 80,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
  },
  h4 : {
    fontWeight: 300,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    }
  },
  h5 : {
    fontWeight: 200,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    }
  },
  h6 : {
    fontWeight: 100,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    }
  },
  jackpotAvatar: {
    width: '62px',
    height: '62px',
    fontSize: '1rem',
    padding: '2px',
    border: '2px solid #59224d'
  },
  jackpotAvatarSmall: {
    width: '48px',
    height: '48px',
    fontSize: '1rem',
    padding: '2px',
    border: '2px solid #59224d'
  },
  accountBalance : {
    fontSize: '0.8rem',
    fontWeight: 400,
    color: palette.tertiary.light,
  },
  accountName : {
    fontWeight: 400,
    fontSize: '0.9rem',
    color: palette.primary.main,
  },
  allowance: {
    fontSize: '0.7rem',
    fontWeight: 400,
    color: palette.tertiary.dark,
  },
  subtitle : {
    fontSize: '1rem',
    fontWeight: 400,
  },
  tableCell : {
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  tableHeader : {
    fontSize: '1rem',
    fontWeight: 400,
  },
  subtleInfo: {
    fontSize: '0.7rem',
    fontWeight: 400,
    color: palette.tertiary.dark,
  },
  disclaimer: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: palette.tertiary.dark,
    textAlign: "center"
  },
  errorHint: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: palette.error.main,
  },
  networkInfo: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: palette.tertiary.light,
  },
  drawerMenuItem: {
    color: palette.primary.main,
    fontWeight: 400,
    textTransform: "uppercase"
  }
}

export default createTheme({
  palette, typography
});