import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const sliceName = "user";

const initialState = {
  data: {},
  status: "idle",
  error: null
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchCurrentUserAgreement.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchCurrentUserAgreement.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.agreed = action.payload;
    })
    .addCase(fetchCurrentUserAgreement.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.data.agreed = false;
    })
    .addCase(fetchCurrentAgreementUri.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchCurrentAgreementUri.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.agreementUri = action.payload;
    })
    .addCase(fetchCurrentAgreementUri.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchCurrentUserAgreement = createAsyncThunk(`${sliceName}/fetchUserAgreement`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch user agreement: params is undefined")
  }
  const {
    contracts,
    account,
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch user agreement: contracts is undefined")
  }
  if (!account) {
    throw new Error("Can't fetch user agreement: account is undefined")
  }

  const agreements = await contracts.getGameFacet("UserAgreementsFacet");
  return await agreements.playerHasAgreed(account);
});

export const fetchCurrentAgreementUri = createAsyncThunk(`${sliceName}/fetchAgreementUri`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch user agreement uri: params is undefined")
  }
  const {
    contracts,
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch user agreement uri: contracts is undefined")
  }

  const agreements = await contracts.getGameFacet("UserAgreementsFacet");
  const doc = await agreements.getUserAgreementDocument();
  if (doc.documentUri === "placeholder") {
    return "/chancey-tandc.pdf";
  } else {
    return doc.documentUri;
  }
});

export const hasUserAgreedToTC = (state) => {
  return state[sliceName].data?.agreed;
};

export const getAgreementUri = (state) => {
  return state[sliceName].data?.agreementUri;
}

export default slice.reducer;