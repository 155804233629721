import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utilities from "../../utils/utilities";

const { ZERO_ADDRESS } = Utilities;

/**
 * These slices are used for keeping
 * state of spending allowances of the game
 * on behalf of the account
 */

const sliceName = "allowances";

const initialState = {
  data: {
    chancey: "0", // The amount of CHANCEY tokens the game is allowed to spend
    game: "0",    // The amount of GAME tokens the game is allowed to spend
    link: "0",    // The amount of LINK tokens the game is allowed to spend (dev only)
  },
  status: "idle",
  error: null
};

const allowancesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchAllowance.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAllowance.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = {...state.data, ...action.payload};
    })
    .addCase(fetchAllowance.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchAllowance = createAsyncThunk(`${sliceName}/fetchOne`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch allowance: params is undefined")
  }

  const {
    account,
    address,
    tokenName,
    tokenAddress,
    contracts
  } = params;
  if (!contracts) {
    throw new Error("Cannot fetch allowance: contracts is undefined");
  }
  if (!account) {
    throw new Error("Cannot fetch allowance: account is undefined");
  }
  if (!tokenName) {
    throw new Error("Cannot fetch allowance: tokenName is undefined");
  }
  if (!tokenAddress) {
    throw new Error("Cannot fetch allowance: tokenAddress is undefined");
  }
  if (!address) {
    throw new Error("Cannot fetch allowance: address is undefined");
  }
  if (tokenAddress !== ZERO_ADDRESS) {
    // Game configured with ERC20 for play
    const erc20 = contracts.IERC20FromAddress(tokenAddress);
    const allowance = await erc20.allowance(account, address);
    return {[tokenName] : allowance.toString()};
  } else {
    // Game configured with native coin for play
    // Return the balance as allowance since.
    const provider = contracts.getProvider();
    const balance = await provider.getBalance(account);
    return {[tokenName] : balance.toString()};
  }
});

export const getAllowance = (state, t) => {
  return state.allowances.data[t];
};

export default allowancesSlice.reducer;