import { createSlice } from "@reduxjs/toolkit";

const sliceName = "freeItemsCart";

const initialState = {
  data: [],
};

const freeItemsCartSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    itemAdded(state, action) {
      if (state.data.includes(action.payload)) {
        return state;
      } else {
        const items = [...state.data];
        items.push(action.payload);
        return {...state, data: items.sort()};
      }
    },
    itemRemoved(state, action) {
      const items = [...state.data];
      items.splice(action.payload, 1);
      return {...state, data: items};
    },
    itemsCleared(state) {
      return {...state, data: []};
    }
  }
});


export const getCartItems = (state) => {
  return state.freeItemsCart.data;
};

export const { itemAdded, itemRemoved, itemsCleared } = freeItemsCartSlice.actions;
export default freeItemsCartSlice.reducer;