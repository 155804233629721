import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from "react-redux";
import { persistStore } from "redux-persist"
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Connectors } from "./connectors/connectors";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import store from "./store/store";
import theme from "./theme/Theme";
import "./i18n";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://abdb3344c1af707311d131c6b4e9aeaa@o4506332381118464.ingest.sentry.io/4506332404645888",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/play.getchancey\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const connectors = Connectors.map(c => [c.provider, c.hooks]);
const loading = () => {
  return (
    <div>Loading...</div>
  )
};
const apolloClient = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/72190/chancey-base-sepolia/v1.0.0",
  cache: new InMemoryCache(),
});

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={loading()} persistor={persistor}>
        <Web3ReactProvider connectors={connectors}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline/>
              <Suspense fallback={null}>
                <ApolloProvider client={apolloClient}>
                  <App />
                </ApolloProvider>
              </Suspense>
            </ThemeProvider>
          </BrowserRouter>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
