import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ConversionUtils from "../../utils/conversions";

const {
  addABISelectorSignature,
} = ConversionUtils;

const sliceName = "loupes";

const initialState = {
  data: [],
  status: "idle",
  error: null
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(discoverFacets.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(discoverFacets.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = action.payload;
    })
    .addCase(discoverFacets.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
})

export const discoverFacets = createAsyncThunk(`${sliceName}/discover`, async(contracts) => {
  const result = [];
  if (contracts) {
    const governedContracts = contracts.getGovernedContracts();
    const targets = Object.keys(governedContracts);
    for (let t = 0; t < targets.length; ++t) {
      const target = targets[t];
      const targetName = governedContracts[target];
      const currentTarget = {target, targetName, facets: []};
      const loupe = await contracts.getLoupe(target);
      if (loupe) {
        const facets = await loupe.facets();
        for (let f = 0; f < facets.length; ++f) {
          const facet = facets[f];
          const contract = await contracts.getDetachedFacetFromAddress(facet.facetAddress);
          const functions = contract.abi.filter(a => a.type === "function").map(a => addABISelectorSignature(a));
          const currentFacet = {
            name: contract.contractName,
            address: facet.facetAddress,
            selectors: functions
          }
          currentTarget.facets.push(currentFacet);
        }
      } else {
        throw new Error(`Can't find loupe for ${targetName}`);
      }
      result.push(currentTarget);
    }
  } else {
    throw new Error("Can't discover facets: contracts not initialized");
  }
  return result;
});

export const getAllFacets = (state) => {
  return state[sliceName].data;
}

export const getFacetForTarget = (state, target) => {
  return state[sliceName].data.find(f => f.target === target);
}

export default slice.reducer;