export default [
  {
    "name": "Ethereum Mainnet",
    "chain": "ETH",
    "icon": "ethereum",
    "rpc": [
      "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
      "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
      "https://api.mycryptoapi.com/eth",
      "https://cloudflare-eth.com",
      "https://ethereum.publicnode.com"
    ],
    "features": [
      {
        "name": "EIP155"
      },
      {
        "name": "EIP1559"
      }
    ],
    "faucets": [],
    "nativeCurrency": {
      "name": "Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "infoURL": "https://ethereum.org",
    "shortName": "eth",
    "chainId": 1,
    "networkId": 1,
    "slip44": 60,
    "ens": {
      "registry": "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
    },
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://etherscan.io",
        "standard": "EIP3091"
      }
    ],
    "louperNetworkName" : "mainnet"
  },
  {
    "name": "Ropsten",
    "title": "Ethereum Testnet Ropsten",
    "chain": "ETH",
    "rpc": [
      "https://ropsten.infura.io/v3/${INFURA_API_KEY}",
      "wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}"
    ],
    "faucets": [
      "http://fauceth.komputing.org?chain=3&address=${ADDRESS}",
      "https://faucet.ropsten.be?${ADDRESS}"
    ],
    "nativeCurrency": {
      "name": "Ropsten Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "infoURL": "https://github.com/ethereum/ropsten",
    "shortName": "rop",
    "chainId": 3,
    "networkId": 3,
    "ens": {
      "registry": "0x112234455c3a32fd11230c42e7bccd4a84e02010"
    },
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://ropsten.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Rinkeby",
    "title": "Ethereum Testnet Rinkeby",
    "chain": "ETH",
    "rpc": [
      "https://rinkeby.infura.io/v3/${INFURA_API_KEY}",
      "wss://rinkeby.infura.io/ws/v3/${INFURA_API_KEY}"
    ],
    "faucets": [
      "http://fauceth.komputing.org?chain=4&address=${ADDRESS}",
      "https://faucet.rinkeby.io"
    ],
    "nativeCurrency": {
      "name": "Rinkeby Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "infoURL": "https://www.rinkeby.io",
    "shortName": "rin",
    "chainId": 4,
    "networkId": 4,
    "ens": {
      "registry": "0xe7410170f87102df0055eb195163a03b7f2bff4a"
    },
    "explorers": [
      {
        "name": "etherscan-rinkeby",
        "url": "https://rinkeby.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Goerli",
    "title": "Ethereum Testnet Goerli",
    "chain": "ETH",
    "rpc": [
      "https://goerli.infura.io/v3/${INFURA_API_KEY}",
      "wss://goerli.infura.io/v3/${INFURA_API_KEY}",
      "https://rpc.goerli.mudit.blog/"
    ],
    "faucets": [
      "http://fauceth.komputing.org?chain=5&address=${ADDRESS}",
      "https://goerli-faucet.slock.it?address=${ADDRESS}",
      "https://faucet.goerli.mudit.blog"
    ],
    "nativeCurrency": {
      "name": "Goerli Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "infoURL": "https://goerli.net/#about",
    "shortName": "gor",
    "chainId": 5,
    "networkId": 5,
    "ens": {
      "registry": "0x112234455c3a32fd11230c42e7bccd4a84e02010"
    },
    "explorers": [
      {
        "name": "etherscan-goerli",
        "url": "https://goerli.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Optimism",
    "chain": "ETH",
    "rpc": [
      "https://mainnet.optimism.io/"
    ],
    "faucets": [],
    "nativeCurrency": {
      "name": "Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "infoURL": "https://optimism.io",
    "shortName": "oeth",
    "chainId": 10,
    "networkId": 10,
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://optimistic.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Cronos Mainnet Beta",
    "chain": "CRO",
    "rpc": [
      "https://evm.cronos.org",
      "https://cronos-evm.publicnode.com"
    ],
    "features": [
      {
        "name": "EIP1559"
      }
    ],
    "faucets": [],
    "nativeCurrency": {
      "name": "Cronos",
      "symbol": "CRO",
      "decimals": 18
    },
    "infoURL": "https://cronos.org/",
    "shortName": "cro",
    "chainId": 25,
    "networkId": 25,
    "explorers": [
      {
        "name": "Cronos Explorer",
        "url": "https://cronoscan.com",
        "standard": "none"
      }
    ]
  },
  {
    "name": "Kovan",
    "title": "Ethereum Testnet Kovan",
    "chain": "ETH",
    "rpc": [
      "https://kovan.poa.network",
      "http://kovan.poa.network:8545",
      "https://kovan.infura.io/v3/${INFURA_API_KEY}",
      "wss://kovan.infura.io/ws/v3/${INFURA_API_KEY}",
      "ws://kovan.poa.network:8546"
    ],
    "faucets": [
      "http://fauceth.komputing.org?chain=42&address=${ADDRESS}",
      "https://faucet.kovan.network",
      "https://gitter.im/kovan-testnet/faucet"
    ],
    "nativeCurrency": {
      "name": "Kovan Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://kovan.etherscan.io",
        "standard": "EIP3091"
      }
    ],
    "infoURL": "https://kovan-testnet.github.io/website",
    "shortName": "kov",
    "chainId": 42,
    "networkId": 42
  },
  {
    "name": "Binance Smart Chain Mainnet",
    "chain": "BSC",
    "rpc": [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "https://bsc.publicnode.com",
      "wss://bsc-ws-node.nariox.org"
    ],
    "faucets": [
      "https://free-online-app.com/faucet-for-eth-evm-chains/"
    ],
    "nativeCurrency": {
      "name": "Binance Chain Native Token",
      "symbol": "BNB",
      "decimals": 18
    },
    "infoURL": "https://www.binance.org",
    "shortName": "bnb",
    "chainId": 56,
    "networkId": 56,
    "slip44": 714,
    "explorers": [
      {
        "name": "bscscan",
        "url": "https://bscscan.com",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Binance Smart Chain Testnet",
    "chain": "BSC",
    "rpc": [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545"
    ],
    "faucets": [
      "https://testnet.binance.org/faucet-smart"
    ],
    "nativeCurrency": {
      "name": "Binance Chain Native Token",
      "symbol": "tBNB",
      "decimals": 18
    },
    "infoURL": "https://testnet.binance.org/",
    "shortName": "bnbt",
    "chainId": 97,
    "networkId": 97,
    "explorers": [
      {
        "name": "bscscan-testnet",
        "url": "https://testnet.bscscan.com",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Polygon Mainnet",
    "chain": "Polygon",
    "icon": "polygon",
    "isSupported" : true,
    "rpc": [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.matic.network",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com",
      "https://polygon-bor.publicnode.com"
    ],
    "faucets": [],
    "nativeCurrency": {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    "infoURL": "https://polygon.technology/",
    "shortName": "matic",
    "chainId": 137,
    "networkId": 137,
    "slip44": 966,
    "explorers": [
      {
        "name": "polygonscan",
        "url": "https://polygonscan.com",
        "standard": "EIP3091"
      }
    ]
  },
  {
    "name": "Local Hardhat Node",
    "chain": "GO",
    "icon" : "polygon",
    "isSupported" : true,
    "nativeCurrency": {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    "chainId": 1337,
    "networkId": 1337,
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://etherscan.io",
        "standard": "EIP3091"
      }
    ],
    "louperNetworkName" : "mainnet"
  },
  {
    "name": "Local Hardhat Node (ETH)",
    "chain": "GO",
    "icon" : "ethereum",
    "isSupported" : true,
    "nativeCurrency": {
      "name": "ETH",
      "symbol": "ETH",
      "decimals": 18
    },
    "chainId": 31337,
    "networkId": 31337,
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://etherscan.io",
        "standard": "EIP3091"
      }
    ],
    "louperNetworkName" : "mainnet"
  },
  {
    "name": "Base Sepolia",
    "chain": "Base",
    "icon" : "ethereum",
    "isSupported" : true,
    "nativeCurrency": {
      "name": "ETH",
      "symbol": "Ξ",
      "decimals": 18
    },
    "chainId": 84532,
    "networkId": 84532,
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://sepolia.basescan.org/",
        "standard": "EIP3091"
      }
    ],
    "louperNetworkName" : "baseSepolia"
  },
  {
    "name": "Mumbai",
    "chain": "Polygon",
    "icon" : "polygon",
    "isSupported" : true,
    "nativeCurrency": {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    "chainId": 80001,
    "networkId": 80001,
    "explorers": [
      {
        "name": "etherscan",
        "url": "https://etherscan.io",
        "standard": "EIP3091"
      }
    ],
    "louperNetworkName" : "mainnet"
  }
]