import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import allowancesReducer from "./slices/allowances-slice";
import accountBalancesReducer from "./slices/account-balances-slice";
import gameBalancesReducer from "./slices/game-balances-slice";
import gameConfigurationsReducer from "./slices/game-configurations-slice";
import potSliceReducer from "./slices/pot-slice";
import drawsSliceReducer from "./slices/draws-slice";
import tokensReducer from "./slices/tokens-slice";
import winningsSliceReducer from "./slices/winnings-slice";
import gameStateSliceReducer from "./slices/game-state-slice";
import ticketsSliceReducer from "./slices/tickets-slice";
import cartSliceReducer from "./slices/cart-slice";
import freeItemsCartSlice from "./slices/free-items-cart-slice";
import stakesSliceReducer from "./slices/stakes-slice";
import currentBuyRewardsSlice from "./slices/current-buy-rewards-slice";
import currentTicketPriceSlice from "./slices/current-ticket-price-slice";
import governanceSlice from "./slices/governance-slice";
import loupesSlice from "./slices/loupes-slice";
import userSlice from "./slices/user-slice";
import preferencesSlice from "./slices/preferences-slice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"

const rootReducer = combineReducers({
  accountBalances: accountBalancesReducer,
  gameBalances: gameBalancesReducer,
  gameConfigurations: gameConfigurationsReducer,
  allowances: allowancesReducer,
  tokens: tokensReducer,
  pot: potSliceReducer,
  draws: drawsSliceReducer,
  winnings: winningsSliceReducer,
  gameState: gameStateSliceReducer,
  tickets: ticketsSliceReducer,
  cart: cartSliceReducer,
  freeItemsCart: freeItemsCartSlice,
  stakes: stakesSliceReducer,
  currentBuyRewards: currentBuyRewardsSlice,
  currentTicketPrice: currentTicketPriceSlice,
  governance: governanceSlice,
  loupes: loupesSlice,
  user: userSlice,
  preferences: preferencesSlice
});

const persistConfig = {
  key: "root",
  version: 2,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});