import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/**
 * These slices are used for keeping
 * state of balances for both the account
 * and the game addresses
 */
const sliceName = "gameBalances";

const initialState = {
  data: {
    chancey: "0", // Game balance of CHANCEY Token
    game: "0",    // Game balance of GAME Token
    link: "0"     // Game balance of LINK Token
  },
  status: "idle",
  error: null
};

const gameBalancesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchGameBalance.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchGameBalance.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = {...state.data, ...action.payload};
    })
    .addCase(fetchGameBalance.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchGameBalance = createAsyncThunk(`${sliceName}/fetchOne`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch account balance: params is undefined")
  }

  const {
    contracts,
    game,
    tokenName,
    tokenAddress
  } = params;

  if (!contracts) {
    throw new Error("Cannot fetch game balance: contracts is undefined");
  }
  if (!game) {
    throw new Error("Cannot fetch game balance: game is undefined");
  }
  if (!tokenName) {
    throw new Error("Cannot fetch game balance: tokenName is undefined");
  }
  if (!tokenAddress) {
    throw new Error("Cannot fetch game balance: tokenAddress is undefined");
  }
  const erc20 = contracts.IERC20FromAddress(tokenAddress);
  const balance = await erc20.balanceOf(game);
  return {[tokenName] : balance.toString()};
});

export const getGameBalance = (state, t) => {
  return state.gameBalances.data[t];
};

export default gameBalancesSlice.reducer;