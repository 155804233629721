import { Box,Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const LabelWithValue = (props) => {
  const { label, value, accentLabel, separator=":" } = props;
  const theme = useTheme();
  const labelStyle = {...theme.typography.tableCell};
  const valueStyle = {...theme.typography.tableCell};
  if (accentLabel) {
    valueStyle.color = theme.palette.tertiary.main
  } else {
    labelStyle.color = theme.palette.tertiary.main
  }
  return <Stack direction="row" spacing={1}>
    <Box>
      <Typography sx={labelStyle}>
        {label}{separator}
      </Typography>
    </Box>
    <Box sx={{overflowWrap: "anywhere", textAlign: "left"}}>
      <Typography sx={valueStyle}>
        {value}
      </Typography>
    </Box>
  </Stack>
}

export default LabelWithValue