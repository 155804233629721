import { createSlice } from "@reduxjs/toolkit";

const sliceName = "preferences";

const initialState = {
  data: {},
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPreference(state, action) {
        const d = {...state.data};
        const {key, value} = action.payload
        d[key] = value;
        return {...state, data: d};
    }
  }
});


export const getPreference = (state, preference) => {
    return state[sliceName].data[preference];
};

export const { setPreference } = slice.actions;
export default slice.reducer;