import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

const ErrorDialogue = (props) => {
  const {
    open,
    close,
    title,
    body
  } = props;

  const { t } = useTranslation("translation", { keyPrefix: "error.dialog" });
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
        }
      }}
      >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          <Typography>{body}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("dismiss")}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ErrorDialogue;