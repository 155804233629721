import { createSlice } from "@reduxjs/toolkit";

const sliceName = "cart";

const initialState = {
  data: [],
};

const cartSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    itemAdded(state, action) {
      if (state.data.includes(action.payload)) {
        return state;
      } else {
        const items = [...state.data];
        items.push(action.payload);
        return {...state, data: items.sort()};
      }
    },
    itemsAdded(state, action) {
      const uniqueItems = action.payload.filter(i => !state.data.includes(i));
      if (uniqueItems.length === 0) {
        return state;
      } else {
        const items = [...state.data, ...uniqueItems];
        return {...state, data: items.sort()};
      }
    },
    itemRemoved(state, action) {
      const items = [...state.data];
      items.splice(action.payload, 1);
      return {...state, data: items};
    },
    itemsCleared(state) {
      return {...state, data: []};
    }
  }
});


export const getCartItems = (state) => {
  return state.cart.data;
};

export const { itemAdded, itemsAdded, itemRemoved, itemsCleared } = cartSlice.actions;
export default cartSlice.reducer;