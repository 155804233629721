import { createSlice } from "@reduxjs/toolkit";

const sliceName = "currentBuyRewards";

const initialState = {
  data: 0,
};

const currentBuyRewardSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    currentBuyRewardUpdated(state, action) {
      return {...state, data: action.payload};
    }
  }
});


export const getCurrentBuyReward = (state) => {
  return state[sliceName].data;
};

export const { currentBuyRewardUpdated } = currentBuyRewardSlice.actions;
export default currentBuyRewardSlice.reducer;