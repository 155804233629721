import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WalletStateContext } from "../context/wallet";
import { ContractsStateContext } from "../context/contracts";
import { addPurchasedTicket } from '../store/slices/tickets-slice';
import { fetchCurrentDraw, getCurrentDraw, getWinningDraws } from "../store/slices/draws-slice";
import { getToken } from "../store/slices/tokens-slice";
import { fetchAccountBalance, getAccountBalance } from "../store/slices/account-balances-slice";
import { getCartItems, itemAdded, itemRemoved, itemsCleared } from "../store/slices/free-items-cart-slice";
import { getCurrentBuyReward } from "../store/slices/current-buy-rewards-slice";
import { getRewardsConfigurations, fetchRewardsConfigurations, getCarryoverTickets } from "../store/slices/game-configurations-slice";
import { fetchPot, getPot } from '../store/slices/pot-slice';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Alert, Box, Button, Chip, Grid, Paper, Snackbar, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useApolloClient, gql } from '@apollo/client';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ConversionUtils from "../utils/conversions";
import Utilities from "../utils/utilities";
import TransactionModal from "../components/TransactionModal";
import TicketsHistory from "../components/TicketsHistory";
import NumberInput from "../components/NumberInput";
import { TABLE_SIZE, TICKETS_QUERY } from "../utils/apollo-queries";

const {
  formatUnits,
  toBigNumber,
  keccak256Hash,
  encodeParameters
} = ConversionUtils;

const {
  MIN_NUMBER,
  MAX_NUMBER,
  NUMBERS_COUNT,
  generateRandomNumbers,
  numbersToTicketLine
} = Utilities;

const FreeTickets = () => {
  const { t } = useTranslation("translation", { keyPrefix: "tickets" });
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const navigate = useNavigate();
  const [randomLine, setRandomLine] = useState();
  const [ticketNumbers, setTicketNumbers] = useState();
  const [dupeDetected, setDupeDetected] = useState(false);
  const [addToCartDisabled, setAddToCartDisabled] = useState(true);
  const [hasEnoughBalance, setHasEnoughBalance] = useState(false);
  const [cartCost, setCartCost] = useState("0");
  const [txDiagOpen, setTxDiagOpen] = useState({buy: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({buy: false});
  const [cartIsOverflown, setCartIsOverflown] = useState(false);
  const [freeTicketExchangeRate, setFreeTicketExchangeRate] = useState("10");
  const [avaialabeFreeTickets, setAvailableFreeTickets] = useState(0);
  const [maxFreeTicketForUser, setMaxFreeTicketForUser] = useState(0);
  const [potIsBigEnough, setPotIsBigEnough] = useState(false);
  const [requiredPotValue, setRequiredPotValue] = useState("0");
  const [txCount, setTxCount] = useState(0);
  const wallet = useContext(WalletStateContext);
  const contracts = useContext(ContractsStateContext);
  const draw = useSelector(state => getCurrentDraw(state));
  const winningDraws = useSelector(state => getWinningDraws(state));
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const gameToken = useSelector(state => getToken(state, "game"));
  const carryoverTickets = useSelector(state => getCarryoverTickets(state));
  const cartItems = useSelector(state => getCartItems(state));
  const currentBuyReward = useSelector(state => getCurrentBuyReward(state));
  const dispatch = useDispatch();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));
  const chanceyBalance = useSelector(state => getAccountBalance(state, "chancey"));
  const currentPot = useSelector(state => getPot(state, "current"));
  const nextPot = useSelector(state => getPot(state, "next"));
  const rewardsConfigs = useSelector(state => getRewardsConfigurations(state));

  useEffect(() => {
    if (contracts) {
      dispatch(fetchCurrentDraw(contracts));
      dispatch(fetchPot(contracts));
    }
  }, [contracts]);

  useEffect(() => {
    if (contracts) {
      dispatch(fetchRewardsConfigurations(contracts));
    }
  }, [contracts]);

  useEffect(() => {
    if (rewardsConfigs && chanceyToken) {
      setFreeTicketExchangeRate(formatUnits(rewardsConfigs.freeTicketExchangeRate, chanceyToken.decimals, 0).toString());
    }
  }, [rewardsConfigs, chanceyToken]);

  useEffect(() => {
    if (draw && rewardsConfigs && chanceyBalance && chanceyToken && gameToken) {
      const totalPot = toBigNumber(currentPot).add(toBigNumber(nextPot));
      const target = toBigNumber(rewardsConfigs.potLiftTarget);
      const bigEnough = totalPot.gte(target);
      setPotIsBigEnough(bigEnough);
      if (!bigEnough) {
        setAvailableFreeTickets(0);
        setMaxFreeTicketForUser(0);
        setRequiredPotValue(formatUnits(rewardsConfigs.potLiftTarget, gameToken.decimals, 0).toString());
      } else {
        const ticketsCount = parseInt(draw.ticketsCount);
        const ratio = parseInt(rewardsConfigs.freeTicketsToSoldRate);
        if (ticketsCount === 0) {
          setAvailableFreeTickets(0);
        } else {
          const freeTicketsIssued = parseInt(draw.freeTicketsCount);
          const soldTickets = ticketsCount - freeTicketsIssued;
          const maxFreeTickets = parseInt(soldTickets / ratio);
          const availableFreeTickets = maxFreeTickets - freeTicketsIssued;
          setAvailableFreeTickets(availableFreeTickets);
        }
        const m = toBigNumber(chanceyBalance).div(toBigNumber(rewardsConfigs.freeTicketExchangeRate));
        setMaxFreeTicketForUser(Math.min(m.toNumber(), 10));
        setHasEnoughBalance(toBigNumber(chanceyBalance).gte(toBigNumber(rewardsConfigs.freeTicketExchangeRate)));
      }
    }
  }, [draw, rewardsConfigs, chanceyToken, chanceyBalance, currentPot, nextPot, gameToken]);

  useEffect(() => {
    if (ticketNumbers) {
      const set = Array.from(new Set(ticketNumbers));
      setAddToCartDisabled(set.length !== NUMBERS_COUNT);
    }
  }, [ticketNumbers]);

  useEffect(() => {
    const cost = toBigNumber(cartItems.length).mul(toBigNumber(rewardsConfigs.freeTicketExchangeRate));
    const formatted = formatUnits(cost, chanceyToken.decimals, 0).toString();
    setCartCost(formatted);
    setCartIsOverflown(cartItems.length > Math.min(maxFreeTicketForUser, avaialabeFreeTickets));
  }, [cartItems, rewardsConfigs, chanceyToken, chanceyBalance]);

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const startTransactions = async(tx) => {
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const updateTicketLine = (numbers) => {
    setTicketNumbers(numbers);
  }

  const addTicketLineToCart = () => {
    const line = numbersToTicketLine(ticketNumbers);
    if (cartItems.includes(line)) {
      setDupeDetected(true);
    } else {
      dispatch(itemAdded(numbersToTicketLine(ticketNumbers)));
      setRandomLine(!randomLine);
    }
  }

  const generateRandomTicket = () => {
    let line;
    do {
      line = numbersToTicketLine(generateRandomNumbers());
    } while (cartItems.includes(line));
    setRandomLine(line.split(","))
  }

  const removeFromCart = (index) => {
    dispatch(itemRemoved(index));
  }

  const readNewBalances = async () => {
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "game",
      tokenAddress: gameToken.address,
      account: wallet.account
    }));
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "chancey",
      tokenAddress: chanceyToken.address,
      account: wallet.account
    }));
  }

  const buyTransactions = [{
    pendingLabel: t("trStep1Pending"),
    completedLabel: t("trStep1Completed"),
    failedLabel: t("trStep1Failed"),
    tx: async () => {
      // A fake transaction for starting MM
      return 0;
    }
  },{
    pendingLabel: t("trStep5Pending"),
    completedLabel: t("trStep5Completed"),
    failedLabel: t("trStep5Failed"),
    tx: async () => {
      try {
        const tickets = cartItems.map(t => `0x${t.split(",").map(t => parseInt(t).toString(16).padStart(2, "0")).join("")}`);
        const game = await contracts.getGameFacet("GameFacet");
        const erc1363 = await contracts.getChanceyFacet("ChanceyERC1363ExtendedFacet");
        const functionCall = "burnAndCall(uint256,address,bytes)";
        const action = keccak256Hash("CLAIM_FREE_TICKETS");
        const data = encodeParameters(["bytes32", "bytes[]"], [action, tickets]);
        const cost = toBigNumber(rewardsConfigs.freeTicketExchangeRate).mul(cartItems.length);
        const res = await erc1363[functionCall](cost, game.address, data);
        const receipt = await res.wait();
        const { events } = receipt;
        dispatch(addPurchasedTicket({
          events,
          apollo: {
            client: apolloClient,
            query: TICKETS_QUERY,
            variables: {
              account: wallet.account,
              drawId: draw.id,
              first: TABLE_SIZE,
              skip: 0
            }
          }
        }));
        dispatch(itemsCleared());
        setTxCount(txCount + 1);
        readNewBalances();
        return 0;
      } catch (e) {
        console.log("Failed allow transaction");
        if (e.code === 4001) {
          console.log("User rejected transaction");
        } else {
          console.log(e);
        }
        return e;
      }
    }
  }];

  const layoutWidth = smallScreen ? {} : {width: 700, display:"inline-block"}
  let noticeTitle = "redeemNotEnoughPot";
  let noticeArgs = {value: requiredPotValue, symbol: gameToken.symbol};
  if (potIsBigEnough) {
    if (hasEnoughBalance) {
      noticeTitle = "redeemTickets";
      noticeArgs = {
        count: avaialabeFreeTickets,
        symbol: chanceyToken.symbol,
        reward: formatUnits(currentBuyReward, chanceyToken.decimals).toString()
      };
    } else {
      noticeTitle = "redeemNotEnoughBalance";
      noticeArgs = {symbol: chanceyToken.symbol};
    }
  }
  return (
    <div>
      <Snackbar
        open={dupeDetected}
        onClose={() => setDupeDetected(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert variant="filled" severity="error">{t("duplicateError")}</Alert>
      </Snackbar>
      <Box sx={layoutWidth}>
        <Stack alignItems="center" direction="column">
          <Box>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Typography variant="h3">
                {t("headerRedeem")}
              </Typography>
              <Stack>
                <Typography component={'span'} sx={{ ...theme.typography.tableHeader }} color={theme.palette.tertiary.main}>
                  {t("ticketExchangeRate", {points: freeTicketExchangeRate})}
                </Typography>
              </Stack>
              <Box sx={{paddingBottom: 4}} display="flex" justifyContent="center">
                <Stack direction="row" alignItems="center">
                  <Typography component={'span'} color="primary">
                    {t(noticeTitle, noticeArgs)}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          {(avaialabeFreeTickets !== 0 && potIsBigEnough && hasEnoughBalance) && <Box sx={{p: 1}}>
            <Stack alignItems="stretch" direction="column">
              <NumberInput
                size={NUMBERS_COUNT}
                min={MIN_NUMBER-1}
                max={MAX_NUMBER}
                length={2}
                choice={randomLine}
                handleChange={updateTicketLine}
              >
              </NumberInput>
              <Typography component={'span'} color={theme.palette.tertiary.main}>
                {t("subheader", {count: NUMBERS_COUNT, min: MIN_NUMBER, max: MAX_NUMBER})}
              </Typography>
              <Box sx={{paddingBottom: 2, paddingTop: 1}}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Button variant="outlined" onClick={generateRandomTicket} tabIndex={-1} >{t("generateRandom")}</Button>
                  <Button variant="outlined" onClick={addTicketLineToCart} disabled={addToCartDisabled}>{t("addToCart")}</Button>
                </Stack>
              </Box>
              <Paper>
                {cartItems.length > 0 && <Box sx={{p: 1, width:"100%"}}>
                  <Grid container spacing={1}>
                    {cartItems.map((t, i) => {
                      return (
                        <Grid key={i} item>
                          <Chip label={t} onDelete={() => removeFromCart(i)}></Chip>
                        </Grid>
                      )})}
                  </Grid>
                </Box>}
                <Box sx={{p: 1}}>
                  <Stack direction={smallScreen ? "column" : "row"} spacing={2} alignItems="st" justifyContent={smallScreen ? "flex-start" : "flex-end"}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                      <ShoppingCartOutlinedIcon color="primary"></ShoppingCartOutlinedIcon>
                      <Box>
                        <Stack>
                          <Typography textAlign="left" sx={{ ...theme.typography.tableCell }}>
                            {t("cartSummaryRedeem", {count: cartItems.length, cost: cartCost, currency: chanceyToken.symbol})}
                          </Typography>
                          <Typography textAlign="left" sx={{ ...theme.typography.tableCell }}>
                            {t("cartMax", {count: Math.min(maxFreeTicketForUser, avaialabeFreeTickets)})}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <Stack directrion="row" alignItems="center">
                      <Box>
                      <Tooltip
                        title={wallet?.account ? (cartItems.length === 0 ? t("cartItemEmpty") : t("cartOverflow", {currency: gameToken.symbol})) : t("walletNotConnected")}
                        disableHoverListener={wallet?.account && !cartIsOverflown && cartItems.length !== 0}
                        arrow
                        placement="top"
                      >
                        <Box>
                          <Button
                            fullWidth
                            variant="outlined"
                            disabled={!wallet?.account || cartIsOverflown || cartItems.length === 0}
                            onClick={() => startTransactions("buy")}
                          >
                            {t("checkout")}
                          </Button>
                        </Box>
                      </Tooltip>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <TransactionModal
                  open={txDiagOpen["buy"]}
                  onClose={() => closeTxDiag("buy")}
                  steps={buyTransactions}
                  onFinished={() => onTxDiagFinished("buy")}
                >
                </TransactionModal>
              </Paper>
            </Stack>
          </Box>}
          {(avaialabeFreeTickets === 0 || !potIsBigEnough || !hasEnoughBalance) && <Button variant="outlined" onClick={() => navigate("/tickets")} >
            {t("buyTickets")}
          </Button>}
        </Stack>
      </Box>
      <TicketsHistory
        account={wallet?.account}
        drawId={draw?.id}
        ticketsQuery={TICKETS_QUERY}
        tableSize={TABLE_SIZE}
      />
    </div>
  );
}
export default FreeTickets