import React from "react";
import PropTypes from "prop-types";

export const WalletStateContext = React.createContext();
export const WalletDispatchContext = React.createContext();
export const SET_WALLET = "SET_WALLET";

export const WalletReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_WALLET: {
      return {...state, ...payload};
    }
    default:
      return state;
  }
};

function Wallet(props) {
  const { initialState, dispatch } = props;
  return (
    <WalletStateContext.Provider value={initialState}>
      <WalletDispatchContext.Provider value={dispatch}>
        {props.children}
      </WalletDispatchContext.Provider>
    </WalletStateContext.Provider>
  );
}

Wallet.propTypes = {
  initialState: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default Wallet;