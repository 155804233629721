import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "tickets";

const initialState = {
  data: [],
  status: "idle",
  error: null
};

const ticketsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchAllTickets.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAllTickets.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = action.payload;
    })
    .addCase(fetchAllTickets.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(addPurchasedTicket.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(addPurchasedTicket.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = [...action.payload, ...state.data];
    })
    .addCase(addPurchasedTicket.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
  }
});

const convertTicket = async (ticket) => {
  const keys = ["data", "drawId", "holder", "ticketId"];
  const res = {};
  const {args} = ticket;
  keys.forEach(k => {
    res[k] = args[k] && args[k]._isBigNumber ? args[k].toString() : args[k];
  });
  const block = await ticket.getBlock();
  res["timestamp"] = block.timestamp;
  return res;
}

export const fetchAllTickets = createAsyncThunk(`${sliceName}/fetchAll`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch tickets: params is undefined")
  }
  const {
    contracts,
    account,
    blockNumber,
    drawId
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch tickets, contracts is undefined")
  }
  if (!account) {
    throw new Error("Can't fetch tickets, account is undefined")
  }

  const ticketBooth = await contracts.getGameFacet("TicketBoothFacet");
  const filter = ticketBooth.filters.TicketPurchased(account, drawId);
  const res = await ticketBooth.queryFilter(filter, blockNumber);
  const purchases = res.filter(r => r.event === "TicketPurchased");
  const tickets = await Promise.all(purchases.map(async(t) => await convertTicket(t)));
  return tickets.sort((a,b) => b.timestamp - a.timestamp);
});

export const addPurchasedTicket = createAsyncThunk(`${sliceName}/add`, async(params) => {
  const { events, apollo } = params;
  const purchases = events.filter(p => p.event === "TicketPurchased");
  const tickets = await Promise.all(purchases.map(async(t) => await convertTicket(t)));
  if (apollo) {
    // The code below reads the apollo cache into memory
    // adds the new ticket and saves the data back to cache
    // that now contains the old tickets and the newly added
    // tickets.
    try {
      const { client, query, variables } = apollo;
      const dataBefore = client.readQuery({ query, variables });
      const insertTickets = tickets.map(t => {
        return {
          "__typename": "TicketPurchased",
          "ticketId": t.ticketId,
          "holder": t.holder,
          "drawId": t.drawId,
          "data": t.data,
          "blockTimestamp": t.blockTimestamp || t.timestamp
        }
      });
      client.writeQuery({
        query, variables,
        data: {
          ticketPurchaseds: [...insertTickets, ...dataBefore.ticketPurchaseds],
        },
      });
    } catch (e) {
      console.error("Failed to update apollo cache:", e);
    }
  }
  return tickets.sort((a,b) => b.timestamp - a.timestamp);
});

export const getAllTickets = (state) => {
  return state.tickets.data;
};


export default ticketsSlice.reducer;