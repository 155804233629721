import { Box,Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useLazyQuery, gql } from '@apollo/client';

import ChanceyTable from './ChanceyTable';
import ConversionUtils from "../utils/conversions";
import moment from "moment";
import { useEffect, useState } from 'react';

const {
  ticketLineFromHex,
} = ConversionUtils;

// This won't show up in local host dev without
// having a grapql server setup.
const TicketsHistory = ({ account, drawId, ticketsQuery, tableSize }) => {
  const { t } = useTranslation("translation", { keyPrefix: "tickets" });
  const [fetchTickets, { loading, error, data }] = useLazyQuery(ticketsQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [tickets, setTickets] = useState([]);
  const [loadedAllTickets, setLoadedAllTickets] = useState(true);
  const theme = useTheme();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));
  const spacing = smallScreen ? 1 : 2;

  const tableData = {
    columns : [
      {key: "data", name: t("ticketNumbers"), format: (n) => ticketLineFromHex(n)},
      {key: "blockTimestamp", name: t("ticketPurchaseDate"), format: (t) => moment(new Date(t * 1000)).format("llll")},
      {key: "drawId", name: t("ticketPurchaseDraw")},
    ],
    rows: tickets,
  }

  useEffect(() => {
    if (account && drawId) {
      const first = tableSize;
      const skip = (currentPage - 1) * tableSize;
      fetchTickets({variables: { account, drawId, first, skip }});
    }
  }, [account, drawId, currentPage]);

  useEffect(() => {
    if (data?.ticketPurchaseds.length > 0) {
      setTickets(data.ticketPurchaseds);
      setLoadedAllTickets(data.ticketPurchaseds.length < tableSize);
    } else {
      setLoadedAllTickets(true);
    }
  },[data]);


  const onPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const onNext = () => {
    if (!loadedAllTickets) {
      setCurrentPage(currentPage + 1);
    }
  }

  return <Box sx={{paddingTop: 2}}>
    <Stack spacing={spacing} alignItems="center" >
      <Box sx={{paddingTop: 4, paddingBottom: 4}}>
        <Typography variant="h4">
          {t("myTicketsTitle")}
        </Typography>
        {/* <Typography sx={{ ...theme.typography.subtitle }} color={theme.palette.tertiary.main}>
          {t("myTicketsDetails", {count: tickets.length})}
        </Typography> */}
      </Box>
      {loading && <p>{t("fetchingTickets")}</p>}
      {error && <p>{t("errorFetchingTickets", {error: error.message})}</p>}
      {!loading && !error && tickets.length !== 0 && <Box sx={{width: {xs: "100%", md: 700}}}>
        <ChanceyTable
        data={tableData}
        compact={smallScreen}
        showPrevious={currentPage > 1}
        showNext={!loadedAllTickets}
        onPrevious={onPrevious}
        onNext={onNext}
        >
        </ChanceyTable>
      </Box>}
    </Stack>
  </Box>
}

export default TicketsHistory