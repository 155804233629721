import { Box, Button, LinearProgress, Modal, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useRef } from "react";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TransactionModal = (props) => {
  const {
    open,
    onClose,
    onFinished,
    steps,
    title,
    args
  } = props;
  const previousOpen = usePrevious(open);
  const previousSteps = usePrevious(steps);
  const theme = useTheme();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation("translation", { keyPrefix: "modal" });
  const [transactionSteps, setTransactionSteps] = useState([]);
  const [modalTitle, setModalTitle] = useState(t("dontClose"));
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [txErrorMessage, setTxErrorMessage] = useState("");
  const orientation = smallScreen ? "vertical" : "horizontal";

  useEffect(() => {
    if (transactionSteps.length !== 0) {
      const completed = transactionSteps.filter(s => s.completed);
      const p = Math.ceil(100 / transactionSteps.length) * completed.length;
      setProgress(p);
      if (completed.length === transactionSteps.length || transactionSteps.some(s => s.error)) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }
  }, [transactionSteps]);

  useEffect(() => {
    if (title) {
      setModalTitle(title);
    }
  }, [title]);

  useEffect(() => {
    if (!previousSteps) {
      setTransactionSteps(steps);
    }
  }, [steps, previousSteps]);

  useEffect(() => {
    if (open && open != previousOpen) {
      setTxErrorMessage("");
      startTransactions();
    }
  }, [open, previousOpen]);

  const startTransactions = async() => {
    for(let i = 0; i < steps.length; ++i) {
      startTransaction(i);
      const t = steps[i];
      const error = await t.tx(args);
      if (error) {
        failTransaction(i, error);
        const errorMessage = error.data ? error.data.message : error.message;
        setTxErrorMessage(errorMessage);
        onFinished(error);
        break;
      } else {
        completeTransaction(i);
      }
    }
    onFinished();
  }

  const completeTransaction = (step) => {
    const txs = [...steps];
    txs[step].completed = true;
    setTransactionSteps(txs);
    console.debug("Completed transaction", step);
    console.debug(txs[step]);
  }

  const failTransaction = (step, error) => {
    const txs = [...steps];
    txs[step].error = error;
    console.error("Failing transaction", step);
    console.error(txs[step]);
    setTransactionSteps(txs);
  }

  const startTransaction = (step) => {
    const txs = [...steps];
    txs[step].completed = false;
    txs[step].error = false;
    setTransactionSteps(txs);
    console.debug("Starting transaction", step, args);
    console.debug(txs[step]);
  }

  return (
    <div>
    <Modal open={open} onClose={onClose}>
      <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: smallScreen ? "95%" : 500,
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          p: 4
      }}>
        <Box sx={{paddingBottom: 4, textAlign: "center"}}>
          <Typography>
            {modalTitle}
          </Typography>
        </Box>
        <Stepper nonLinear color="primary" alternativeLabel={!smallScreen} orientation={orientation}>
          {transactionSteps.map((s, i) => {
            return(
              <Step key={i} completed={s.completed}>
                <StepLabel error={!!s.error}>
                  {s.completed ? s.completedLabel : s.error ? s.failedLabel : s.pendingLabel}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box sx={{paddingTop: 3}}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={progress}></LinearProgress>
        </Box>
        <Box display={completed ? "flex" : "none"} justifyContent="flex-end" alignItems="flex-end" sx={{paddingTop: 3}}>
          <Button onClick={onClose}>Close</Button>
        </Box>
        {txErrorMessage && <Box sx={{textAlign: "center"}}>
          <Typography sx={{ ...theme.typography.subtleInfo, wordWrap: 'break-word' }}>{`Error: ${txErrorMessage}`}</Typography>
        </Box>}
      </Box>
    </Modal>
    </div>
  )
}

export default TransactionModal;