import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uniqWith } from "lodash";

const sliceName = "draws";

const initialState = {
  data: {
    all: [],
    winning: [],
    current: {
      id: "0",
      createdAt:  "0",
      playedAt: "0",
      numbers:  "0x",
      payout: "0",
      freeTicketsCount: "0",
      ticketsCount: "0",
      vrfRequestId: "0x0000000000000000000000000000000000000000000000000000000000000000",
    }
  },
  status: "idle",
  error: null
};

const drawsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchAllDraws.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAllDraws.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.all = action.payload;
    })
    .addCase(fetchAllDraws.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchWinningDraws.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchWinningDraws.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.winning = action.payload;
    })
    .addCase(fetchWinningDraws.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchCurrentDraw.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchCurrentDraw.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.current = action.payload;
    })
    .addCase(fetchCurrentDraw.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

const convertDraw = (draw) => {
  const keys = [
    "id", "createdAt", "playedAt",
    "payout", "ticketsCount", "freeTicketsCount",
    "numbers", "randomnessRequestId"
  ]
  const res = {};
  keys.forEach(k => {
    res[k] = draw[k] && draw[k]._isBigNumber ? draw[k].toString() : draw[k];
  });
  return res;
}

const convertWinningDraw = async (winner) => {
  const { args } = winner;
  const block = await winner.getBlock();
  const res = {
    id: args["drawId"].toString(),
    blockNumber: block.number
  };
  return res;
}

export const fetchAllDraws = createAsyncThunk(`${sliceName}/fetchAll`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch draws, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  const draws = await game.allDraws();
  const allDraws = draws.map(d => convertDraw(d)).sort((a,b) => parseInt(b.id) - parseInt(a.id));

  if (allDraws.length === 0) {
    return allDraws;
  }

  if (allDraws[0].playedAt === "0" &&
      allDraws[0].randomnessRequestId !== "0" &&
      allDraws[0].randomnessRequestId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
    // This is the current draw and the numbers won't be available until
    // the pot is distributed, but we want to show them so we can call
    // the method that returns just the current draw numbers
    allDraws[0].numbers = await game.currentDrawNumbers();
  }
  return allDraws;
});

export const fetchWinningDraws = createAsyncThunk(`${sliceName}/fetchWinningDraws`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch winning draws, contracts is undefined")
  }
  const game = await contracts.getGameFacet("GameFacet");
  const filter = game.filters.WinnerDeclared();
  const res = await game.queryFilter(filter);
  const winners = res.filter(r => r.event === "WinnerDeclared");
  const winningDraws = await Promise.all(winners.map(async(w) => await convertWinningDraw(w)));
  const uniqueDraws = uniqWith(winningDraws, (a, b) => a.id === b.id);
  return uniqueDraws.sort((a,b) => parseInt(b.id) - parseInt(a.id));
});

export const fetchCurrentDraw = createAsyncThunk(`${sliceName}/fetchCurrent`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch draw, contracts is undefined")
  }
  const game = await contracts.getGameFacet("GameFacet");
  const draw = await game.currentDraw();
  return convertDraw(draw);
});

export const getCurrentDraw = (state) => {
  return state.draws.data.current;
};

export const getAllDraws = (state) => {
  return state.draws.data.all;
};

export const getWinningDraws = (state) => {
  return state.draws.data.winning;
};

export const getDraw = (state, id) => {
  return state.draws.data.all.find(d => d.id === id);
};

export default drawsSlice.reducer;