import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "stakes";

const initialState = {
  data: {
    account : [],
    total : "0"
  },
  status: "idle",
  error: null
};

const stakesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchAccountStakes.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAccountStakes.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.account = action.payload;
    })
    .addCase(fetchAccountStakes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchTotalStakeAmount.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchTotalStakeAmount.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.total = action.payload;
    })
    .addCase(fetchTotalStakeAmount.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
  }
});

const convertStake = (stake) => {
  return {
    index: stake.index.toString(),
    reward: stake.reward.toString(),
    claim: {
      amount: stake.claim.amount.toString(),
      effectiveDrawId : stake.claim.effectiveDrawId.toString()
    }
  }
}

export const fetchAccountStakes = createAsyncThunk(`${sliceName}/fetchAccount`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch account states: params is undefined")
  }
  const {
    contracts,
    account,
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch stakes, contracts is undefined")
  }
  if (!account) {
    throw new Error("Can't fetch stakes, account is undefined")
  }

  const staking = await contracts.getGameFacet("StakingFacet");
  const stakes = await staking.indexedStakeClaims(account);
  const withRewards = [];
  for (let i = 0; i < stakes.length; ++i) {
    const s = stakes[i];
    const reward = await staking.claimableRewards(s.index, account);
    withRewards.push({...s, reward});
  }
  return withRewards.map(s => convertStake(s)).sort((a, b) => b.claim.effectiveDrawId > a.claim.effectiveDrawId ? 1 : -1);
});

export const fetchTotalStakeAmount = createAsyncThunk(`${sliceName}/fetchTotalAmount`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch stake amount: params is undefined")
  }
  const {
    contracts,
  } = params;

  if (!contracts) {
    throw new Error("Can't fetch stake amount, contracts is undefined")
  }

  const game = await contracts.getGameFacet("GameFacet");
  const staking = await contracts.getGameFacet("StakingFacet");
  const draw = await game.currentDraw();
  const sp = await staking.stakePool(parseInt(draw.id) + 1);
  return sp.amount.toString();
});

export const getAccountStakes = (state) => {
  return state.stakes.data.account;
};

export const getTotalStakeAmount = (state) => {
  return state.stakes.data.total;
};


export default stakesSlice.reducer;