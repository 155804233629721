import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography } from "@mui/material";
import { getAgreementUri } from "../store/slices/user-slice";
import { useSelector } from "react-redux";

const UserAgreementPrompt = (props) => {
  const {
    open,
    setOpen,
  } = props;

  const { t } = useTranslation("translation", { keyPrefix: "account.agreement" });
  const theme = useTheme();
  const agreementUri = useSelector(state => getAgreementUri(state));
  const close = (accepted) => {
    setOpen({accepted});
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
        }
      }}
      >
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          <Typography>{t("body")}</Typography>
          <Link href={agreementUri} target="_blank">{t("agreementLink")}</Link>
          <Typography>{t("agree")}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>{t("cancel")}</Button>
        <Button onClick={() => close(true)}>{t("sign")}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default UserAgreementPrompt;