import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "pot";

const initialState = {
  data: {
    current: "0",
    next: "0"
  },
  status: "idle",
  error: null
};

const potSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchPot.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchPot.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = action.payload;
    })
    .addCase(fetchPot.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchPot = createAsyncThunk(`${sliceName}/fetch`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch pot, contracts is undefined")
  }
  const game = await contracts.getGameFacet("GameFacet");
  const current = await game.currentPot();
  const next = await game.nextPot();
  return {
    current: current.toString(),
    next: next.toString()
  };
});

export const getPot = (state, which = "current") => {
  if (which === "current") {
    return state.pot.data.current;
  } else if (which === "next") {
    return state.pot.data.next;
  } else {
    return null;
  }
};

export default potSlice.reducer;