import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { useState, useEffect, useContext } from "react";
import { ContractsStateContext } from "../context/contracts";
import { WalletStateContext } from "../context/wallet";
import { getAccountBalance, fetchAccountBalance } from "../store/slices/account-balances-slice";
import { getToken } from "../store/slices/tokens-slice";
import { fetchPot, getPot } from '../store/slices/pot-slice';
import { getRewardsConfigurations, fetchRewardsConfigurations } from "../store/slices/game-configurations-slice";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Paper, Slider, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import ConversionUtils from "../utils/conversions";
import TransactionModal from "../components/TransactionModal";

const {
  toBigNumber,
  formatUnits,
  parseUnits,
  keccak256Hash,
  encodeParameters
} = ConversionUtils;

const PotClaim = () => {
  const { t } = useTranslation("translation", { keyPrefix: "potClaim" });
  const theme = useTheme();
  const navigate = useNavigate();

  const [claimLimit, setClaimLimit] = useState("0");
  const [claimAmount, setClaimAmount] = useState("1");
  const [estimatedPayout, setEstimatedPayout] = useState("1");
  const [sliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(100);
  const [sliderValue, setSliderValue] = useState(1);
  const [txArgs, setTxArgs] = useState(null);
  const [txDiagOpen, setTxDiagOpen] = useState({claiming: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({claiming: false});
  const wallet = useContext(WalletStateContext);
  const contracts = useContext(ContractsStateContext);
  const gameToken = useSelector(state => getToken(state, "game"));
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const chanceyBalance = useSelector(state => getAccountBalance(state, "chancey"));
  const rewardsConfigs = useSelector(state => getRewardsConfigurations(state));
  const dispatch = useDispatch();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));

  const currentPot = useSelector(state => getPot(state, "current"));
  const nextPot = useSelector(state => getPot(state, "next"));

  useEffect(() => {
    if (contracts) {
      dispatch(fetchPot(contracts));
      dispatch(fetchRewardsConfigurations(contracts));
    }
  }, [contracts]);

  useEffect(() => {
    setSliderMax(parseInt(formatUnits(chanceyBalance, chanceyToken.decimals).toString()));
  }, [chanceyBalance]);

  useEffect(() => {
    const fetchClaimParams = async() => {
      const totalPot = toBigNumber(currentPot).add(toBigNumber(nextPot));
      const potLiftTarget = toBigNumber(rewardsConfigs.potLiftTarget);

      if (totalPot.gte(potLiftTarget)) {
        const coolOffRate = toBigNumber(rewardsConfigs.potBurnCoolRate);
        const maxLimit = totalPot.div(coolOffRate);
        setClaimLimit(formatUnits(maxLimit, gameToken.decimals, 0).toString());

        const s = parseUnits(claimAmount, chanceyToken.decimals);
        const payout = (totalPot.mul(s)).div(coolOffRate.mul(chanceyToken.totalSupply));
        setEstimatedPayout(payout.toString());

      } else {
        setClaimLimit("0");
      }
    }
    fetchClaimParams();
  }, [currentPot, nextPot, rewardsConfigs, chanceyToken, chanceyBalance]);

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const claimTransactions = [{
    pendingLabel: t("trClaimStep1Pending"),
    completedLabel: t("trClaimStep1Completed"),
    failedLabel: t("trClaimStep1Failed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trClaimStep2Pending"),
    completedLabel: t("trClaimStep2Completed"),
    failedLabel: t("trClaimStep2Failed"),
    tx: async(stake) => {
      // Claiming
      try {
        const action = keccak256Hash("CLAIM_POT_PORTION");
        const functionCall = "burnAndCall(uint256,address,bytes)";
        const game = await contracts.getGameFacet("GameFacet");
        const erc1363 = await contracts.getChanceyFacet("ChanceyERC1363ExtendedFacet");
        const amount = parseUnits(claimAmount, chanceyToken.decimals);
        const data = encodeParameters(["bytes32"], [action]);
        const res = await erc1363[functionCall](amount, game.address, data);
        await res.wait();
        dispatch(fetchPot(contracts));
        readNewBalances();
        return 0;
      } catch (e) {
        console.error("Failed claim transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const startTransactions = async(tx, args) => {
    setTxArgs(args);
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const readNewBalances = async () => {
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "game",
      tokenAddress: gameToken.address,
      account: wallet.account
    }));
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "chancey",
      tokenAddress: chanceyToken.address,
      account: wallet.account
    }));
  }

  const updateClaimAmount = (a) => {
    if (!a) {
      setClaimAmount("");
      setSliderValue(0);
      setEstimatedPayout("0");
    } else if (!isNaN(a)){
      const s = parseUnits(a, chanceyToken.decimals);
      const b = toBigNumber(chanceyBalance);

      if (s.lte(b)) {
        setClaimAmount(a);
        setSliderValue(Number(a));
        const totalPot = toBigNumber(currentPot).add(toBigNumber(nextPot));
        const coolOffRate = toBigNumber(rewardsConfigs.potBurnCoolRate);
        const payout = (totalPot.mul(s)).div(coolOffRate.mul(chanceyToken.totalSupply));
        setEstimatedPayout(payout.toString());
      }
    }
  }

  const handleSliderChange = (_, newValue) => {
    updateClaimAmount(String(newValue));
  };

  let subheaderTitle = "subheader";
  let subheaderArgs = {symbol: chanceyToken.symbol, amount: claimLimit, currency: gameToken.symbol};

  if (claimLimit === "0") {
    subheaderTitle = "subheader-n/a";
    subheaderArgs = {
      symbol: gameToken.symbol,
      value: formatUnits(rewardsConfigs.potLiftTarget, gameToken.decimals, 0)
    }
  }

  return (
    <Box sx={{display:"inline-block"}}>
      <Stack alignItems="center" direction="column">
        <Stack spacing={2}>
          <Typography variant="h3">
            {t("header")}
          </Typography>
          <Typography component={'span'} sx={{ ...theme.typography.subtitle }} color={theme.palette.tertiary.main}>
            {t(subheaderTitle, subheaderArgs)}
          </Typography>
        </Stack>
        {claimLimit !== "0" && <Box sx={{width: "100%", paddingTop: 4}}>
          <Paper>
            {chanceyBalance < "1" && <Box sx={{p: 2}}>
              <Stack spacing={2} alignItems="stretch" direction="column">
                <Box sx={{width: "100%", p: 2}}>
                  <Typography>
                    {t("insufficientBalance", {currency: chanceyToken.symbol})}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="outlined" onClick={() => navigate('/tickets')}>{t("buy")}</Button>
                </Box>
              </Stack>
            </Box>}
            {chanceyBalance >= "1" && <Box sx={{p: 2, width: {sm: "100%", md: 650}}}>
              <Stack direction={smallScreen ? "column" : "row"} spacing={2} alignItems="center" justifyContent="center">
                <Stack>
                  <Typography variant="h6">
                    {t("enterAmount")}
                  </Typography>
                  {!smallScreen && <Typography sx={{ ...theme.typography.accountBalance }}>
                    {t("availableBalance", {b: formatUnits(chanceyBalance, chanceyToken.decimals, 0), currency: chanceyToken.symbol})}
                  </Typography>}
                </Stack>
                <TextField
                  color="tertiary"
                  size="small"
                  focused
                  value={claimAmount}
                  onChange={(e) => updateClaimAmount(e.target.value)}
                >
                </TextField>
                {smallScreen && <Typography sx={{ ...theme.typography.accountBalance }}>
                    {t("availableBalance", {b: formatUnits(chanceyBalance, chanceyToken.decimals, 0), currency: chanceyToken.symbol})}
                </Typography>}
              </Stack>
              <Box sx={{p:2}}>
                <Slider
                  size="small"
                  valueLabelDisplay="auto"
                  min={sliderMin}
                  max={sliderMax}
                  value={sliderValue}
                  onChange={handleSliderChange}
                >
                </Slider>
                <Typography sx={{ ...theme.typography.accountBalance, color: theme.palette.primary.main }}>
                  {t("estimatedPayout", {amount: formatUnits(estimatedPayout, gameToken.decimals, 4), symbol: gameToken.symbol})}
                </Typography>
                <Box sx={{p: 2}}>
                  <Typography sx={{ ...theme.typography.accountBalance }}>
                    {t("maxPayout", {symbol: chanceyToken.symbol})}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  disabled={!claimAmount || claimAmount === "0"}
                  onClick={() => startTransactions("claiming")}
                >
                  {t("redeem")}
                </Button>
              </Box>
            </Box>}
          </Paper>
          <TransactionModal
            open={txDiagOpen["claiming"]}
            onClose={() => closeTxDiag("claiming")}
            steps={claimTransactions}
            onFinished={() => onTxDiagFinished("claiming")}
            args={txArgs}
          >
          </TransactionModal>
        </Box>}
        {claimLimit === "0" && <Box sx={{p: 4}}>
          <Button variant="outlined" onClick={() => navigate('/tickets')}>{t("buy")}</Button>
        </Box>}
      </Stack>
    </Box>
  );
};

export default PotClaim;