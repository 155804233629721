import { ethers } from "ethers";
import moment from "moment";

const ZERO_BIG_NUMBER = ethers.BigNumber.from(0);
const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
const EMPTY_BYTES_32 = "0x0000000000000000000000000000000000000000000000000000000000000000";

const formatUnits = (amount, decimals, precision = 4) => {
  const res = ethers.utils.formatUnits(amount, decimals);
  if (res.includes(".")) {
    const beforeDecimal = res.split(".")[0];
    const afterDecimal = res.split(".")[1];
    let value = `${beforeDecimal}.${afterDecimal.substring(0, precision)}`;
    if (precision === 0) {
      value = value.replace(".", "");
    }
    return value;
  } else {
    return res;
  }
}

const parseUnits = (amount, decimals) => {
  return ethers.utils.parseUnits(amount, decimals);
}

const toBigNumber = (v) => {
  return ethers.BigNumber.from(v);
}

const ticketLineFromHex = (hex) => {
  if (!hex || hex === "0x") {
    return "";
  }
  return hex.replace("0x", "")
    .match(/.{1,2}/g)
    .map(i => parseInt(i, 16)
    .toString()
    .padStart(2, "0"))
    .join(",");
}

const dateFromTimestamp = (t) => {
  return (t && t !== "0") ? moment(new Date(parseInt(t)) * 1000).format("llll") : "";
}

const extractAbiParameterType = (i) => {
  let type = i.type;
  if (type.includes("tuple")) {
    type = `(${i.components.map(c => c.type).join(", ")})`;
    if (i.type.includes("[]")) {
      type = `${type}[]`;
    }
  }
  return type;
}

const extractAbiParameters = (inputs) => {
  return inputs.map(i => {
    let type = i.type;
    let name = i.name;
    if (i.type.includes("tuple")) {
      type = `(${i.components.map(c => c.type).join(", ")})`;
      if (i.type.includes("[]")) {
        type = `${type}[]`;
      }
    }
    return [`${type} ${name}`];
  }).join(", ");
}

const addABISelectorSignature = (fs) => {
  const {name, inputs} = fs;
  const params = extractAbiParameters(inputs)
  const f = `${name}(${params})`;
  const abi = new ethers.utils.Interface([`function ${f}`]);
  const signature = abi.getSighash(f);
  return {signature, ...fs}
}

const decodeCalldata = (fs, calldata) => {
  const {name, inputs} = fs;
  const params = extractAbiParameters(inputs)
  const f = `${name}(${params})`;
  const abi = new ethers.utils.Interface([`function ${f}`]);
  return abi.decodeFunctionData(f, calldata);
}

const abiFromSelector = (fs) => {
  const {name, inputs} = fs;
  const params = extractAbiParameters(inputs)
  const f = `${name}(${params})`;
  return new ethers.utils.Interface([`function ${f}`]);
}

const keccak256Hash = (v) => {
  return ethers.utils.keccak256(ethers.utils.toUtf8Bytes(v));
}

const solidityKeccak256 = (types, values) => {
  return ethers.utils.solidityKeccak256(types, values);
}

const arrayify = (v) => {
  return ethers.utils.arrayify(v);
}

const encodeParameters = (types, values) => {
  return ethers.utils.defaultAbiCoder.encode(types, values);
}

const toWallet = (privateKey) => {
  return new ethers.Wallet(privateKey);
}

const toBytes32 = (v) => {
  return ethers.utils.hexZeroPad(
    ethers.utils.hexlify(
      toBigNumber(v)
    ),
    32
  );
}

const ConversionUtils = {
  toBigNumber,
  formatUnits,
  parseUnits,
  ticketLineFromHex,
  ZERO_BIG_NUMBER,
  ZERO_ADDRESS,
  EMPTY_BYTES_32,
  dateFromTimestamp,
  decodeCalldata,
  addABISelectorSignature,
  extractAbiParameters,
  extractAbiParameterType,
  abiFromSelector,
  keccak256Hash,
  solidityKeccak256,
  encodeParameters,
  arrayify,
  toWallet,
  toBytes32
};

export default ConversionUtils;