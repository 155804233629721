import React from 'react'
import { styled } from "@mui/material/styles";

const OutterDiv = styled('div')(({ theme }) => ({
  borderRadius: "1em",
  padding: "0.2em",
  [theme.breakpoints.up("sm")]: {
    display: "inline-block",
  },
  boxShadow: `0px 0px 3px 3px ${theme.palette.info.main}, 0px 0px 6px 6px ${theme.palette.primary.main}, 0px 0px 12px 12px ${theme.palette.secondary.main}`
}));

const InnerDiv = styled('div')(({ theme }) => ({
  borderRadius: "1em",
  background: theme.palette.background.default
}));

const GradientBorderDiv = (props) => {

  return (
    <OutterDiv>
      <InnerDiv>
        {props.children}
      </InnerDiv>
    </OutterDiv>
  )
}
export default GradientBorderDiv