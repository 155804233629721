import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chaindescriptors from "../../utils/chaindescriptors";
import Utilities from "../../utils/utilities";

const { ZERO_ADDRESS } = Utilities;
/**
 * These slices are used for keeping
 * state of tokens used in the project
 */

const sliceName = "tokens";

const icons = {
  chancey: "chancey.svg",
  polygon: "polygon.svg",
  ethereum: "ethereum.svg",
  link: "link.svg",
};

const initialState = {
  data: {
    chancey: {name: "", symbol: "", decimals: "4", icon: icons["chancey"]},
    game: {name: "", symbol: "", decimals: "18", icon: icons["ethereum"]},
    link: {name: "", symbol: "", decimals: "18", icon: icons["link"]}
  },
  status: "idle",
  error: null
};

const tokensSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchTokens.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchTokens.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = action.payload;
    })
    .addCase(fetchTokens.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchTokens = createAsyncThunk(`${sliceName}/fetchAll`, async(contracts) => {
  if (contracts) {
    const data = {};
    let token = contracts.getContract("Chancey");
    let erc20Meta = contracts.IERC20FromAddress(token.address);
    data.chancey = {
      symbol: await erc20Meta.symbol(),
      decimals: (await erc20Meta.decimals()).toString(),
      totalSupply: (await erc20Meta.totalSupply()).toString(),
      address: token.address,
      icon: icons["chancey"]
    };

    const game = await contracts.getGameFacet("GameFacet");
    let address = await game.gameTokenAddress();
    if (address !== ZERO_ADDRESS) {
      // Game configured with ERC20 token for play
      erc20Meta = contracts.IERC20FromAddress(address);
      data.game = {
        symbol: await erc20Meta.symbol(),
        decimals: (await erc20Meta.decimals()).toString(),
        address,
        icon: icons["game"]
      };
    } else {
      // Game configured with native currency for play
      const chain = chaindescriptors.find(c => c.chainId === contracts.getChainId());
      data.game = {
        symbol: chain.nativeCurrency.symbol,
        decimals: chain.nativeCurrency.decimals,
        address: ZERO_ADDRESS,
        icon: icons[chain.icon],
        isNative: true
      }
    }
    // Link token isn't properly configured when running in
    // with API3 QRNG, so don't bother grabbing details
    // address = await game.linkTokenAddress();
    // erc20Meta = contracts.IERC20FromAddress(address);
    // data.link = {
    //   symbol: await erc20Meta.symbol(),
    //   decimals: (await erc20Meta.decimals()).toString(),
    //   address,
    //   icon: icons["link"]
    // };
    return data;
  } else {
    throw new Error("Can't fetch tokens: contracts not initialized");
  }
});

// TODO add getters to avoid formatting in the code
export const getToken = (state, t) => {
  return state.tokens.data[t];
};

export default tokensSlice.reducer;