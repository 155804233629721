import { Box, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useState, useEffect, useMemo, createRef } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

function preFilledArray(original, size) {
  if (!original || !Array.isArray(original)) {
    return new Array(size).fill("");
  }
  if (original.length >= size) {
    return [...original];
  } else {
    let tmp = [...original];
    const diff = size - tmp.length;
    for(let i = 0; i < diff; ++i) {
      tmp.push("");
    }
    return tmp;
  }
}

const NumberInput = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "tickets" });
  const { size, min, max, length, choice, handleChange } = props;
  const theme = useTheme();
  const inputRefs = useMemo(() => Array(size).fill(0).map(_ => createRef()), []);
  const [numbers, setNumbers] = useState(new Array(size).fill(""));
  const [currentBox, setCurrentBox] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const mediumScreen = !useMediaQuery(theme.breakpoints.up('md'));
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));
  const columnsCount = mediumScreen ? 6 : 6;
  const columns = Array(columnsCount).fill(1);
  const rows = Array(Math.ceil(size / columnsCount)).fill(1);
  const fontSize = smallScreen ? "1.75em" : "4em";
  useEffect(() => {
    if (currentBox !== null && currentBox < size) {
      inputRefs[currentBox].current.focus();
    }
  }, [currentBox]);

  useEffect(() => {
    setNumbers(preFilledArray(choice, size));
  }, [choice])

  useEffect(() => {
    const pick = numbers.filter(i => i && !isNaN(i));
    const set = Array.from(new Set(pick.map(i => parseInt(i))));
    const dupes = set.length !== pick.length
    setErrorMessage(pick.length === size && dupes ? t("numbersNotUnique") : "");
    handleChange(set);
  }, [numbers]);

  const updateTextFieldValue = (index, value) => {
    const pattern = /[^0-9]/;
    if (!value.match(pattern)) {
      if (value) {
        if (value.length > length) {
          return;
        }

        const v = parseInt(value);
        if (v < min || v > max) {
          return;
        }
      }

      const arr = [...numbers];
      arr[index] = value;
      setNumbers(arr);

      if (value.length === length && index < size) {
        setCurrentBox(index + 1);
      }
    }
  }

  const onFocusLeft = (index) => {
    const arr = [...numbers];
    const value = arr[index];
    if (value && value.length < length) {
      arr[index] = String(value).padStart(2, "0");
      setNumbers(arr);
    }
  }

  const onBoxKeyPressed = (index, e) => {
    // Backspace handling
    if (index !== 0 && !numbers[index] && e.keyCode === 8) {
      setCurrentBox(index - 1);
    }
  }

  return (
    <Stack spacing={1}>
      <Stack spacing={1}>
        {rows.map((_, r) => {
          return <Stack key={r} direction="row" alignItems="center" justifyContent="center" spacing={1}>
            {columns.map((_, c) => {
              let i = (r * columns.length) + c;
              if (i < size) {
                return <Box key={i}>
                  <TextField
                    id={`${i}`}
                    value={numbers[i]}
                    inputRef={inputRefs[i]}
                    inputProps={{ autoComplete: "off", style: { fontSize: fontSize, width: "3ch", padding: 1, textAlign: 'center' }}}
                    color="tertiary"
                    focused
                    onChange={(e) => updateTextFieldValue(i, e.target.value)}
                    onKeyDown={(e) => onBoxKeyPressed(i, e)}
                    onBlur={() => onFocusLeft(i)}
                  >
                  </TextField>
                </Box>
              } else {
                return <></>
              }
            })}
          </Stack>
        })}
      </Stack>
      <Typography sx={{ ...theme.typography.errorHint }}>{errorMessage}</Typography>
    </Stack>
  )
}
export default NumberInput