import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletStateContext } from "../context/wallet";
import { ContractsStateContext } from "../context/contracts";
import { addPurchasedTicket } from '../store/slices/tickets-slice';
import { getCurrentDraw } from "../store/slices/draws-slice";
import { getToken } from "../store/slices/tokens-slice";
import { getAllowance, fetchAllowance } from "../store/slices/allowances-slice";
import { fetchAccountBalance, getAccountBalance } from "../store/slices/account-balances-slice";
import { getCartItems, itemAdded, itemsAdded, itemRemoved, itemsCleared } from "../store/slices/cart-slice";
import { getCurrentBuyReward, currentBuyRewardUpdated } from "../store/slices/current-buy-rewards-slice";
import { getCurrentTicketPrice, currentTicketPriceUpdated } from "../store/slices/current-ticket-price-slice";
import { hasUserAgreedToTC, fetchCurrentUserAgreement } from "../store/slices/user-slice";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Slider, Snackbar, Stack, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useApolloClient, gql } from '@apollo/client';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ConversionUtils from "../utils/conversions";
import Utilities from "../utils/utilities";
import TransactionModal from "../components/TransactionModal";
import TicketsHistory from "../components/TicketsHistory";
import NumberInput from "../components/NumberInput";
import UserAgreementPrompt from "../components/UserAgreementPrompt";
import { TABLE_SIZE, TICKETS_QUERY } from "../utils/apollo-queries";
const {
  formatUnits,
  parseUnits,
  toBigNumber,
} = ConversionUtils;

const {
  MIN_NUMBER,
  MAX_NUMBER,
  NUMBERS_COUNT,
  generateRandomNumbers,
  numbersToTicketLine
} = Utilities;

const QuickPickDialog = (props) => {
  const {
    open,
    setOpen,
    maxTickets
  } = props;

  const { t } = useTranslation("translation", { keyPrefix: "tickets" });
  const theme = useTheme();
  const [sliderMin] = useState(1);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedCount, setSelectedCount] = useState(1);

  const close = (accepted) => {
    setOpen({accepted, selectedCount});
  };

  useEffect(() => {
    setSliderValue(1);
    setSelectedCount(1);
  }, [maxTickets]);

  const handleSliderChange = (_, newValue) => {
    setSliderValue(newValue);
    updateSelectedCount(newValue);
  };

  const updateSelectedCount = (c) => {
    if (!c) {
      setSelectedCount(0);
      setSliderValue(0);
    } else if (!isNaN(c)){
      const v = Number(c);
      if (v <= maxTickets && v >= 0) {
        setSelectedCount(v);
        setSliderValue(v);
      }
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
        }
      }}
      >
      <DialogTitle>{t("quickPick")}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} alignItems="center">
          <Typography>{t("quickPickDescription")}</Typography>
          <TextField
            color="tertiary"
            size="small"
            focused
            value={selectedCount}
            onChange={(e) => updateSelectedCount(e.target.value)}
          >
          </TextField>
          <Typography sx={{ ...theme.typography.accountBalance }}>{t("quickPickMax", {max: maxTickets})}</Typography>
          <Slider
            size="small"
            valueLabelDisplay="auto"
            min={sliderMin}
            max={maxTickets}
            value={sliderValue}
            onChange={handleSliderChange}
          >
          </Slider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>{t("cancel")}</Button>
        <Button disabled={selectedCount < 1} onClick={() => close(true)}>{t("pick")}</Button>
      </DialogActions>
    </Dialog>
  )
}

const Tickets = () => {
  const { t } = useTranslation("translation", { keyPrefix: "tickets" });
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const [randomLine, setRandomLine] = useState();
  const [ticketNumbers, setTicketNumbers] = useState();
  const [dupeDetected, setDupeDetected] = useState(false);
  const [addToCartDisabled, setAddToCartDisabled] = useState(true);
  const [hasEnoughBalance, setHasEnoughBalance] = useState(false);
  const [requiredAllowance, setRequiredAllowance] = useState("");
  const [cartCost, setCartCost] = useState("0");
  const [txDiagOpen, setTxDiagOpen] = useState({buy: false, userAgreement: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({buy: false, userAgreement: false});
  const [quickPickDialogOpen, setQuickPickDialogOpen] = useState(false);
  const [userAgreementDialogOpen, setUserAgreementDialogOpen] = useState(false);
  const maxTicketsPerCart = 100;
  const wallet = useContext(WalletStateContext);
  const contracts = useContext(ContractsStateContext);
  const draw = useSelector(state => getCurrentDraw(state));
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const gameToken = useSelector(state => getToken(state, "game"));
  const gameTokenAllowance = useSelector(state => getAllowance(state, "game"));
  const gameTokenBalance = useSelector(state => getAccountBalance(state, "game"));
  const cartItems = useSelector(state => getCartItems(state));
  const currentBuyReward = useSelector(state => getCurrentBuyReward(state));
  const currentTicketPrice = useSelector(state => getCurrentTicketPrice(state));
  const userAgreement = useSelector(state => hasUserAgreedToTC(state));
  const dispatch = useDispatch();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const fetchSaleInfo = async() => {
      const ticketBooth = await contracts.getGameFacet("TicketBoothFacet");

      const price = await ticketBooth.currentTicketPrice();
      dispatch(currentTicketPriceUpdated(price.toString()));

      const reward = await ticketBooth.currentRewardPerTicket();
      dispatch(currentBuyRewardUpdated(reward.toString()));
    }
    if (contracts) {
      fetchSaleInfo();
    }
  }, [contracts, chanceyToken]);

  useEffect(() => {
    if (cartItems && gameTokenAllowance && draw) {
      const cost = toBigNumber(cartItems.length).mul(toBigNumber(currentTicketPrice));
      setRequiredAllowance(formatUnits(cost, gameToken.decimals).toString());
    }
  }, [cartItems, draw, gameTokenAllowance]);

  useEffect(() => {
    const cost = toBigNumber(cartItems.length).mul(toBigNumber(currentTicketPrice));
    const formatted = formatUnits(cost, gameToken.decimals).toString();
    setCartCost(formatted);
    setRequiredAllowance(formatted);
  }, [cartItems])

  useEffect(() => {
    if (gameTokenBalance && cartItems) {
      const cost = parseUnits(cartCost, gameToken.decimals);
      const balance = parseUnits(gameTokenBalance || "0", gameToken.decimals);
      setHasEnoughBalance(balance.gte(cost));
    }
  }, [gameTokenBalance, cartCost]);

  useEffect(() => {
    if (ticketNumbers) {
      const set = Array.from(new Set(ticketNumbers));
      setAddToCartDisabled(set.length !== NUMBERS_COUNT);
    }
  }, [ticketNumbers])

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const startTransactions = async(tx) => {
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const updateTicketLine = (numbers) => {
    setTicketNumbers(numbers);
  }

  const addTicketLineToCart = () => {
    const line = numbersToTicketLine(ticketNumbers);
    if (cartItems.includes(line)) {
      setDupeDetected(true);
    } else {
      dispatch(itemAdded(numbersToTicketLine(ticketNumbers)));
      setRandomLine(!randomLine);
    }
  }

  const generateRandomTicket = () => {
    let line;
    do {
      line = numbersToTicketLine(generateRandomNumbers());
    } while (cartItems.includes(line));
    setRandomLine(line.split(","))
  }

  const fillCartWithRandomTickets = (count) => {
    const lines = [];
    for(let i = 0; i < count; ++ i) {
      let line;
      do {
        line = numbersToTicketLine(generateRandomNumbers());
      } while (cartItems.includes(line) || lines.includes(line));
      lines.push(line);
    }
    dispatch(itemsAdded(lines));
  }

  const removeFromCart = (index) => {
    dispatch(itemRemoved(index));
  }

  const readNewAllowance = async () => {
    if (contracts && wallet) {
      const game = await contracts.getGameFacet("GameFacet");
      dispatch(fetchAllowance({
        contracts,
        tokenName: "game",
        tokenAddress: gameToken.address,
        address: game.address,
        account: wallet.account
      }));
    } else {
      console.error("Can't read new allowance without contracts and wallet");
    }
  }

  const readNewBalances = async () => {
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "game",
      tokenAddress: gameToken.address,
      account: wallet.account
    }));
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "chancey",
      tokenAddress: chanceyToken.address,
      account: wallet.account
    }));
  }

  const changeAllowanceDiff = (v) => {
    if (!v) {
      setRequiredAllowance(cartCost);
    } else if (!isNaN(v)) {
      setRequiredAllowance(v);
    }
  }

  const buyTransactionsERC20 = [{
    pendingLabel: t("trStep1Pending"),
    completedLabel: t("trStep1Completed"),
    failedLabel: t("trStep1Failed"),
    tx: async () => {
      // A fake transaction for starting MM
      return 0;
    }
  },{
    pendingLabel: t("trStep2Pending"),
    completedLabel: t("trStep2Completed"),
    failedLabel: t("trStep2Failed"),
    tx: async () => {
      // approve spending
      try {
        const ticketBooth = await contracts.getGameFacet("TicketBoothFacet");
        const token = contracts.IERC20FromAddress(gameToken.address);
        const requiredAmount = parseUnits(requiredAllowance || "0", gameToken.decimals);
        const res = await token.approve(ticketBooth.address, requiredAmount);
        const receipt = await res.wait();
        readNewAllowance();
        changeAllowanceDiff("0");
        const {events, transactionHash} = receipt;
        console.log("Transaction hash", transactionHash);
        const [e] = events;
        if (e) {
          const [from, to, amount] = e.args;
          console.log("Approved owner", from);
          console.log("Approved spender", to);
          console.log("Approved amount", amount.toString());
          if (amount.lt(requiredAmount)) {
            console.error(`Not enough approved. Required: ${requiredAmount.toString()}, Approved: ${amount.toString()}`);
            return 1;
          }
        }
        return 0;
      } catch (e) {
        console.log("Failed allow transaction");
        if (e.code === 4001) {
          console.log("User rejected transaction");
        } else {
          console.log(e);
        }
        return e;
      }
    }
  },{
    pendingLabel: t("trStep3Pending"),
    completedLabel: t("trStep3Completed"),
    failedLabel: t("trStep3Failed"),
    tx: async () => {
      try {
        const ticketBooth = await contracts.getGameFacet("TicketBoothFacet");
        const tickets = cartItems.map(t => `0x${t.split(",").map(t => parseInt(t).toString(16).padStart(2, "0")).join("")}`);
        const res = await ticketBooth.buy(tickets, "0x", 0);
        const receipt = await res.wait();
        const { events } = receipt;
        dispatch(addPurchasedTicket({
          events,
          apollo: {
            client: apolloClient,
            query: TICKETS_QUERY,
            variables: {
              account: wallet.account,
              drawId: draw.id,
              first: TABLE_SIZE,
              skip: 0
            }
          }
        }));
        dispatch(itemsCleared());
        readNewBalances();
        readNewAllowance();
        return 0;
      } catch (e) {
        console.log("Failed allow transaction");
        if (e.code === 4001) {
          console.log("User rejected transaction");
        } else {
          console.log(e);
        }
        return e;
      }
    }
  }];

  const buyTransactionsNative = [{
    pendingLabel: t("trStep1Pending"),
    completedLabel: t("trStep1Completed"),
    failedLabel: t("trStep1Failed"),
    tx: async () => {
      // A fake transaction for starting MM
      return 0;
    }
  },{
    pendingLabel: t("trStep3Pending"),
    completedLabel: t("trStep3Completed"),
    failedLabel: t("trStep3Failed"),
    tx: async () => {
      try {
        const ticketBooth = await contracts.getGameFacet("TicketBoothFacet");
        const tickets = cartItems.map(t => `0x${t.split(",").map(t => parseInt(t).toString(16).padStart(2, "0")).join("")}`);
        const cost = toBigNumber(currentTicketPrice).mul(tickets.length).toString();
        const res = await ticketBooth.buy(tickets, "0x", 0, {value: cost});
        const receipt = await res.wait();
        const { events } = receipt;
        console.log({events});
        dispatch(addPurchasedTicket({
          events,
          apollo: {
            client: apolloClient,
            query: TICKETS_QUERY,
            variables: {
              account: wallet.account,
              drawId: draw.id,
              first: TABLE_SIZE,
              skip: 0
            }
          }
        }));
        dispatch(itemsCleared());
        readNewBalances();
        readNewAllowance();
        return 0;
      } catch (e) {
        console.log("Failed allow transaction");
        if (e.code === 4001) {
          console.log("User rejected transaction");
        } else {
          console.log(e);
        }
        return e;
      }
    }
  }];

  // TODO - this transaction must be repeated
  // everytime the user plays the game and agreement
  // wasn't obtained.
  // However, since the first entry point for most users
  // is buying tickets, it's sufficient for most of the use
  // cases, especially early in the game.
  // Later, this needs to be checked when staking and burning
  const signUserAgreementTransactions = [{
    pendingLabel: t("trStep1Pending"),
    completedLabel: t("trStep1Completed"),
    failedLabel: t("trStep1Failed"),
    tx: async () => {
      // A fake transaction for starting MM
      return 0;
    }
  },{
    pendingLabel: t("trSavingAgreementPending"),
    completedLabel: t("trSavingreementCompleted"),
    failedLabel: t("trSavingAgreementFailed"),
    tx: async () => {
      try {
        const agreements = await contracts.getGameFacet("UserAgreementsFacet");
        const res = await agreements.agree();
        await res.wait();
        dispatch(fetchCurrentUserAgreement({
          contracts,
          account: wallet.account
        }));
        return 0;
      } catch (e) {
        console.log("Failed allow transaction");
        if (e.code === 4001) {
          console.log("User rejected transaction");
        } else {
          console.log(e);
        }
        return e;
      }
    }
  }];

  const handleQuickPickClose = (args) => {
    const {accepted, selectedCount} = args;
    if (accepted) {
      fillCartWithRandomTickets(selectedCount);
    }
    setQuickPickDialogOpen(false);
  }

  const handleUserAgreement = (args) => {
    const {accepted} = args;
    setUserAgreementDialogOpen(false);
    if (accepted) {
      startTransactions("userAgreement")
    }
  }

  const handleCheckout = () => {
    if (userAgreement) {
      startTransactions("buy");
    } else {
      setUserAgreementDialogOpen(true);
    }
  }

  const layoutWidth = smallScreen ? {} : {width: 700, display:"inline-block"}
  return (
    <div>
      <Snackbar
        open={dupeDetected}
        onClose={() => setDupeDetected(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert variant="filled" severity="error">{t("duplicateError")}</Alert>
      </Snackbar>
      <QuickPickDialog open={quickPickDialogOpen} setOpen={handleQuickPickClose} maxTickets={maxTicketsPerCart - cartItems.length}>
      </QuickPickDialog>
      <UserAgreementPrompt open={userAgreementDialogOpen} setOpen={handleUserAgreement}>
      </UserAgreementPrompt>
      <Box sx={layoutWidth}>
        <Stack alignItems="center" direction="column">
          <Box>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Typography variant="h3">
                {t("headerBuy")}
              </Typography>
              <Stack>
                <Typography component={'span'} sx={{ ...theme.typography.tableHeader }} color={theme.palette.tertiary.main}>
                  {t("ticketPrice", {cost: formatUnits(currentTicketPrice, gameToken.decimals).toString(), currency: gameToken.symbol})}
                </Typography>
              </Stack>
              <Box sx={{paddingBottom: 4}} display="flex" justifyContent="center">
                <Stack direction="row" alignItems="center">
                  <Typography component={'span'} color="primary">
                    {t("ticketReward", {symbol: chanceyToken.symbol, reward: formatUnits(currentBuyReward, chanceyToken.decimals, 0).toString()})}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box sx={{p: 1}}>
              <Box sx={{paddingBottom: 2, paddingTop: 1}}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Tooltip title={t("cartIsFull")} arrow placement="top" disableHoverListener={cartItems.length < maxTicketsPerCart}>
                    <Box>
                      <Button disabled={cartItems.length === maxTicketsPerCart} variant="outlined" onClick={generateRandomTicket}>{t("generateRandom")}</Button>
                    </Box>
                  </Tooltip>
                </Stack>
              </Box>
            <Stack alignItems="stretch" direction="column">
              <NumberInput
                size={NUMBERS_COUNT}
                min={MIN_NUMBER-1}
                max={MAX_NUMBER}
                length={2}
                choice={randomLine}
                handleChange={updateTicketLine}
              >
              </NumberInput>
              <Typography component={'span'} color={theme.palette.tertiary.main}>
                {t("subheader", {count: NUMBERS_COUNT, min: MIN_NUMBER, max: MAX_NUMBER})}
              </Typography>
              <Box sx={{paddingBottom: 2, paddingTop: 1}}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Tooltip title={t("cartIsFull")} arrow placement="top" disableHoverListener={cartItems.length < maxTicketsPerCart}>
                    <Box>
                      <Button
                        disabled={cartItems.length === maxTicketsPerCart}
                        variant="outlined"
                        onClick={() => setQuickPickDialogOpen(true)}
                      >
                        {t("quickPick")}
                      </Button>
                    </Box>
                  </Tooltip>
                  <Tooltip title={t("cartIsFull")} arrow placement="top" disableHoverListener={cartItems.length < maxTicketsPerCart}>
                    <Box>
                      <Button
                        disabled={addToCartDisabled || cartItems.length === maxTicketsPerCart}
                        variant="outlined"
                        onClick={addTicketLineToCart}
                      >{
                        t("addToCart")}
                      </Button>
                    </Box>
                  </Tooltip>
                </Stack>
              </Box>
              <Paper>
                {cartItems.length > 0 && <Box sx={{p:1, width:"100%"}}>
                  <Grid container spacing={1}>
                    {cartItems.map((t, i) => {
                      return (
                        <Grid  key={i} item>
                          <Chip label={t} onDelete={() => removeFromCart(i)}></Chip>
                        </Grid>
                      )})}
                  </Grid>
                </Box>}
                <Box sx={{p: 1}}>
                  <Stack direction={smallScreen ? "column" : "row"} spacing={2} alignItems="st" justifyContent={smallScreen ? "flex-start" : "flex-end"}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                      <ShoppingCartOutlinedIcon color="primary"></ShoppingCartOutlinedIcon>
                      <Box>
                        <Stack>
                          <Typography textAlign="left" sx={{ ...theme.typography.tableCell }}>
                            {t("cartSummary", {count: cartItems.length, cost: cartCost, currency: gameToken.symbol})}
                          </Typography>
                          <Typography textAlign="left" sx={{ ...theme.typography.tableCell }}>
                            {t("cartReward", {currency: chanceyToken.symbol, reward: formatUnits(toBigNumber(currentBuyReward).mul(cartItems.length), chanceyToken.decimals, 0).toString()})}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <Stack directrion="row" alignItems="center">
                      <Box>
                      <Tooltip
                        title={wallet?.account ? (cartItems.length === 0 ? t("cartItemEmpty") : t("notEnoughBalance", {currency: gameToken.symbol})) : t("walletNotConnected")}
                        disableHoverListener={wallet?.account && hasEnoughBalance && cartItems.length !== 0}
                        arrow
                        placement="top"
                      >
                        <Box>
                          <Button
                            fullWidth
                            variant="outlined"
                            disabled={!wallet?.account || !hasEnoughBalance || cartItems.length === 0}
                            onClick={handleCheckout}
                          >
                            {t("checkout")}
                          </Button>
                        </Box>
                      </Tooltip>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <TransactionModal
                  open={txDiagOpen["buy"]}
                  onClose={() => closeTxDiag("buy")}
                  steps={gameToken.isNative ? buyTransactionsNative : buyTransactionsERC20}
                  onFinished={() => onTxDiagFinished("buy")}
                >
                </TransactionModal>
                <TransactionModal
                  open={txDiagOpen["userAgreement"]}
                  onClose={() => closeTxDiag("userAgreement")}
                  steps={signUserAgreementTransactions}
                  onFinished={() => onTxDiagFinished("userAgreement")}
                >
                </TransactionModal>
              </Paper>
              {cartItems.length >= maxTicketsPerCart && <Box sx={{p: 1}}>
                <Typography sx={{ ...theme.typography.errorHint }}>
                  {t("cartIsFull")}
                </Typography>
              </Box>}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <TicketsHistory
        account={wallet?.account}
        drawId={draw?.id}
        ticketsQuery={TICKETS_QUERY}
        tableSize={TABLE_SIZE}
      />
    </div>
  );
}
export default Tickets