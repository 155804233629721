import { Box, ButtonBase, Avatar } from '@mui/material';

const Logo = (props) => {
  const {size = 50} = props;
  return <Box>
    <ButtonBase disableRipple>
      <Avatar src={`/icons/tokens/chancey.svg`} sx={{width: size, height: size}}>
      </Avatar>
    </ButtonBase>
  </Box>
};

export default Logo;
