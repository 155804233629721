import { Box, Button, Collapse, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ContractsStateContext } from "../context/contracts";
import { WalletStateContext } from "../context/wallet";
import { getAllFacets } from "../store/slices/loupes-slice";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import TransactionModal from "../components/TransactionModal";
import LabelWithValue from "../components/LabelWithValue";
import moment from "moment";
import ConversionUtils from '../utils/conversions';
import { getToken } from "../store/slices/tokens-slice";
import { useLazyQuery, gql } from '@apollo/client';
import {
  getGQLProposals,
  storeGQLProposals,
  //fetchAllProposals,
  getDelegateAddress,
  fetchDelegateAddress,
  getDelegateVotingPower,
  fetchDelegateVotingPower,
  getOwnVotingPower,
  fetchOwnVotingPower,
  getCancelRole,
  fetchCancelRole,
} from '../store/slices/governance-slice';

// This page won't show history without
// having a grapql server setup.

const ALL_PROPOSALS_QUERY = gql`
  query GetAllProposals {
    proposalCreateds(
      orderBy: blockTimestamp
      orderDirection: desc
    ) {
      id
      blockTimestamp
      calldatas
      description
      proposalId
      proposer
      signatures
      targets
      voteEnd
      voteStart
    }
  }
`;

const PROPOSAL_VOTES = gql`
  query GetVotes {
    voteCasts {
      id
      proposalId
      support
      weight
    }
    voteCastWithParams {
      id
      proposalId
      support
      weight
    }
  }
`;

const {
  decodeCalldata,
  extractAbiParameters,
  extractAbiParameterType,
  abiFromSelector,
  formatUnits,
  toBigNumber,
  keccak256Hash
} = ConversionUtils;

const ProposalStates = {
  "Pending" : 0,
  "Active" : 1,
  "Cancelled" : 2,
  "Defeated" : 3,
  "Succeeded" : 4,
  "Queued" : 5,
  "Expired" : 6,
  "Executed" : 7,
}

const Votes = ["voteAgainst", "voteFor", "voteAbstain"];

const ContentsRoot = styled('div')(({ theme }) => ({
  borderLeft : `1px solid ${theme.palette.tertiary.dark}`,
  width: "60em"
}));

const ProposalPayload = (props) => {
  const { targets, onAddProposal } = props;
  const { t } = useTranslation("translation", {keyPrefix: "governance"});
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedSelector, setSelectedSelector] = useState("");
  const [selectors, setSelectors] = useState([]);
  const [selectorInputs, setSelectorInputs] = useState({});
  const [parsedSelectorInputs, setParsedSelectorInputs] = useState({});
  const [calldataValue, setCalldataValue] = useState("");
  const loupeFacets = useSelector(state => getAllFacets(state));

  useEffect(() => {
    const inputsLength = Object.keys(parsedSelectorInputs).length;
    const selector = getCurrentSelector();
    if (selector && inputsLength !== 0 && inputsLength === selector.inputs.length) {
      const args = selector.inputs.map(i => parsedSelectorInputs[i.name]);
      const abi = abiFromSelector(selector);
      try {
        setCalldataValue(abi.encodeFunctionData(selector.name, args));
      } catch (e) {
        console.error(e);
        setCalldataValue("")
      }
    } else {
      setCalldataValue("");
    }
  }, [parsedSelectorInputs]);

  useEffect(() => {
    const selector = getCurrentSelector();
    if (selector && selector.inputs.length === 0) {
      const abi = abiFromSelector(selector);
      setCalldataValue(abi.encodeFunctionData(selector.name, []));
    }
  }, [selectedSelector]);

  const parseInputs = (input, args) => {
    if (!input || !args) {
      return null;
    }

    if (input.lastIndexOf("[]") === input.length - 2) {
      try {
        const parsedArgs = Array.isArray(args) ? args : JSON.parse(args);
        const res = parsedArgs.map(a => parseInputs(input.slice(0, input.lastIndexOf("[]")), a)).filter(a => a !== null);
        return res.length === parsedArgs.length ? parsedArgs : null;
      } catch (e) {
        return null;
      }
    } else if (Array.isArray(args) && input.indexOf("(") !== 0) {
      return null;
    }

    const hexReg = RegExp(/^0x[a-fA-F0-9]*$/);
    const intReg = RegExp(/^-?\d*$/);
    const uintReg = RegExp(/^\d*$/);

    // Easy cases
    switch(input) {
      case "bytes":
        return hexReg.test(args) && args.length % 2 === 0 ? args : null;
      case "address":
        return hexReg.test(args) && args.length === 42 ? args : null;
      case "bool":
        return (args === "true" || args === "false") ? args : null;
      case "string":
        return args;
    }

    // TODO check that the value actually fits
    if (input.indexOf("int") === 0) {
      return intReg.test(args) ? args : null
    }

    // TODO check that the value actually fits
    if (input.indexOf("uint") === 0) {
      return uintReg.test(args) ? args : null
    }

    // TODO check that the value actually fits
    if (input.indexOf("bytes") === 0 && input.length > "bytes".length) {
      return hexReg.test(args) ? args : null;
    }

    // Tuple
    if (input.indexOf("(") === 0 && input.indexOf(")") === input.length - 1) {
      if (Array.isArray(args)) {
        const params = input.replace("(", "").replace(")", "").split(",").map(i => i.trim());
        if (params.length !== args.length) {
          return null
        }
        const res = args.map((a, i) => parseInputs(params[i], a)).filter(a => a !== null);
        return res.length === args.length ? args : null;
      } else {
        return null;
      }
    }
    return null;
  }

  const updateSelectedTarget = (e) => {
    setSelectedTarget(e.target.value);
    setSelectedSelector("");
    setSelectorInputs({});
    setParsedSelectorInputs({});
    const facets = loupeFacets.find(lf => lf.target === e.target.value);
    if (facets) {
      const flattenedSelectors = facets.facets.map(f => f.selectors).flat();
      const commonMethods = [
        "diamondCut", "grantRole", "revokeRole", "transferGameFeeBalance",
        "transferOwnership", "pauseGame", "unpauseGame", "configureGuaranteedPrize",
        "removeGuaranteedPrize", "updateDelay", "updateUserAgreement", "configureAPI3Airnode",
        "retryCurrentDraw"
      ];
      const governanceFacets = flattenedSelectors.filter(({name}) => name.startsWith("set") || commonMethods.includes(name));
      setSelectors(governanceFacets);
    }
  }

  const updateSelectedSelector = (e) => {
    setSelectedSelector(e.target.value);
    setSelectorInputs({});
    setParsedSelectorInputs({});
  }

  const getCurrentSelector = () => {
    return selectors.find(s => s.signature === selectedSelector);
  }

  const getCurrentSelectorInputs = () => {
    const selector = getCurrentSelector();
    if (selector) {
      return selector.inputs;
    } else {
      return [];
    }
  }

  const updateInputValue = (input, value) => {
    const inputs = selectorInputs;
    const { name } = input;
    setSelectorInputs({...inputs, [name] : value});

    const selector = selectors.find(s => s.signature === selectedSelector);
    selector.inputs.forEach(i => {
        if (i.name === name) {
        const update = {...parsedSelectorInputs};
        const paramType = extractAbiParameterType(i);
        const parsedArg = parseInputs(paramType, value);
        if (parsedArg) {
          update[name] = parsedArg;
        } else {
          delete update[name];
        }
        setParsedSelectorInputs(update);
      }
    });
  }

  const addPayload = () => {
    const selector = getCurrentSelector();
    const args = decodeCalldata(selector, calldataValue);
    const functionArgs = args.map(a => a._isBigNumber ? a.toString() : a);
    onAddProposal({
      targetName: targets[selectedTarget],
      target: selectedTarget,
      signature: 0,
      calldata: calldataValue,
      functionSignature: formatSelectorLabel(selector),
      functionCall: `${selector.name}(${functionArgs})`
    });
    setSelectedTarget("");
    setSelectedSelector("");
    setSelectorInputs({});
    setParsedSelectorInputs({});
  }

  const formatSelectorLabel = (s) => {
    const name = s.name;
    const params = extractAbiParameters(s.inputs);
    return `${name}(${params})`;
  }

  return <Box>
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          {t("proposalPayload")}
        </Typography>
        <Button disabled={!calldataValue} variant="outlined" onClick={addPayload}>{t("addPayload")}</Button>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="target-select-label">{t("target")}</InputLabel>
        <Select
          labelId="target-select-label"
          id="target-select"
          label={t("target")}
          value={selectedTarget}
          onChange={updateSelectedTarget}
        >
          {Object.keys(targets).map((k, i) => {
            return <MenuItem value={k} key={i}>
              <LabelWithValue label={targets[k]} value={k} accentLabel={true} separator={""} />
            </MenuItem>
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="selector-select-label">{t("selector")}</InputLabel>
        <Select
          labelId="selector-select-label"
          id="selector-select"
          label={t("selector")}
          value={selectedSelector}
          onChange={updateSelectedSelector}
        >
          {selectors.map((s, i) => {
            return <MenuItem value={s.signature} key={i}>
              <LabelWithValue label={formatSelectorLabel(s)} value={s.signature} accentLabel={true} separator={""} />
            </MenuItem>
          })}
        </Select>
      </FormControl>
      <Stack direction="row" spacing={2}>
        {getCurrentSelectorInputs().map((input, i) => {
          return <TextField
            key={i}
            error={!!selectorInputs[input.name] && !parsedSelectorInputs[input.name]}
            label={input.name}
            inputProps={{ autoComplete: "off"}}
            value={Object.keys(selectorInputs).includes(input.name) ? selectorInputs[input.name] : ""}
            onChange={(e) => updateInputValue(input, e.target.value)}
          />
        })}
      </Stack>
      <Box>
        <LabelWithValue label={t("encodedPayload")} accentLabel={true} value={calldataValue ? calldataValue : t("calldataNotAvailable")}/>
      </Box>
    </Stack>
  </Box>
}

const VotingModal = (props) => {
  const theme = useTheme();
  const { t } = useTranslation("translation", {keyPrefix: "governance"});
  const [selectedOption, setSelectedOption] = useState(-1);
  const { open, proposalId, dismiss, accept } = props;

  return <div>
    <Modal open={open}>
      <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          p: 2
      }}>
        <Box sx={{textAlign: "center"}}>
          <Typography>
            {t("voteChoice")}
          </Typography>
          <Stack spacing={2}>
            <List>
              {Votes.map((v, i) => {
                return <ListItemButton
                  key={i}
                  selected={selectedOption === i}
                  onClick={() => setSelectedOption(i)}
                  >
                  {t(v)}
                </ListItemButton>
              })}
            </List>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={dismiss}>Cancel</Button>
              <Button disabled={selectedOption === -1} variant="outlined" onClick={() => accept(proposalId, selectedOption)}>Vote</Button>
            </Stack>
         </Stack>
        </Box>
      </Box>
    </Modal>
  </div>
}

const VotingDistribution = (props) => {
  const { distribution } = props;
  const { voteFor, voteAgainst, voteAbstain, voteTotal } = distribution;
  const theme = useTheme()
  const { t } = useTranslation("translation", {keyPrefix: "governance"});
  const chanceyToken = useSelector(state => getToken(state, "chancey"));

  if (voteTotal === "0") {
    return <span></span>;
  }

  const forValue = toBigNumber(voteFor);
  const againstValue = toBigNumber(voteAgainst);
  const abstainValue = toBigNumber(voteAbstain);
  const totalValue = toBigNumber(voteTotal);

  const forRate = forValue.mul(100).div(totalValue).toString();
  const againstRate = againstValue.mul(100).div(totalValue).toString();
  const abstainRate = abstainValue.mul(100).div(totalValue).toString();

  return <Tooltip
    title={<span>
      <div>{t("voteFor")} {formatUnits(forValue, chanceyToken.decimals, 0).toString()}</div>
      <div>{t("voteAgainst")} {formatUnits(againstValue, chanceyToken.decimals, 0).toString()}</div>
      <div>{t("voteAbstain")} {formatUnits(abstainValue, chanceyToken.decimals, 0).toString()}</div>
    </span>}
    placement="top"
    arrow
    >
    <Box sx={{width: "100%", height: "10px", display: "flex"}}>
      <Box sx={{width: `${parseInt(forRate)}%`, backgroundColor: theme.palette.primary.main}}></Box>
      <Box sx={{width: `${parseInt(againstRate)}%`, backgroundColor: theme.palette.error.main}}></Box>
      <Box sx={{width: `${parseInt(abstainRate)}%`, backgroundColor: theme.palette.tertiary.main}}></Box>
    </Box>
  </Tooltip>
}

const Governance = () => {
  const { t } = useTranslation("translation", {keyPrefix: "governance"});
  const theme = useTheme();
  const contracts = useContext(ContractsStateContext);
  const wallet = useContext(WalletStateContext);
  const smallScreen = !useMediaQuery(theme.breakpoints.up('lg'));
  const hoveredProposal = {
    "&:hover": {
      backgroundColor: theme.palette.success.main
    }
  };
  const [proposalDescription, setProposalDescription] = useState("");
  const [txArgs, setTxArgs] = useState(null);
  const [txDiagOpen, setTxDiagOpen] = useState({proposing: false, voting: false, delegating: false, cancelling: false, queueing: false, executing: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({proposing: false, voting: false, delegating: false, cancelling: false, queueing: false, executing: false});
  const [expandedProposals, setExpandedProposals] = useState({0: true});
  const [selectedTab, setSelectedTab] = useState(0);
  const [targets, setTargets] = useState([]);
  const [proposalPayloads, setProposalPayloads] = useState([]);
  const [delegateTo, setDelegateTo] = useState("");
  const [delegateAddressError, setDelegateAddressError] = useState(false);
  const [votingDialogOpen, setVotingDialogOpen] = useState(false);
  const [proposalToVote, setProposalToVote] = useState("");
  const [fetchProposals, { loading: proposalsLoading, error: proposalsError, data: proposalsData }] = useLazyQuery(ALL_PROPOSALS_QUERY);
  const [fetchVotes, { loading: votesLoading, error: votesError, data: votesData }] = useLazyQuery(PROPOSAL_VOTES);
  const [proposalVotes, setProposalVotes] = useState({});
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const proposals = useSelector(state => getGQLProposals(state));
  const loupeFacets = useSelector(state => getAllFacets(state));
  const originalDelegateTo = useSelector(state => getDelegateAddress(state));
  const delegateVotingPower = useSelector(state => getDelegateVotingPower(state));
  const ownVotingPower = useSelector(state => getOwnVotingPower(state));
  const cancelRole = useSelector(state => getCancelRole(state));
  const dispatch = useDispatch();
  const proposalMetadata = [{
    property: "proposer"
  },{
    property: "blockTimestamp",
    format: (t) => moment(new Date(t * 1000)).format("llll")
  },{
    property: "voteStart",
  },{
    property: "voteEnd",
  },{
    property: "minQuorum",
    format: (t) => formatUnits(t, chanceyToken.decimals, 0).toString()
  }];

  useEffect(() => {
    if (contracts && wallet?.account) {
      dispatch(fetchDelegateAddress({contracts, account: wallet.account}));
      dispatch(fetchOwnVotingPower({contracts, account: wallet.account}));
      dispatch(fetchCancelRole({contracts, account: wallet.account}));
      setTargets(contracts.getGovernedContracts());
      fetchProposals();
      fetchVotes();
    }
  }, [contracts, wallet]);

  useEffect(() => {
    // Store proposals in redux so that they can be
    // decorated with more information
    if (proposalsData?.proposalCreateds?.length > 0) {
      dispatch(storeGQLProposals({
        contracts,
        proposals: proposalsData.proposalCreateds
      }));
    }
  }, [proposalsData]);

  useEffect(() => {
    if (votesData?.voteCasts && votesData.voteCastWithParams) {
      const votes = {};
      const allVotes = votesData?.voteCasts.concat(votesData.voteCastWithParams);
      const voteOptions = ["voteAgainst", "voteFor", "voteAbstain"];
      const getVotes = (proposalId) => {
        if (!votes[proposalId]) {
          votes[proposalId] = {voteFor: "0", voteAgainst: "0", voteAbstain: "0", voteTotal: "0"};
        }
        return votes[proposalId];
      }
      for(let i = 0; i < allVotes.length; i++) {
        const {support, weight, proposalId} = allVotes[i];
        const option = voteOptions[support];
        const proposalVote = getVotes(proposalId);
        const currentWeight = toBigNumber(proposalVote[option]);
        proposalVote[option] = currentWeight.add(weight).toString();
        let total = toBigNumber(proposalVote["voteTotal"]);
        total = total.add(weight);
        proposalVote.voteTotal = total.toString();
      }
      setProposalVotes(votes);
    }
  }, [votesData]);

  useEffect(() => {
    const ethAddressPattern = RegExp(/^0x[a-fA-F0-9]{40}$/);
    if (contracts && ethAddressPattern.test(originalDelegateTo)) {
      dispatch(fetchDelegateVotingPower({contracts, delegate: originalDelegateTo}));
    }
  }, [contracts, originalDelegateTo]);

  const toggleProposalExpanded = (i) => {
    const expanded = expandedProposals[i];
    setExpandedProposals({[i]: !expanded});
  }

  const updateProposalDescription = (d) => {
    setProposalDescription(d);
  }

  const updateNewDelegate = (d) => {
    setDelegateTo(d);
    const ethAddressPattern = RegExp(/^0x[a-fA-F0-9]{40}$/);
    setDelegateAddressError(d !== "" && !ethAddressPattern.test(d));
  }

  const addProposalPayload = (p) => {
    const payloads = [...proposalPayloads];
    payloads.push(p);
    setProposalPayloads(payloads);
  }

  const removeProposalPayload = (i) => {
    const payloads = [...proposalPayloads];
    if (i >= 0 && i < payloads.length) {
      payloads.splice(i, 1);
      setProposalPayloads(payloads);
    }
  }

  const extractProposalParmaters = (proposal) => {
    const callParameters = [];
    if (contracts) {
      const { targets } = proposal;
      for(let t = 0; t < targets.length; ++t) {
        const target = targets[t];
        const governedContracts = contracts.getGovernedContracts();
        const key = Object.keys(governedContracts).find(k => k.toLocaleLowerCase() === target.toLowerCase());
        const targetName = contracts.getGovernedContracts()[key];
        const calldata = proposal.calldatas[t];
        const signature = proposal.signatures[t];
        const parameter = {target, targetName, calldata, signature};
        const loupe = loupeFacets.find(lf => lf.target.toLowerCase() === target.toLowerCase());
        if (loupe) {
          const selectorSig = calldata.substring(0,10);
          const facet = loupe.facets.find(f => f.selectors.map(s => s.signature).includes(selectorSig));
          if (facet) {
            const selector = facet.selectors.find(s => s.signature === selectorSig);
            if (selector) {
              const args = decodeCalldata(selector, calldata);
              const functionName = selector.name;
              const functionParams = extractAbiParameters(selector.inputs);
              const functionArgs = args.map(a => a._isBigNumber ? a.toString() : a);
              parameter.functionSignature = `${functionName}(${functionParams})`;
              parameter.functionCall = `${functionName}(${functionArgs})`;
            }
          }
        }
        callParameters.push(parameter);
      }
    }
    return callParameters;
  }

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const startTransactions = async(tx, args) => {
    setTxArgs(args);
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const proposeTransactions = [{
    pendingLabel: t("trProposeStep1Pending"),
    completedLabel: t("trProposeStep1Completed"),
    failedLabel: t("trProposeStep1Failed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trProposeStep2Pending"),
    completedLabel: t("trProposeStep2Completed"),
    failedLabel: t("trProposeStep2Failed"),
    tx: async() => {
      // propose
      try {
        const targets = proposalPayloads.map(p => p.target);
        const sigs = proposalPayloads.map(p => p.signature);
        const calls = proposalPayloads.map(p => p.calldata);
        const proposal = [targets, sigs, calls];
        const governor = await contracts.getGovernor();
        const res = await governor.propose(...proposal, proposalDescription);
        await res.wait();
        //dispatch(fetchAllProposals({contracts}));
        return 0;
      } catch (e) {
        console.error("Failed propose transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const queueTransactions = [{
    pendingLabel: t("trStartWalletPending"),
    completedLabel: t("trStartWalletCompleted"),
    failedLabel: t("trStartWalletFailed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trQueuePending"),
    completedLabel: t("trQueueCompleted"),
    failedLabel: t("trQueueFailed"),
    tx: async(p) => {
      // queue
      try {
        const {targets, signatures, calldatas, description} = p;
        const sigs = signatures.map(s => s === '' ? 0 : s);
        const proposal = [targets, sigs, calldatas];
        const descriptionHash = keccak256Hash(description);
        const governor = await contracts.getGovernor();
        const res = await governor.queue(...proposal, descriptionHash);
        await res.wait();
        //dispatch(fetchAllProposals({contracts}));
        return 0;
      } catch (e) {
        console.error("Failed propose transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const executeTransactions = [{
    pendingLabel: t("trStartWalletPending"),
    completedLabel: t("trStartWalletCompleted"),
    failedLabel: t("trStartWalletFailed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trExecutePending"),
    completedLabel: t("trExecuteCompleted"),
    failedLabel: t("trExecuteFailed"),
    tx: async(p) => {
      // execute
      try {
        const {targets, signatures, calldatas, description} = p;
        const sigs = signatures.map(s => s === '' ? 0 : s);
        const proposal = [targets, sigs, calldatas];
        const descriptionHash = keccak256Hash(description);
        const governor = await contracts.getGovernor();
        const res = await governor.execute(...proposal, descriptionHash);
        await res.wait();
        //dispatch(fetchAllProposals({contracts}));
        return 0;
      } catch (e) {
        console.error("Failed propose transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const voteTransactions = [{
    pendingLabel: t("trVoteStep1Pending"),
    completedLabel: t("trVoteStep1Completed"),
    failedLabel: t("trVoteStep1Failed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trVoteStep2Pending"),
    completedLabel: t("trVoteStep2Completed"),
    failedLabel: t("trVoteStep2Failed"),
    tx: async(args) => {
      // cast vote
      try {
        const governor = await contracts.getGovernor();
        const res = await governor.castVote(args.proposalId, args.voteChoice);
        await res.wait();
        //dispatch(fetchAllProposals({contracts}));
        return 0;
      } catch (e) {
        console.error("Failed vote transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const cancelProposalTransactions = [{
    pendingLabel: t("trStartWalletPending"),
    completedLabel: t("trStartWalletCompleted"),
    failedLabel: t("trStartWalletFailed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trCancelPending"),
    completedLabel: t("trCancelCompleted"),
    failedLabel: t("trCancelFailed"),
    tx: async(p) => {
      // cancel
      try {
        const {targets, signatures, calldatas, description} = p;
        const sigs = signatures.map(s => s === '' ? 0 : s);
        const proposal = [targets, sigs, calldatas];
        const descriptionHash = keccak256Hash(description);
        const governor = await contracts.getGovernor();
        const res = await governor.cancelProposal(...proposal, descriptionHash);
        await res.wait();
        //dispatch(fetchAllProposals({contracts}));
        return 0;
      } catch (e) {
        console.error("Failed vote transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const delegateTransactions = [{
    pendingLabel: t("trDelegateStep1Pending"),
    completedLabel: t("trDelegateStep1Completed"),
    failedLabel: t("trDelegateStep1Failed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trDelegateStep2Pending"),
    completedLabel: t("trDelegateStep2Completed"),
    failedLabel: t("trDelegateStep2Failed"),
    tx: async() => {
      // delegate
      try {
        const votes = await contracts.getChanceyFacet("ChanceyERC20VotesFacet");
        const res = await votes.delegate(delegateTo);
        await res.wait();
        dispatch(fetchDelegateAddress({contracts, account: wallet.account}));
        dispatch(fetchOwnVotingPower({contracts, account: wallet.account}));
        return 0;
      } catch (e) {
        console.error("Failed delegate transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const showVotingPrompt = (proposalId) => {
    setProposalToVote(proposalId);
    setVotingDialogOpen(true);
  }

  const startVotingTransaction = (proposalId, voteChoice) => {
    setProposalToVote("");
    setVotingDialogOpen(false);
    startTransactions("voting", {proposalId, voteChoice});
  }

  const cancelVoting = () => {
    setProposalToVote("");
    setVotingDialogOpen(false);
  }

  return <Box>
    <Typography variant="h3">
      {t("header")}
    </Typography>
    {smallScreen && <Box sx={{p: 4}}>
      <Typography>
        {t("onlyBigScreens")}
      </Typography>
    </Box>}
    {!smallScreen && <Box sx={{paddingTop: 3, paddingBottom: 3}}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box>
            <List>
              <ListItemButton
                selected={selectedTab === 0}
                onClick={() => setSelectedTab(0)}
                sx={{
                  '&.Mui-selected': {
                    borderLeft : `1px solid ${theme.palette.primary.main}`,backgroundColor: 'rgba(0, 0, 0, 0)'
                  }
                }}
              >
                <Box>
                  <Typography sx={{ ...theme.typography.tableHeader, fontWeight: "bold" }} color={theme.palette.tertiary.lighter}>
                    {t("proposals")}
                  </Typography>
                </Box>
              </ListItemButton>
              <ListItemButton
                selected={selectedTab === 1}
                onClick={() => setSelectedTab(1)}
                sx={{
                  '&.Mui-selected': {
                    borderLeft : `1px solid ${theme.palette.primary.main}`,backgroundColor: 'rgba(0, 0, 0, 0)'
                  }
                }}
              >
                <Box>
                  <Typography sx={{ ...theme.typography.tableHeader, fontWeight: "bold" }} color={theme.palette.tertiary.lighter}>
                    {t("submitProposal")}
                  </Typography>
                </Box>
              </ListItemButton>
              <ListItemButton
                selected={selectedTab === 2}
                onClick={() => setSelectedTab(2)}
                sx={{
                  '&.Mui-selected': {
                    borderLeft : `1px solid ${theme.palette.primary.main}`,backgroundColor: 'rgba(0, 0, 0, 0)'
                  }
                }}
              >
                <Box>
                   <Typography sx={{ ...theme.typography.tableHeader, fontWeight: "bold" }} color={theme.palette.tertiary.lighter}>
                    {t("delegation")}
                  </Typography>
                </Box>
              </ListItemButton>
            </List>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box>
            <ContentsRoot>
            {selectedTab === 0 && <Box>
              <Box>
                <List sx={{p: 1}}>
                  {proposals.length !== 0 ? (proposals.map((p,i) => {
                    return <Box key={i} sx={{p:2}}>
                      <Paper>
                        <ListItem>
                          <Box sx={{width: "100%"}}>
                            <Stack direction="column" alignItems="center">
                              <Stack direction="row" sx={{width: "100%"}} justifyContent="space-between" spacing={2}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Button sx={hoveredProposal} disableRipple={true} size="small" disableElevation={true} variant="contained" color="success">
                                    {t(`proposalState${p.state}`)}
                                  </Button>
                                  <Typography>
                                    {t("proposalNumber", {number: proposals.length - i})}
                                  </Typography>
                                  <Typography sx={{...theme.typography.tableCell, color: theme.palette.tertiary.dark}}>
                                    {t("proposalId", {id: `${p.proposalId.slice(0,8)} ... ${p.proposalId.slice(-8)}`})}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                  {p.state === ProposalStates.Succeeded && <Button variant="outlined" onClick={() => startTransactions("queueing", p)}>{t("queue")}</Button>}
                                  {p.state === ProposalStates.Queued && <Button variant="outlined" onClick={() => startTransactions("executing", p)} >{t("execute")}</Button>}
                                  {p.state === ProposalStates.Active && ownVotingPower !== "0" && <Button variant="outlined" onClick={() => showVotingPrompt(p.proposalId)}>{t("vote")}</Button>}
                                  {p.state === ProposalStates.Active && ownVotingPower === "0" && <Tooltip title={t("noVotingPower")} arrow><div><Button variant="outlined" disabled={true}>{t("vote")}</Button></div></Tooltip>}
                                  {cancelRole && <Button variant="outlined" onClick={() => startTransactions("cancelling", p)} >{t("cancel")}</Button>}
                                </Stack>
                              </Stack>
                              <Collapse in={expandedProposals[i]} collapsedSize={40} sx={{width: "100%"}}>
                                <Box sx={{paddingTop: 2, paddingBottom: 2}}>
                                  <Stack spacing={1}>
                                    <Typography>
                                      {p.description}
                                    </Typography>
                                    <Stack>
                                      {proposalMetadata.map(m => {
                                        return <LabelWithValue
                                          key={m.property}
                                          label={t(m.property)}
                                          value={m.format ? m.format(p[m.property]) : p[m.property]}
                                        />
                                      })}
                                    </Stack>
                                    {proposalVotes[p.proposalId] && <Stack>
                                      <VotingDistribution distribution={proposalVotes[p.proposalId]}>
                                      </VotingDistribution>
                                    </Stack>}
                                    <Stack spacing={2}>
                                      <Typography>
                                        {t("payload")}
                                      </Typography>
                                      <Stack spacing={2}>
                                        {extractProposalParmaters(p).map((a, i) => {
                                          return <Box key={i} sx={{p: 1, border: `1px solid ${theme.palette.tertiary.dark}`, borderRadius: 1}}>
                                            <Stack>
                                              {Object.keys(a).map((k, j) => {
                                                return <LabelWithValue key={j} label={t(k)} value={a[k]} />
                                              })}
                                            </Stack>
                                          </Box>
                                        })}
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </Box>
                              </Collapse>
                              <Box>
                                <IconButton onClick={() => toggleProposalExpanded(i)}>
                                  {expandedProposals[i] && <ExpandLessOutlinedIcon color="primary"/>}
                                  {!expandedProposals[i] && <ExpandMoreOutlinedIcon color="primary"/>}
                                </IconButton>
                              </Box>
                            </Stack>
                          </Box>
                        </ListItem>
                      </Paper>
                    </Box>
                  })) : (<Box>
                          <Typography variant="h4">
                            {proposalsLoading? t("fetchingProposals") : proposalsError ? t("errorFetchingProposals", {error: proposalsError}) : t("noProposals")}
                          </Typography>
                        </Box>
                    )}
                </List>
              </Box>
            </Box>}
            {selectedTab === 1 && <Box sx={{p:2}}>
            <Box>
              <Paper>
                <Box sx={{p: 2}}>
                  <Stack spacing={2}>
                    <Typography variant="h4">
                      {t("proposalConfigurations")}
                    </Typography>
                    <TextField
                      multiline={true}
                      label={t("proposalDescription")}
                      value={proposalDescription}
                      onChange={(e) => updateProposalDescription(e.target.value)}
                    />
                    <ProposalPayload onAddProposal={addProposalPayload} targets={targets}/>
                    <Typography variant="h4">
                      {t("proposalPayloadPreview")}
                    </Typography>
                    <Stack spacing={2}>
                      {proposalPayloads.map((a, i) => {
                        return <Box key={i} sx={{p: 1, border: `1px solid ${theme.palette.tertiary.dark}`, borderRadius: 1}}>
                          <Stack>
                            {Object.keys(a).map((k, j) => {
                              return <LabelWithValue key={j} label={t(k)} value={a[k]} />
                            })}
                          </Stack>
                          <Stack direction="row" justifyContent="flex-end">
                            <Button variant="outlined" onClick={() => removeProposalPayload(i)}>{t("removeProposal")}</Button>
                          </Stack>
                        </Box>
                      })}
                    </Stack>
                    <Button
                      disabled={proposalPayloads.length === 0 || !proposalDescription}
                      variant="outlined"
                      onClick={() => startTransactions("proposing")}
                    >
                      {t("propose")}
                    </Button>
                  </Stack>
                </Box>
              </Paper>
              <TransactionModal
                open={txDiagOpen["proposing"]}
                onClose={() => closeTxDiag("proposing")}
                steps={proposeTransactions}
                onFinished={() => onTxDiagFinished("proposing")}
                args={txArgs}
              >
              </TransactionModal>
            </Box>
            </Box>}
            {selectedTab === 2 && <Box sx={{p:2}}>
              <Box>
                <Paper>
                  <Box sx={{p: 2}}>
                    <Stack spacing={2}>
                      <Typography variant="h4">
                        {t("delegation")}
                      </Typography>
                      <LabelWithValue label={t("delegatedTo")} value={originalDelegateTo} accentLabel={true} separator={""} />
                      <LabelWithValue label={t("delegateVotePower")} value={t("delegateVotes", {votes: formatUnits(delegateVotingPower, chanceyToken.decimals, 0)})} accentLabel={true} separator={""} />
                      <LabelWithValue label={t("ownVotePower")} value={t("delegateVotes", {votes: formatUnits(ownVotingPower, chanceyToken.decimals, 0)})} accentLabel={true} separator={""} />
                      <TextField
                        value={delegateTo}
                        label={t("delegateNew")}
                        error={delegateAddressError}
                        onChange={(e) => updateNewDelegate(e.target.value)}
                      />
                      <Box>
                        <Button
                          variant="outlined"
                          disabled={delegateTo === "" || delegateAddressError}
                          onClick={() => startTransactions("delegating", {delegateTo})}
                        >
                          {t("delegateChange")}
                        </Button>
                      </Box>
                    </Stack>
                    <TransactionModal
                      open={txDiagOpen["delegating"]}
                      onClose={() => closeTxDiag("delegating")}
                      steps={delegateTransactions}
                      onFinished={() => onTxDiagFinished("delegating")}
                      args={txArgs}
                    >
                    </TransactionModal>
                  </Box>
                </Paper>
              </Box>
            </Box>}
            <VotingModal
              open={votingDialogOpen}
              proposalId={proposalToVote}
              dismiss={cancelVoting}
              accept={startVotingTransaction}
            >
            </VotingModal>
            <TransactionModal
              open={txDiagOpen["voting"]}
              onClose={() => closeTxDiag("voting")}
              steps={voteTransactions}
              onFinished={() => onTxDiagFinished("voting")}
              args={txArgs}
            >
            </TransactionModal>
            <TransactionModal
              open={txDiagOpen["queueing"]}
              onClose={() => closeTxDiag("queueing")}
              steps={queueTransactions}
              onFinished={() => onTxDiagFinished("queueing")}
              args={txArgs}
            >
            </TransactionModal>
            <TransactionModal
              open={txDiagOpen["executing"]}
              onClose={() => closeTxDiag("executing")}
              steps={executeTransactions}
              onFinished={() => onTxDiagFinished("executing")}
              args={txArgs}
            >
            </TransactionModal>
            <TransactionModal
              open={txDiagOpen["cancelling"]}
              onClose={() => closeTxDiag("cancelling")}
              steps={cancelProposalTransactions}
              onFinished={() => onTxDiagFinished("cancelling")}
              args={txArgs}
            >
            </TransactionModal>
            </ContentsRoot>
          </Box>
        </Grid>
      </Grid>
    </Box>}
  </Box>
}
export default Governance