const Networks = {
  31337 : {
    name: "Local Node",
    contractsFolder: "develop",
    logo: "hardhat.svg"
  },
  84532 : {
    name: "Base Sepolia",
    contractsFolder: "baseSepolia",
    logo: "base.svg"
  }
}

const Contracts = {};
const contractNames = [
  "AccessControlFacet",
  "Chancey",
  "ChanceyERC1363ExtendedFacet",
  "ChanceyERC20Facet",
  "ChanceyERC20PermitFacet",
  "ChanceyERC20VotesFacet",
  "ChanceyGovernor",
  "ChanceyTimelockController",
  "Chancey649",
  "ClaimsFacet",
  "DiamondCutFacet",
  "GameFacet",
  "LoupeFacet",
  "StakingFacet",
  "TicketBoothFacet",
  "UserAgreementsFacet",
  "VRFCoordinatorV2Mock",
  "AirnodeRrpV0"
];

const networksList = Object.entries(Networks);
for(let n = 0; n < networksList.length; ++n) {
  const [id, details] = networksList[n];
  const { contractsFolder } = details;
  Contracts[id] = {};
  for (let c = 0; c < contractNames.length; ++c) {
    const contractName = contractNames[c];
    if (["VRFCoordinatorV2Mock", "AirnodeRrpV0"].includes(contractName) && !["localhost", "develop"].includes(contractsFolder)) {
      continue;
    }
    try {
      Contracts[id][contractName] = await import(`../contracts/${contractsFolder}/${contractName}.json`);
    } catch (e) {
      console.error(e);
    }
  }
}

export {Contracts, Networks};