import React from "react";
import PropTypes from "prop-types";

export const ContractsStateContext = React.createContext();
export const ContractsDispatchContext = React.createContext();
export const SET_FACTORY = "SET_FACTORY";

export const ContractsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FACTORY: {
      return payload;
    }
    default:
      return state;
  }
};

function Contracts(props) {
  const { initialState, dispatch } = props;
  return (
    <ContractsStateContext.Provider value={initialState}>
      <ContractsDispatchContext.Provider value={dispatch}>
        {props.children}
      </ContractsDispatchContext.Provider>
    </ContractsStateContext.Provider>
  );
}

Contracts.propTypes = {
  initialState: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default Contracts;