import { gql } from '@apollo/client';

export const TABLE_SIZE = 100;
export const TICKETS_QUERY = gql`
  query GetTickets($account: String!, $drawId: String!, $skip: Int!, $first: Int!) {
    ticketPurchaseds(
      skip: $skip
      first: $first
      orderBy: blockTimestamp
      orderDirection: desc
      where: {holder: $account, drawId: $drawId}
    ) {
      ticketId
      holder
      drawId
      data
      blockTimestamp
    }
  }
`;