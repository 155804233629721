import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { useState, useEffect, useContext } from "react";
import { ContractsStateContext } from "../context/contracts";
import { WalletStateContext } from "../context/wallet";
import { fetchAccountStakes, fetchTotalStakeAmount, getAccountStakes, getTotalStakeAmount } from "../store/slices/stakes-slice";
import { getAccountBalance, fetchAccountBalance } from "../store/slices/account-balances-slice";
import { getToken } from "../store/slices/tokens-slice";
import { fetchAllowance } from "../store/slices/allowances-slice";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Paper, Slider, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import ConversionUtils from "../utils/conversions";
import TransactionModal from "../components/TransactionModal";
import ChanceyTable from "../components/ChanceyTable";

const {
  toBigNumber,
  formatUnits,
  parseUnits,
} = ConversionUtils;

const Staking = () => {
  const { t } = useTranslation("translation", { keyPrefix: "staking" });
  const theme = useTheme();
  const navigate = useNavigate();

  const [stakeAmount, setStakeAmount] = useState("1");
  const [sliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(100);
  const [sliderValue, setSliderValue] = useState(1);
  const [txArgs, setTxArgs] = useState(null);
  const [txDiagOpen, setTxDiagOpen] = useState({staking: false, unstaking: false, claiming: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({staking: false, unstaking: false, claiming: false});
  const wallet = useContext(WalletStateContext);
  const contracts = useContext(ContractsStateContext);
  const stakeHistory = useSelector(state => getAccountStakes(state));
  const totalGameStake = useSelector(state => getTotalStakeAmount(state));
  const gameToken = useSelector(state => getToken(state, "game"));
  const chanceyToken = useSelector(state => getToken(state, "chancey"));
  const chanceyBalance = useSelector(state => getAccountBalance(state, "chancey"));
  const dispatch = useDispatch();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (contracts) {
      dispatch(fetchAccountStakes({contracts, account: wallet.account}));
      dispatch(fetchTotalStakeAmount({contracts, account: wallet.account}));
    }
  }, [contracts, wallet]);

  useEffect(() => {
    setSliderMax(parseInt(formatUnits(chanceyBalance, chanceyToken.decimals).toString()));
  }, [chanceyBalance]);

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const stakingTransactions = [{
    pendingLabel: t("trStakeStep1Pending"),
    completedLabel: t("trStakeStep1Completed"),
    failedLabel: t("trStakeStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trStakeStep3Pending"),
    completedLabel: t("trStakeStep3Completed"),
    failedLabel: t("trStakeStep3Failed"),
    tx: async() => {
      // Stake
      try {
        const game = await contracts.getGameFacet("GameFacet");
        const erc1363 = await contracts.getChanceyFacet("ChanceyERC1363ExtendedFacet");
        const amount = parseUnits(stakeAmount || "0", chanceyToken.decimals);
        const functionCall = "transferAndCall(address,uint256)";
        const res = await erc1363[functionCall](game.address, amount);
        await res.wait();
        dispatch(fetchAccountStakes({contracts, account: wallet.account}));
        dispatch(fetchTotalStakeAmount({contracts, account: wallet.account}));
        readNewBalances();
        updateStakeAmount("1");
        return 0;
      } catch (e) {
        console.error("Failed stake transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const unstakingTransactions = [{
    pendingLabel: t("trUnstakeStep1Pending"),
    completedLabel: t("trUnstakeStep1Completed"),
    failedLabel: t("trUnstakeStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trUnstakeStep2Pending"),
    completedLabel: t("trUnstakeStep2Completed"),
    failedLabel: t("trUnstakeStep2Failed"),
    tx: async(stake) => {
      try {
        // TODO allow partial unstake
        const staking = await contracts.getGameFacet("StakingFacet");
        const res = await staking.unstake(stake.index, stake.claim.amount);
        await res.wait();
        dispatch(fetchAccountStakes({contracts, account: wallet.account}));
        dispatch(fetchTotalStakeAmount({contracts, account: wallet.account}));
        readNewBalances();
        return 0;
      } catch (e) {
        console.error("Failed allow transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const claimTransactions = [{
    pendingLabel: t("trClaimStep1Pending"),
    completedLabel: t("trClaimStep1Completed"),
    failedLabel: t("trClaimStep1Failed"),
    tx: async() => {
      return 0;
    }
  },{
    pendingLabel: t("trClaimStep2Pending"),
    completedLabel: t("trClaimStep2Completed"),
    failedLabel: t("trClaimStep2Failed"),
    tx: async(stake) => {
      // Claiming
      try {
        const staking = await contracts.getGameFacet("StakingFacet");
        const res = await staking.claimStakeRewards(stake.index);
        await res.wait();
        dispatch(fetchAccountStakes({contracts, account: wallet.account}));
        readNewBalances();
        return 0;
      } catch (e) {
        console.error("Failed claim transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const startTransactions = async(tx, args) => {
    setTxArgs(args);
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const readNewBalances = async () => {
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "game",
      tokenAddress: gameToken.address,
      account: wallet.account
    }));
    dispatch(fetchAccountBalance({
      contracts,
      tokenName: "chancey",
      tokenAddress: chanceyToken.address,
      account: wallet.account
    }));
  }

  const updateStakeAmount = (a) => {
    if (!a) {
      setStakeAmount("");
      setSliderValue(0);
    } else if (!isNaN(a)){
      const s = parseUnits(a, chanceyToken.decimals);
      const b = toBigNumber(chanceyBalance);
      if (s.lte(b)) {
        setStakeAmount(a);
        setSliderValue(Number(a));
      }
    }
  }

  const handleSliderChange = (_, newValue) => {
    updateStakeAmount(String(newValue));
  };

  const stakeCellContents = (key, d) => {
    let cell = d[key];

    switch(key) {
      case "effectiveDrawId":
        cell = d.claim.effectiveDrawId;
        break;
      case "claim":
        cell = formatUnits(d.claim.amount, chanceyToken.decimals)
        break;
      case "reward":
        cell = formatUnits(d.reward, gameToken.decimals, 8)
        break;
      case "actions":
        cell = <Stack direction="row" spacing={1}>
          <Button variant="outlined" disabled={d.reward === "0"} onClick={() => startTransactions("claiming", d)}>{t("claim")}</Button>
          <Button variant="outlined" onClick={() => startTransactions("unstaking", d)}>{t("unstake")}</Button>
        </Stack>
        break;
    }

    return cell;
  }

  const stakeActions = (d) => {
    return <Stack direction="row" justifyContent="space-between">
      <Button variant="outlined" disabled={d.reward === "0"} onClick={() => startTransactions("claiming", d)}>{t("claim")}</Button>
      <Button variant="outlined" onClick={() => startTransactions("unstaking", d)}>{t("unstake")}</Button>
    </Stack>;
  }

  const showAccountStakes = () => {
    const spacing = smallScreen ? 1 : 2;
    const rows = stakeHistory.map(sh => {
      return {...sh, actions: stakeActions}
    });
    const tableData = {
      columns : [
        {key: "effectiveDrawId", name: t("effectiveDrawId")},
        {key: "claim", name: t("stakeAmount", {currency: chanceyToken.symbol})},
        {key: "reward", name: t("estimatedReward", {currency: gameToken.symbol})},
      ],
      rows: rows,
      rowFormatter: stakeCellContents
    }

    if (!smallScreen) {
      tableData.columns.push({key: "actions", name: t("actions")})
    }

    return (
      <Box sx={{paddingTop: 2}}>
        <Stack spacing={spacing} alignItems="center" >
          <Box sx={{p: 4}}>
            <Typography variant="h4" >{t("myStakes")}</Typography>
            <Typography sx={{ ...theme.typography.subtitle }} color={theme.palette.tertiary.main}>
              {t("myStakesDetails", {count: stakeHistory.length})}
            </Typography>
          </Box>
          {stakeHistory.length !== 0 && <Box sx={{width: {xs: "100%", md: "auto"}}}>
            <ChanceyTable data={tableData} compact={smallScreen}></ChanceyTable>
          </Box>}
        </Stack>
      </Box>
    )
  }

  return (
    <Box sx={{display:"inline-block"}}>
      <Stack alignItems="center" direction="column">
        <Stack spacing={2}>
          <Typography variant="h3">
            {t("header", {currency: chanceyToken.symbol})}
          </Typography>
          <Typography component={'span'} sx={{ ...theme.typography.subtitle }} color={theme.palette.tertiary.main}>
            {t("subheader",  {currency: chanceyToken.symbol, amount: formatUnits(totalGameStake, chanceyToken.decimals, 0)})}
          </Typography>
        </Stack>
        <Box sx={{width: "100%", paddingTop: 4}}>
          <Paper>
            {chanceyBalance < "1" && <Box sx={{p: 2}}>
              <Stack spacing={2} alignItems="stretch" direction="column">
                <Box sx={{width: "100%", p: 2}}>
                  <Typography>
                    {t("insufficientBalance", {currency: chanceyToken.symbol})}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="outlined" onClick={() => navigate('/tickets')}>{t("buy")}</Button>
                </Box>
              </Stack>
            </Box>}
            {chanceyBalance >= "1" && <Box sx={{p: 2}}>
              <Stack direction={smallScreen ? "column" : "row"} spacing={2} alignItems="center" justifyContent="center">
                <Stack>
                  <Typography variant="h6">
                    {t("enterAmount")}
                  </Typography>
                  <Typography sx={{ ...theme.typography.accountBalance }}>
                    {t("availableBalance", {b: formatUnits(chanceyBalance, chanceyToken.decimals, 0), currency: chanceyToken.symbol})}
                  </Typography>
                </Stack>
                <TextField
                  color="tertiary"
                  size="small"
                  focused
                  value={stakeAmount}
                  onChange={(e) => updateStakeAmount(e.target.value)}
                >
                </TextField>
              </Stack>
              <Box sx={{p:2}}>
                <Slider
                  size="small"
                  valueLabelDisplay="auto"
                  min={sliderMin}
                  max={sliderMax}
                  value={sliderValue}
                  onChange={handleSliderChange}
                >
                </Slider>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  disabled={!stakeAmount || stakeAmount === "0"}
                  onClick={() => startTransactions("staking")}
                >
                  {t("stake")}
                </Button>
              </Box>
            </Box>}
          </Paper>
          <TransactionModal
            open={txDiagOpen["staking"]}
            onClose={() => closeTxDiag("staking")}
            steps={stakingTransactions}
            onFinished={() => onTxDiagFinished("staking")}
          >
          </TransactionModal>
          <TransactionModal
            open={txDiagOpen["unstaking"]}
            onClose={() => closeTxDiag("unstaking")}
            steps={unstakingTransactions}
            onFinished={() => onTxDiagFinished("unstaking")}
            args={txArgs}
          >
          </TransactionModal>
          <TransactionModal
            open={txDiagOpen["claiming"]}
            onClose={() => closeTxDiag("claiming")}
            steps={claimTransactions}
            onFinished={() => onTxDiagFinished("claiming")}
            args={txArgs}
          >
          </TransactionModal>
        </Box>
        <Box sx={{width:"100%"}}>
        { showAccountStakes() }
        </Box>
      </Stack>
    </Box>
  );
};

export default Staking;