import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import { WalletStateContext } from "../context/wallet";
import { ContractsStateContext } from "../context/contracts";
import { RandomSources } from "../configs.js";
import { fetchPot, getPot } from '../store/slices/pot-slice';
import { getToken } from "../store/slices/tokens-slice";
import { getGameBalance } from "../store/slices/game-balances-slice";
import { fetchAccountBalance } from "../store/slices/account-balances-slice";
import { fetchAllDraws, fetchCurrentDraw, getAllDraws, getCurrentDraw } from "../store/slices/draws-slice";
import { fetchGuaranteedPrizeConfigurations, getGuaranteedPrizeConfigurations, fetchRandomSource, getRandomSource } from "../store/slices/game-configurations-slice";
import { fetchWinnings, getWinnings } from "../store/slices/winnings-slice";
import { fetchGameState, fetchGamePaused, getGameState, getGamePaused, GameStates } from "../store/slices/game-state-slice";
import { fetchPauseRole, getPauseRole } from "../store/slices/governance-slice";
import { discoverFacets } from "../store/slices/loupes-slice";
import { Avatar, Box, Button, Link, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {
  fetchDrawInterval, fetchMinTickets,
  getDrawInterval, getMinTickets
} from "../store/slices/game-configurations-slice";

import chaindescriptors from "../utils/chaindescriptors";
import ConversionUtils from "../utils/conversions";
import moment from "moment";
import GlowingDiv from "../components/GlowingDiv";
import ChanceyTable from "../components/ChanceyTable";
import TransactionModal from "../components/TransactionModal";

const {
  formatUnits,
  parseUnits,
  toBigNumber,
  ticketLineFromHex,
  dateFromTimestamp,
  encodeParameters,
  solidityKeccak256,
  arrayify,
  toWallet,
  toBytes32
} = ConversionUtils;

const Dashboard = () => {
  const { t } = useTranslation("translation", {keyPrefix: "dashboard"});
  const theme = useTheme();
  const navigate = useNavigate();
  const [vrfAddress, setVrfAddress] = useState();
  const [airnodeRrpAddress, setAirnodeRrpAddress] = useState();
  const [game, setGame] = useState();
  const [claims, setClaims] = useState();
  const [gameLinkFunded, setGameLinkFunded] = useState(false);
  const [txDiagOpen, setTxDiagOpen] = useState({trigger: false, distribute: false, claiming: false, fakeNumbers: false, pauseGame: false});
  const [canCloseTxDiag, setCanCloseTxDiag] = useState({trigger: false, distribute: false, claiming: false, fakeNumbers: false, pauseGame: false});
  const [jackpot, setJackpot] = useState("0");
  const [chainExplorer, setChainExplorer] = useState("");
  const [louperNetworkName, setLouperNetworkName] = useState("");
  const wallet = useContext(WalletStateContext);
  const contracts = useContext(ContractsStateContext);
  const pot = useSelector(state => getPot(state));
  const gameToken = useSelector(state => getToken(state, "game"));
  const linkToken = useSelector(state => getToken(state, "link"));
  const linkBalance = useSelector(state => getGameBalance(state, "link"));
  const draw = useSelector(state => getCurrentDraw(state));
  const allDraws = useSelector(state => getAllDraws(state));
  const winnings = useSelector(state => getWinnings(state));
  const gameState = useSelector(state => getGameState(state));
  const gamePaused = useSelector(state => getGamePaused(state));
  const minTickets = useSelector(state => getMinTickets(state));
  const drawInterval = useSelector(state => getDrawInterval(state));
  const guranteedPrizeConfigurations = useSelector(state => getGuaranteedPrizeConfigurations(state));
  const randomSource = useSelector(state => getRandomSource(state));
  const pauseRole = useSelector(state => getPauseRole(state));
  const dispatch = useDispatch();
  const smallScreen = !useMediaQuery(theme.breakpoints.up('sm'));

  const fetchInfo = async () => {
    if (contracts) {
      const game = await contracts.getGameFacet("GameFacet");
      const claims = await contracts.getGameFacet("ClaimsFacet");
      setGame(game);
      setClaims(claims);
      setVrfAddress(await game.vrfWrapperAddress());
      setAirnodeRrpAddress(await game.airnodeRrp());
      const chain = chaindescriptors.find(c => c.chainId === contracts.getChainId());
      if (chain?.explorers.length > 0) {
        setChainExplorer(chain.explorers[0].url);
      }
      setLouperNetworkName(chain?.louperNetworkName);
    }
  }

  useEffect(() => {
    if (contracts && wallet?.account) {
      fetchInfo();
      dispatch(fetchMinTickets(contracts));
      dispatch(fetchDrawInterval(contracts));
      dispatch(fetchPot(contracts));
      dispatch(fetchCurrentDraw(contracts));
      dispatch(fetchAllDraws(contracts));
      dispatch(fetchGameState(contracts));
      dispatch(fetchGamePaused(contracts));
      dispatch(fetchGuaranteedPrizeConfigurations(contracts));
      dispatch(fetchRandomSource(contracts));
      dispatch(fetchWinnings({contracts, account: wallet.account}));
      dispatch(fetchPauseRole({contracts, account: wallet.account}));
      dispatch(discoverFacets(contracts));
    }
  }, [contracts, wallet]);

  useEffect(() => {
    // TODO the expected amount is dependent on the network
    // const expected = parseUnits("1", linkToken.decimals);
    // setGameLinkFunded(toBigNumber(linkBalance).gte(expected));
  }, [linkToken, gameState]);

  useEffect(() => {
    if (guranteedPrizeConfigurations.length > 0 && pot !== "0") {
      const guaranteedPrizeRate = guranteedPrizeConfigurations.reduce((acc, current) => {
        return acc += current.prizeRate;
      }, 0);
      const guaranteedPrizeShare = toBigNumber(pot).mul(guaranteedPrizeRate).div(100);
      setJackpot((toBigNumber(pot).sub(guaranteedPrizeShare)).toString());
    } else {
      setJackpot(pot);
    }
  }, [pot, guranteedPrizeConfigurations]);

  const closeTxDiag = (tx) => {
    if (canCloseTxDiag[tx]) {
      setTxDiagOpen({...txDiagOpen, [tx] : false});
    }
  }

  const onTxDiagFinished = async(tx) => {
    setCanCloseTxDiag({...canCloseTxDiag, [tx] : true});
  }

  const startTransactions = async(tx) => {
    setTxDiagOpen(true);
    setTxDiagOpen({...txDiagOpen, [tx] : true});
  };

  const requestFakeRandomNumbers = async() => {
    const response = Math.floor(Math.random() * 1000000);
    if (randomSource === RandomSources["chainlink-vrf"]) {
      const vrfMock = await contracts.getVRFMock();
      return await vrfMock.fulfillRandomWordsWithOverride(draw.randomnessRequestId, vrfAddress, [response]);
    } else {
      const data = encodeParameters(['uint256[]'], [[response]]);
      const messageHash = solidityKeccak256(['uint256', 'bytes'], [draw.randomnessRequestId, data]);

      // These are extracted from the local hardhat node
      // and they're pretty much constant if not changing
      // the default mnemonoic when deploying the contracts
      const localSigner = {
        address: "0x70997970C51812dc3A010C7d01b50e0d17dc79C8",
        privateKey: "0x59c6995e998f97a5a0044966f0945389dc9e86dae88c7a8412f4603b6b78690d"
      };

      const wallet = toWallet(localSigner.privateKey);
      const signer = wallet.connect();
      const signedMessage = await signer.signMessage(arrayify(messageHash));
      const selector = game.interface.getSighash("fulfillUint256Array(bytes32,bytes)");
      const airnodeRrp = await contracts.getAirnodeRrp(airnodeRrpAddress);
      const requestId = toBytes32(`${draw.randomnessRequestId}`);
      return await airnodeRrp.fulfill(
        requestId, localSigner.address,
        game.address, selector, data, signedMessage
      )
    }
  };

  const triggerTransactions = [{
    pendingLabel: t("trTriggerStep1Pending"),
    completedLabel: t("trTriggerStep1Completed"),
    failedLabel: t("trTriggerStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trTriggerStep2Pending"),
    completedLabel: t("trTriggerStep2Completed"),
    failedLabel: t("trTriggerStep2Failed"),
    tx: async() => {
      // Trigger draw
      try {
        const res = await game.draw();
        await res.wait();
        dispatch(fetchCurrentDraw(contracts));
        dispatch(fetchGameState(contracts));
        return 0;
      } catch (e) {
        console.error("Failed allow transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  // This will only work in local deployment
  // in prod, this transaction will fail
  const generateFakeNumbersTransactions = [{
    pendingLabel: t("trTriggerStep1Pending"),
    completedLabel: t("trTriggerStep1Completed"),
    failedLabel: t("trTriggerStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: "Generating Random Numbers",
    completedLabel: "Random Numbers Generated",
    failedLabel: "Failed to Generate Random Numbers",
    tx: async() => {
      // Trigger draw
      try {
        const res = await requestFakeRandomNumbers();
        await res.wait();
        dispatch(fetchCurrentDraw(contracts));
        dispatch(fetchAllDraws(contracts));
        dispatch(fetchGameState(contracts));
        return 0;
      } catch (e) {
        console.error("Failed generate transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const distributeTransactions = [{
    pendingLabel: t("trDistStep1Pending"),
    completedLabel: t("trDistStep1Completed"),
    failedLabel: t("trDistStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trDistStep2Pending"),
    completedLabel: t("trDistStep2Completed"),
    failedLabel: t("trDistStep2Failed"),
    tx: async() => {
      // Distribute draw
      try {
        const res = await game.distribute();
        await res.wait();
        dispatch(fetchCurrentDraw(contracts));
        dispatch(fetchGameState(contracts));
        dispatch(fetchAllDraws(contracts));
        return 0;
      } catch (e) {
        console.error("Failed allow transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const claimTransactions = [{
    pendingLabel: t("trClaimStep1Pending"),
    completedLabel: t("trClaimStep1Completed"),
    failedLabel: t("trClaimStep1Failed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trClaimStep2Pending"),
    completedLabel: t("trClaimStep2Completed"),
    failedLabel: t("trClaimStep2Failed"),
    tx: async() => {
      // Claim
      try {
        const res = await claims.claimWinnings();
        await res.wait();
        dispatch(fetchWinnings({contracts, account: wallet.account}));
        dispatch(fetchAccountBalance({
          contracts,
          tokenName: "game",
          tokenAddress: gameToken.address,
          account: wallet.account
        }));
        return 0;
      } catch (e) {
        console.error("Failed allow transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const pauseGameTransactions = [{
    pendingLabel: t("trStartWalletPending"),
    completedLabel: t("trStartWalletCompleted"),
    failedLabel: t("trStartWalletFailed"),
    tx: async() => {
      // Fake transaction to start MM
      return 0;
    }
  },{
    pendingLabel: t("trPauseGamePending"),
    completedLabel: t("trPauseGameCompleted"),
    failedLabel: t("trPauseGameFailed"),
    tx: async() => {
      // pause/unpause
      try {
        let res;
        if (gamePaused) {
          res = await game.unpauseGame();
        } else {
          res = await game.pauseGame();
        }
        await res.wait();
        dispatch(fetchGamePaused(contracts));
        return 0;
      } catch (e) {
        console.error("Failed allow transaction");
        if (e.code === 4001) {
          console.error("User rejected transaction");
        } else {
          console.error(e);
        }
        return e;
      }
    }
  }];

  const hasWinnings = () => {
    return !isNaN(winnings) && winnings !== "0";
  }

  const drawCellContents = (key, d) => {
    let cell = d[key];

    switch(key) {
      case "createdAt":
      case "playedAt":
        cell = dateFromTimestamp(d[key]);
        break;
      case "id":
        const id = d.randomnessRequestId;
        cell = smallScreen ? d[key] : <Tooltip title={t("randomnessRequestId", {id})} arrow><Box>{d[key]}</Box></Tooltip>;
        break;
      case "numbers":
        cell = ticketLineFromHex(d[key]);
        break;
      case "payout":
        cell = `${formatUnits(d[key], gameToken.decimals)}`;
        break;
      case "status":
        if (d.id === draw.id) {
          const startedAt = moment(new Date(parseInt(draw.createdAt)) * 1000);
          const playableAfter = startedAt.add(drawInterval, "seconds");
          const ticketsCount = parseInt(draw.ticketsCount);
          const hasEnoughTickets = ticketsCount > 0 && ticketsCount > minTickets;
          const canBeTriggered = playableAfter.isBefore(new Date()) && hasEnoughTickets;
          const readyForRamdoness = gameLinkFunded || randomSource !== RandomSources["chainlink-vrf"]
          if (gameState === GameStates["play"] && canBeTriggered && readyForRamdoness) {
            cell = <Button variant="outlined" onClick={() => startTransactions("trigger")}>{t("drawTrigger")}</Button>;
          } else if (gameState === GameStates["draw"]) {
            cell = <Tooltip title={t("drawWaitingNumbers")} arrow><Box><Button disabled>{t("drawWaiting")}</Button></Box></Tooltip>;
          } else if (gameState === GameStates["dist"]) {
            cell = <Button variant="outlined" onClick={() => startTransactions("distribute")}>{t("drawDistribute")}</Button>
          } else if (!readyForRamdoness) {
            cell = <Tooltip title={t("vrfFundInstructions")} arrow><Box><Button disabled>{t("noVrfFunds")}</Button></Box></Tooltip>;
          } else {
            cell = <Button variant="outlined" onClick={() => navigate('tickets')}>{t("buy")}</Button>;
          }
        } else {
          cell = <Button disabled>{t("drawCompleted")}</Button>;
        }
        break;
    }
    return cell;
  }

  const showConfigurations = () => {
    return (
      <Box sx={{p: 5}}>
        <Stack justifyContent="center" alignItems="center">
          <Typography sx={{ ...theme.typography.subtleInfo }}>{t("gameInfo")}</Typography>
          <Typography sx={{ ...theme.typography.subtleInfo }}>
              {t("chainExplorer")}:&nbsp;
              <Link href={`${chainExplorer}/address/${game?.address}`} sx={{...theme.typography.disclaimer}} target="_blank">{game?.address}</Link>
          </Typography>
          <Typography sx={{ ...theme.typography.subtleInfo }}>
              {t("louperExplorer")}:&nbsp;
              <Link href={`https://louper.dev/diamond/${game?.address}?network=${louperNetworkName}`} sx={{...theme.typography.disclaimer}} target="_blank">{game?.address}</Link>
          </Typography>
          {randomSource === RandomSources["chainlink-vrf"] && <Typography sx={{ ...theme.typography.subtleInfo }}>
            {t("vrfContract")}:&nbsp;
            <Link href={`${chainExplorer}/address/${vrfAddress}`} sx={{...theme.typography.disclaimer}} target="_blank">{vrfAddress}</Link>
            </Typography>
          }
          {/* <Box sx={{p: 1}}>
            {gameState === GameStates["draw"] && <Button variant="outlined" onClick={() => startTransactions("fakeNumbers")}>Generate Fake Numbers</Button>}
          </Box> */}
          <Box sx={{p: 1}}>
            {pauseRole && <Button variant="outlined" onClick={() => startTransactions("pauseGame")}>{gamePaused ? t("unpauseGame") : t("pauseGame")}</Button>}
          </Box>
        </Stack>
      </Box>
    );
  }

  const showCurrentDrawInfo = () => {
    const spacing = smallScreen ? 1 : 2;
    const rows = allDraws.map(d => {
      return {...d}
    });

    const tableData = {
      columns : [
        {key: "id", name: t("drawId")},
        {key: "ticketsCount", name: t("ticketsCount")},
        {key: "createdAt", name: t("createdAt")},
        {key: "playedAt", name: t("playedAt")},
        {key: "numbers", name: t("winningNumbers")},
        {key: "payout", name: t("payout")},
      ],
      rows: rows,
      rowFormatter: drawCellContents,
    }

    if (!smallScreen) {
      tableData.columns.push({key: "status", name: t("status")})
    }
    return (
      <Box sx={{p: spacing}}>
        <Stack spacing={spacing} alignItems="center" >
        <Box sx={{p: spacing}}>
          <Typography variant="h4" >{t("allDraws")}</Typography>
        </Box>
        <Box sx={{width: {xs: "100%", md: "auto"}}}>
          <ChanceyTable data={tableData} compact={smallScreen}></ChanceyTable>
        </Box>
        </Stack>
      </Box>
    )
  }

  const showClaimableWinnings = () => {
    return (
      <Box>
        {hasWinnings() &&
          <Box sx={{p: 3}}>
            <Stack spacing={2} alignItems="center">
              <Typography variant="h4">
                {t("winnerHeader")}
              </Typography>
              <Typography>
                {t("winnerSubheader", {amount: formatUnits(winnings, gameToken.decimals), currency: gameToken.symbol})}
              </Typography>
              <Button variant="outlined" onClick={() => startTransactions("claiming")}>{t("claimPrize")}</Button>
            </Stack>
          </Box>
        }
      </Box>
    )
  };

  const showGuaranteedPrizes = () => {
    const table = {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main
    }
    const columnRight = {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontSize: '0.8rem',
      fontWeight: 400
    }
    const columnLeft = {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      fontSize: '0.8rem',
      fontWeight: 400,
    }
    const columnLeftLast = {
      ...columnLeft,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.primary.main
    }

    const ticketsCount = draw ? parseInt(draw.ticketsCount) : 0;
    if (ticketsCount === 0) {
      return <></>;
    }

    const guaranteedPrizeDisplayData = guranteedPrizeConfigurations.map(c => {
      const totalPrizes = parseInt(ticketsCount / c.ticketsLot);
      if (totalPrizes === 0) {
        return null;
      }
      const totalShare = toBigNumber(pot).mul(c.prizeRate).div(100);
      const share = totalShare.div(totalPrizes);
      return {
        odds: t("garanteedPrizeOdds", {prizes: 1, in: c.ticketsLot}),
        dist: t("guranteedPrizeDist", {count: totalPrizes, amount: `${formatUnits(share, gameToken.decimals, 4)} ${gameToken.symbol}`})
      }
    }).filter(i => i !== null);

    if (guaranteedPrizeDisplayData.length === 0) {
      return <></>;
    }

    return (
      <Stack alignItems="center">
        <Box sx={{p: 3, width: {xs: "100%", md: "350px"}}}>
          <Stack spacing={1}>
            <Typography variant="h6">
              {t("guaranteedPrizes")}
            </Typography>
            <TableContainer>
              <Table size="small" sx={{...table}}>
                <TableBody>
                  {guaranteedPrizeDisplayData.map((p, i) => {
                    return <TableRow key={i}>
                      <TableCell sx={i < guaranteedPrizeDisplayData.length - 1 ? {...columnLeft} : {...columnLeftLast}}>{p.odds}</TableCell>
                      <TableCell sx={{...columnRight}}>{p.dist}</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box>
      </Stack>
    )
  }

  const tokenAvatarStyle = useMediaQuery(theme.breakpoints.up('md')) ? theme.typography.jackpotAvatar : theme.typography.jackpotAvatarSmall;
  const potValueSpacing = useMediaQuery(theme.breakpoints.up('md')) ? 2 : 1;
  return (
    <div>
      <Box sx={{ p: 2, paddingTop: 4 }}>
        <GlowingDiv>
          <Box sx={{ p: 4 }}>
            <Stack spacing={2} alignItems="center" >
              <Typography variant="h6">
                {t("jackpot")}
              </Typography>
              {wallet?.account ? (<Stack direction="row" alignItems="center" spacing={potValueSpacing}>
                <div><Avatar src={`/icons/tokens/${gameToken?.icon}`} sx={{...tokenAvatarStyle}}/></div>
                {jackpot && <Typography  sx={{ typography: { xs: "h4", sm: "h3", md: "h2" }}}>{formatUnits(jackpot, gameToken.decimals, 4)}</Typography>}
              </Stack>) : (<Box><Typography variant="h4">{t("connectWallet")}</Typography></Box>)}
            </Stack>
          </Box>
        </GlowingDiv>
      </Box>
      {showClaimableWinnings()}
      {showGuaranteedPrizes()}
      <Box sx={{p: potValueSpacing * 2}}>
        <Button sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 1}} variant="outlined" onClick={() => navigate('/tickets')}>{t("buyTickets")}</Button>
      </Box>
      {showCurrentDrawInfo()}
      {showConfigurations()}
      <TransactionModal
        open={txDiagOpen["trigger"]}
        onClose={() => closeTxDiag("trigger")}
        steps={triggerTransactions}
        onFinished={() => onTxDiagFinished("trigger")}
      >
      </TransactionModal>
      <TransactionModal
        open={txDiagOpen["distribute"]}
        onClose={() => closeTxDiag("distribute")}
        steps={distributeTransactions}
        onFinished={() => onTxDiagFinished("distribute")}
      >
      </TransactionModal>
      <TransactionModal
        open={txDiagOpen["claiming"]}
        onClose={() => closeTxDiag("claiming")}
        steps={claimTransactions}
        onFinished={() => onTxDiagFinished("claiming")}
      >
      </TransactionModal>
      <TransactionModal
        open={txDiagOpen["fakeNumbers"]}
        onClose={() => closeTxDiag("fakeNumbers")}
        steps={generateFakeNumbersTransactions}
        onFinished={() => onTxDiagFinished("fakeNumbers")}
      >
      </TransactionModal>
      <TransactionModal
        open={txDiagOpen["pauseGame"]}
        onClose={() => closeTxDiag("pauseGame")}
        steps={pauseGameTransactions}
        onFinished={() => onTxDiagFinished("pauseGame")}
      >
      </TransactionModal>
    </div>
  );
}
export default Dashboard