import { metaMask, metaMaskHooks } from "./metamask"

const Connectors = [{
  name: "MetaMask",
  provider: metaMask,
  hooks: metaMaskHooks,
  logo: "metamask.svg"
}];

export {
  Connectors
};
