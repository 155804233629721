const MIN_NUMBER = 1;
const MAX_NUMBER = 49;
const NUMBERS_COUNT = 6;

const generateRandomNumbers = () => {
  const numbers = [];
  for (let i = 0; i < NUMBERS_COUNT;) {
    const n = Math.floor(Math.random() * (MAX_NUMBER - MIN_NUMBER + 1)) + MIN_NUMBER;
    if (!numbers.includes(n)) {
      numbers.push(n);
      i++;
    }
  }
  return numbers;
}

const numbersToTicketLine = (numbers) => {
  return numbers.sort((a, b) => a - b)
    .map(n => String(n).padStart(2, "0"))
    .join();
}

const Utilities = {
  generateRandomNumbers,
  numbersToTicketLine,
  MIN_NUMBER,
  MAX_NUMBER,
  NUMBERS_COUNT,
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000"
};

export default Utilities;