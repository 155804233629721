import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utilities from "../../utils/utilities";

const { ZERO_ADDRESS } = Utilities;
/**
 * These slices are used for keeping
 * state of balances for both the account
 * and the game addresses
 */

const sliceName = "accountBalances";

const initialState = {
  data: {
    chancey: "0", // Account balance of CHANCEY
    game: "0",    // Account balance of GAME Token
    link: "0",    // Account balance of LINK Token (dev usage)
  },
  status: "idle",
  error: null
};

const accountBalancesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchAccountBalance.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchAccountBalance.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data = {...state.data, ...action.payload};
    })
    .addCase(fetchAccountBalance.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchAccountBalance = createAsyncThunk(`${sliceName}/fetchOne`, async(params) => {
  if (!params) {
    throw new Error("Cannot fetch account balance: params is undefined")
  }

  const {
    contracts,
    account,
    tokenName,
    tokenAddress
  } = params;

  if (!contracts) {
    throw new Error("Cannot fetch account balance: contracts is undefined");
  }
  if (!account) {
    throw new Error("Cannot fetch account balance: account is undefined");
  }
  if (!tokenName) {
    throw new Error("Cannot fetch account balance: tokenName is undefined");
  }
  if (!tokenAddress) {
    throw new Error("Cannot fetch account balance: tokenAddress is undefined");
  }

  if (tokenAddress !== ZERO_ADDRESS) {
    // Game configured with ERC20 for play
    const erc20 = contracts.IERC20FromAddress(tokenAddress);
    const balance = await erc20.balanceOf(account);
    return {[tokenName] : balance.toString()};
  } else {
    // Game configured with native coin for play
    const provider = contracts.getProvider();
    const balance = await provider.getBalance(account);
    return {[tokenName] : balance.toString()};
  }
});

// TODO add more getters to account for formatting, etc
export const getAccountBalance = (state, t) => {
  return state.accountBalances.data[t];
};

export default accountBalancesSlice.reducer;