import "./App.css";

import { useEffect, useState, useReducer, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useWeb3React } from "@web3-react/core";
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from "./pages/Dashboard.js";
import Tickets from "./pages/Tickets.js";
import FreeTickets from "./pages/FreeTickets";
import Staking from "./pages/Staking.js";
import PotClaim from "./pages/PotClaim";
import NotFound from "./pages/NotFound.js";
import Governance from "./pages/Governance.js";
import AccountInfo from "./components/AccountInfo";
import Transitions from "./components/Transitions";
import ErrorDialogue from "./components/ErrorDialogue";
import Logo from "./components/Logo";
import ContractUtils from "./utils/contracts";
import Wallet, { WalletReducer, SET_WALLET } from "./context/wallet";
import Contracts, { ContractsReducer, SET_FACTORY } from "./context/contracts";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import chaindescriptors from "./utils/chaindescriptors";
import { Networks } from "./utils/deployed-contracts";
import { Connectors, useConnector } from "./connectors/connectors";
import { fetchTokens } from "./store/slices/tokens-slice";
import { fetchAccountBalance } from "./store/slices/account-balances-slice";
import { fetchGameBalance} from "./store/slices/game-balances-slice";
import { fetchAllowance } from "./store/slices/allowances-slice";
import { fetchRewardsConfigurations } from "./store/slices/game-configurations-slice";
import { fetchCurrentUserAgreement, fetchCurrentAgreementUri } from "./store/slices/user-slice";
import { getPreference, setPreference } from "./store/slices/preferences-slice";
import { getAgreementUri } from "./store/slices/user-slice";
import {
  AppBar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';

const SELECTED_WALLET_KEY = "selectedWallet";

function App() {
  const { t } = useTranslation("translation", {keyPrefix: "menu"});
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [walletState, walletDispatch] = useReducer(WalletReducer, null);
  const [contractState, contractDispatch] = useReducer(ContractsReducer, null);
  const [wallet, setWallet] = useState();
  const [contracts, setContracts] = useState();
  const [accountChipLabel, setAccountChipLabel] = useState(t("wallet.connect"));
  const [accountAnchorRef, setAccountAnchorRef] = useState(null);
  const [networkName, setNetworkName] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [networkSupported, setNetworkSupported] = useState(true);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedWallet = useSelector(state => getPreference(state, SELECTED_WALLET_KEY));
  const agreementUri = useSelector(state => getAgreementUri(state));
  const connector = useWeb3React();
  const accountInfoOpen = Boolean(accountAnchorRef);
  const dispatch = useDispatch();
  const tokens = useSelector(state => state.tokens);
  const menuOptions = [
    {path: "/", name: "dashboard"},
    {path: "/tickets", name: "tickets"},
    {path: "/staking", name: "staking"},
    {path: "/governance", name: "governance"},
    {path: "/Chancey-White-Paper-v2.pdf", name: "about", newWindow: true},
  ];


  const handleAccountInfoClick = (event) => {
    setAccountAnchorRef(accountAnchorRef ? null : event?.currentTarget);
  }

  const fetchBalancesAndApprovals = async() => {
    if (contracts && tokens.status === "success") {
      const keys = Object.keys(tokens.data);
      for (let i = 0; i < keys.length; ++i) {
        const name = keys[i];
        const token = tokens.data[name];
        const game = await contracts.getGameFacet("GameFacet");

        dispatch(fetchAccountBalance({
          contracts,
          tokenName: name,
          tokenAddress: token.address,
          account: wallet.account
        }));

        dispatch(fetchGameBalance({
          contracts,
          tokenName: name,
          tokenAddress: token.address,
          game: game.address
        }));

        dispatch(fetchAllowance({
          contracts,
          tokenName: name,
          tokenAddress: token.address,
          address: game.address,
          account: wallet.account
        }));
      }
    }
  }

  const fetchAgreement = async() => {
    if (wallet?.account) {
      dispatch(fetchCurrentUserAgreement({
        contracts,
        account: wallet.account
      }));
      dispatch(fetchCurrentAgreementUri({contracts}));
    }
  }

  useEffect(() => {
    const eagerConnect = async(wallet) => {
      for (let c = 0; c < Connectors.length; ++c) {
        const connector = Connectors[c];
        if (connector.name !== wallet) {
          continue;
        }
        try {
          console.log(`Eager connect ${connector.name}`);
          await connector.provider.connectEagerly();
          break;
        } catch (e) {
          console.error(`Failed egger connect to ${connector.name}`);
        }
      }
    }
    if (selectedWallet) {
      eagerConnect(selectedWallet);
    } else {
      console.log("No previously selected wallet stored");
    }
  },[selectedWallet]);

  useEffect(() => {
    if (tokens && contracts && wallet?.account) {
      fetchBalancesAndApprovals();
      fetchAgreement();
    }
  }, [tokens, contracts, wallet]);

  useEffect(() => {
    if (!window.ethereum) {
      setAccountChipLabel(t("wallet.install"));
    } else if (wallet?.account) {
      setAccountChipLabel(`${wallet.account.slice(0, 8)} ... ${wallet.account.slice(-4)}`);
    } else {
      setAccountChipLabel(t("wallet.connect"));
    }
  }, [wallet]);

  useEffect(() => {
    if (connector?.chainId) {
      const { chainId, provider, accounts} = connector;
      let network = Networks[chainId];
      if (!network) {
        network = chaindescriptors.find(n => n.chainId === chainId)?.name || {name: "Current network"}
      }

      const wallet = {chainId, provider, account: accounts[0]};
      setNetworkName(t("network", {network: network.name}));
      setWallet(wallet);

      if (Networks[chainId]) {
        const contracts = ContractUtils.contractFactory(provider, chainId);
        setNetworkSupported(true);
        setContracts(contracts);
        walletDispatch({type: SET_WALLET, payload: wallet});
        contractDispatch({type: SET_FACTORY, payload: contracts});
        dispatch(fetchTokens(contracts));
        dispatch(fetchRewardsConfigurations(contracts));
      } else {
        setNetworkSupported(false);
      }
    } else {
      setWallet(null);
      setContracts(null);
      walletDispatch({type: SET_WALLET, payload: {chainId: null, provider: null, account: null}});
    }
  }, [connector]);

  const connectWallet = async(connector) => {
    await connector.provider.activate();
    dispatch(setPreference({
      key: SELECTED_WALLET_KEY,
      value: connector.name
    }));
    setAccountAnchorRef(null);
  }

  const handleFreeTickets = async() => {
    setAccountOpen(false);
    navigate("free-tickets");
    handleAccountInfoClick(null);
  }

  const handleBurnPot = async() => {
    setAccountOpen(false);
    navigate("burn-pot");
    handleAccountInfoClick(null);
  }

  const handleWalletConnection = async(connector) => {
    if (!window.ethereum) {
      setShowErrorDialog(true);
      setErrorMessage(t("noWalletDetected", {wallet: connector.name}))
    } else {
      connectWallet(connector);
    }
  }

  const handleDisconnectRequest = async() => {
    if(selectedWallet) {
      for (let c = 0; c < Connectors.length; ++c) {
        const connector = Connectors[c];
        if (connector.name !== selectedWallet) {
          continue;
        }
        const { provider } = connector;
        if (provider?.deactivate) {
          provider?.deactivate();
        } else {
          provider?.resetState();
        }
        break;
      }
    }
    setAccountAnchorRef(null);
    dispatch(setPreference({
      key: SELECTED_WALLET_KEY,
      value: ""
    }));
  }

  const switchNetwork = async(chainId, network) => {
    try {
      await connector.provider.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${parseInt(chainId).toString(16)}` }],
      })
    } catch (e) {
      if (e.code === 4902) {
        setErrorMessage(t("failedNetworkSwitchMissing", {network}));
      } else {
        console.error(e);
        setErrorMessage(t("failedNetworkSwitchGeneric", {network}));
      }
      setShowErrorDialog(true);
    }
  }

  const addTokenToMM = async(token) => {
    await connector.provider.provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals
        },
      },
    });
  }

  const handleMenuOption = (o) => {
    setMenuOpen(false);
    if (o.newWindow) {
      window.open(o.path, "_blank");
    } else {
      navigate(o.path);
    }
  };

  const accountMenu = () => {
    return <Box>
       <AccountInfo
          networkName={networkName}
          isPopUp={false}
          activeConnector={connector}
          handleWalletConnection={handleWalletConnection}
          handleFreeTicketsClick={handleFreeTickets}
          handleBurnPotClick={handleBurnPot}
          disconnectRequested={handleDisconnectRequest}
          addTokenToMM={addTokenToMM}
       />
    </Box>
  }

  const drawerMenu = () => {
    return <Stack>
        <List>
        {menuOptions.map((o, i) => {
          return <ListItemButton
              key={i}
              selected={location.pathname === o.path}
              onClick={() => handleMenuOption(o)}
            >
            <ListItemText
              primary={t(o.name)}
              primaryTypographyProps={{
                ...theme.typography.drawerMenuItem
              }}
            />
          </ListItemButton>
        })}
      </List>
    </Stack>
  }

  return (
    <Wallet initialState={walletState} dispatch={walletDispatch}>
      <Contracts initialState={contractState} dispatch={contractDispatch}>
        <AppBar>
          <Box sx={{ backgroundColor: "background.default", paddingTop: 1 }} flex>
            <Toolbar sx={{display: {xs: "none", lg: "flex"}}}>
              <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{width: "100%"}}>
                <Box>
                  <Logo />
                </Box>
                <Stack direction="row" alignItems="center" spacing={4}>
                  {menuOptions.map((o, i) => {
                    return <Box
                        key={i}
                        sx={{ borderBottom: location.pathname === o.path ? 1 : 0 }}
                      >
                      <Button onClick={() => handleMenuOption(o)}>{t(o.name)}</Button>
                    </Box>
                  })}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" sx={{ flexGrow: 1 }}>
                  <Box>
                    <Chip icon={<AccountBalanceWalletOutlinedIcon/>} size="large" label={accountChipLabel} variant="outlined" color="primary" style={{ border: '2px solid' }} onClick={handleAccountInfoClick}/>
                    <Popper disablePortal open={accountInfoOpen} anchorEl={accountAnchorRef}>
                      {({ TransitionProps }) => (
                        <Transitions in={accountInfoOpen} {...TransitionProps}>
                          <ClickAwayListener onClickAway={() => handleAccountInfoClick(null)}>
                            <Box sx={{ p: 2 }}>
                              <AccountInfo
                                networkName={networkName}
                                isPopUp={true}
                                activeConnector={connector}
                                handleWalletConnection={handleWalletConnection}
                                handleFreeTicketsClick={handleFreeTickets}
                                handleBurnPotClick={handleBurnPot}
                                disconnectRequested={handleDisconnectRequest}
                                addTokenToMM={addTokenToMM}
                              />
                            </Box>
                          </ClickAwayListener>
                        </Transitions>
                      )}
                    </Popper>
                  </Box>
                </Stack>
              </Stack>
            </Toolbar>
            <Toolbar sx={{display: {lg: "none"}}}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: "100%"}}>
                <Box>
                  <IconButton
                    color="primary" edge="start"
                    sx={{ height: 60, width: 60 }}
                    onClick={() => setMenuOpen(true)}
                  >
                    <MenuIcon></MenuIcon>
                  </IconButton>
                </Box>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  <Box>
                    <Logo size={40}/>
                  </Box>
                  <Box>
                    <Typography variant="h4">Chancey</Typography>
                  </Box>
                </Stack>
                <Box>
                  <IconButton
                    edge="end"
                    sx={{ height: 60, width: 60 }}
                    onClick={() => setAccountOpen(true)}
                  >
                    <AccountBalanceWalletOutlinedIcon color="primary"/>
                  </IconButton>
                </Box>
              </Stack>
            </Toolbar>
          </Box>
        </AppBar>
        <Toolbar/>
        <Drawer
          open={menuOpen}
          variant="temporary"
          onClose={() => setMenuOpen(false)}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default,
              width: "50%",
              backgroundImage: "none",
              borderRight: `2px solid ${theme.palette.divider}`
            }
          }}
        >
          {drawerMenu()}
        </Drawer>
        <Drawer
          open={accountOpen}
          variant="temporary"
          onClose={() => setAccountOpen(false)}
          anchor="right"
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default,
              backgroundImage: "none",
              borderLeft: `2px solid ${theme.palette.divider}`
            }
          }}
        >
          {accountMenu()}
        </Drawer>
        <Box sx={{p: 4, textAlign: "center", minHeight:"calc(100vh - 200px)" }}>
          {networkSupported && <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="tickets" element={<Tickets />}></Route>
            <Route path="staking" element={<Staking />}></Route>
            <Route path="governance" element={<Governance />}></Route>
            <Route path="free-tickets" element={<FreeTickets />}></Route>
            <Route path="burn-pot" element={<PotClaim />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>}
          {!networkSupported && <Box>
            <Typography variant="h4">{t("networkNotSupported", {network: networkName})}</Typography>
            <Typography sx={{p: 2}}>{t("networkConnectSupported")}</Typography>
            <Stack spacing={1}>
              {Object.keys(Networks).map(k => {
                  return <Stack alignItems="center" key={k}>
                    <Button
                      startIcon={<img src={`icons/networks/${Networks[k].logo}`} alt={Networks[k].name} style={{ height: '24px', width: 'auto' }}/>}
                      onClick={() => switchNetwork(k, Networks[k].name)}>{Networks[k].name}
                    </Button>
                  </Stack>
                })}
            </Stack>
          </Box>}
        </Box>
        <Stack sx={{p: 2, position: "relative", bottom:0}} spacing={2}>
          <Typography sx={{...theme.typography.disclaimer}}>
            {t("disclaimerTitle")}
          </Typography>
          <Typography sx={{...theme.typography.disclaimer}}>
            {t("disclaimerText")}
          </Typography>
          {agreementUri && <Link href={agreementUri} sx={{...theme.typography.disclaimer}} target="_blank">{t("TandC")}</Link>}
        </Stack>
        <ErrorDialogue
          open={showErrorDialog}
          close={() => setShowErrorDialog(false)}
          title={t("error")}
          body={errorMessage}
        ></ErrorDialogue>
      </Contracts>
    </Wallet>
  )
}

export default App;

