import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sliceName = "gameState";
const States = {
  "play" : 0,
  "draw" : 1,
  "dist" : 2,
  "paused" : 3
};

const initialState = {
  data: {
    state: States["play"],
    isPaused: false
  },
  status: "idle",
  error: null
};

const gameStateSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchGameState.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchGameState.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.state = action.payload;
    })
    .addCase(fetchGameState.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(fetchGamePaused.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    })
    .addCase(fetchGamePaused.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
      state.data.isPaused = action.payload;
    })
    .addCase(fetchGamePaused.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export const fetchGameState = createAsyncThunk(`${sliceName}/fetchState`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game state, contracts is undefined")
  }
  const game = await contracts.getGameFacet("GameFacet");
  return await game.state();
});

export const fetchGamePaused = createAsyncThunk(`${sliceName}/fetchIsPaused`, async(contracts) => {
  if (!contracts) {
    throw new Error("Can't fetch game paused, contracts is undefined")
  }
  const game = await contracts.getGameFacet("GameFacet");
  return await game.isPaused();
});

export const getGameState = (state) => {
  return state.gameState.data.state;
};

export const getGamePaused = (state) => {
  return state.gameState.data.isPaused;
};

export const GameStates = States;
export default gameStateSlice.reducer;