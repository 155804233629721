import { createSlice } from "@reduxjs/toolkit";

const sliceName = "currentTicketPrice";

const initialState = {
  data: 0,
};

const currentTicketPriceSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    currentTicketPriceUpdated(state, action) {
      return {...state, data: action.payload};
    }
  }
});


export const getCurrentTicketPrice = (state) => {
  return state[sliceName].data;
};

export const { currentTicketPriceUpdated } = currentTicketPriceSlice.actions;
export default currentTicketPriceSlice.reducer;